import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Consumer } from "../../../Util/Context/Context";
import "./Toolbar.scss";
//Interface
interface ToolbarProps {
  drawerclickhandler?: any;
  name?: string;
  position?: string;
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Consumer>
      {(context) => (
        <header className="toolbar">
          <Navbar collapseOnSelect expand="md">
            <Navbar.Brand href="/">
              <div className="toolbar-logo"></div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            {/* Start of collapse section Displaying it only on mobile views*/}
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="toolbar-collapse">
                <ul className="toolbar-list">
                  <NavDropdown title="Usuarios" id="collasible-nav-dropdown">
                  <LinkContainer to="/users">
                    <NavDropdown.Item>
                      Usuarios Registrados
                    </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/responsibles">
                    <NavDropdown.Item>
                      Responsables
                    </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown title="Empresas" id="collasible-nav-dropdown">
                  <LinkContainer to="/clients">
                    <NavDropdown.Item>
                      Empresas Registradas
                    </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/plants">
                    <NavDropdown.Item>
                      Plantas
                    </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown title="Portafolios" id="collasible-nav-dropdown">
                    <LinkContainer to="/portfolios">
                      <NavDropdown.Item>
                        Portafolios
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/distributors">
                      <NavDropdown.Item>
                        Distribuidores
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown title="Productos" id="collasible-nav-dropdown">
                  <LinkContainer to="/products">
                    <NavDropdown.Item>
                      Productos
                    </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/productlines">
                    <NavDropdown.Item>
                      Líneas de Producto
                    </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/productstyles">
                    <NavDropdown.Item>
                      Estilos de producto
                    </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/applications">
                    <NavDropdown.Item>
                        Aplicaciones de producto
                    </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/installedproducts">
                    <NavDropdown.Item>
                        Productos Instalados
                    </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown
                    title="Solicitudes de Soporte"
                    id="collasible-nav-dropdown"
                  >
                    <LinkContainer to="/trainingrequests">
                    <NavDropdown.Item>
                    Solicitudes Registradas
                    </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/reasons">
                    <NavDropdown.Item>
                      Razones de Solicitud
                    </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </ul>
                <NavDropdown title={props.name} id="collasible-nav-dropdown">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Perfil</NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Item
                    onClick={() => {
                      context.setLogged(false);
                      sessionStorage.removeItem("token");
                      
                    }}
                    >
                    Cerrar Sesión
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Navbar.Collapse>
            {/* End of collapse section */}
            <div className="icon6"></div>
            <div className="profile-nav">
              <NavDropdown title={props.name} id="collasible-nav-dropdown" drop="down">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Perfil</NavDropdown.Item>
                </LinkContainer>
              {/* Access to context to set log out in case button in pressed*/}
                <NavDropdown.Item
                  onClick={() => {
                    context.setLogged(false);
                    sessionStorage.removeItem("token");
                  }}
                >
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Navbar>
        </header>
      )}
    </Consumer>
  );
};

export default Toolbar;
