import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import "./SideDrawer.scss";

interface SideDrawerProps {
  show: boolean;
  click: any;
}
const SideDrawer: React.FC<SideDrawerProps> = (props) => {

  //hookState to handle collapse sections
  const [usersOpen, setUsersOpen] = useState<boolean>(false);
  const [clientsOpen, setClientsOpen] = useState<boolean>(false);
  const [portfoliosOpen, setPortfoliosOpen] = useState<boolean>(false);
  const [productsOpen, setProductsOpen] = useState<boolean>(false);
  const [successCasesOpen, setSuccessCasesOpen] = useState<boolean>(false);
  const [requestsOpen, setRequestsOpen] = useState<boolean>(false);
  // handle classes to show/not show sidedrawer
  let drawerclasses = "side-drawer open";
  if (props.show) {
    drawerclasses = "side-drawer open";
  }
  return (
    <Consumer>
      {(context) => (

        <nav className={drawerclasses}>
          <ul>
            {
              context.user.role === "AGENT" ?
                <NavLink to="/chat">
                  <li
                    onClick={() => {
                    }}
                  >
                    <div className="icon7"></div>
                    <label>Chat</label>
                  </li>
                </NavLink> :
                context.user.role === "DSO" ?
                  <NavLink to="/users">
                    <li>
                      <div className="icon1"></div>
                      <label>Usuarios</label>
                    </li>
                  </NavLink>
                  :
                  <>
                    <NavLink to="/users">
                      <li
                        onClick={() => {
                          setUsersOpen(!usersOpen);
                          setClientsOpen(false);
                          setPortfoliosOpen(false);
                          setProductsOpen(false);
                          setSuccessCasesOpen(false);
                          setRequestsOpen(false);
                        }}
                      >
                        <div className="icon1"></div>
                        <label>Usuarios</label>
                      </li>
                    </NavLink>

                    <Collapse in={usersOpen}>
                      <div>
                        <NavLink to="/users">
                          <li className="collapse-link">
                            <label>Usuarios Registrados</label>
                          </li>
                        </NavLink>
                        <NavLink to="/responsibles">
                          <li className="collapse-link">
                            <label>Responsables</label>
                          </li>
                        </NavLink>
                        <NavLink to="/registercodes">
                          <li className="collapse-link">
                            <label>Códigos de registro</label>
                          </li>
                        </NavLink>
                        <NavLink to="/deletedusers">
                          <li className="collapse-link">
                            <label>Usuarios eliminados</label>
                          </li>
                        </NavLink>
                      </div>
                    </Collapse>
                    <NavLink to="/clients">
                      <li
                        onClick={() => {
                          setUsersOpen(false);
                          setClientsOpen(!clientsOpen);
                          setPortfoliosOpen(false);
                          setProductsOpen(false);
                          setSuccessCasesOpen(false);
                          setRequestsOpen(false);
                        }}
                      >
                        <div className="icon2"></div>
                        <label>Empresas</label>
                      </li>
                    </NavLink>
                    {/* collapse */}
                    <Collapse in={clientsOpen}>
                      <div>
                        <NavLink to="/clients">
                          <li className="collapse-link">
                            <label>Empresas Registradas</label>
                          </li>
                        </NavLink>
                        <NavLink to="/plants">
                          <li className="collapse-link">
                            <label>Plantas</label>
                          </li>
                        </NavLink>
                      </div>
                    </Collapse>

                    <NavLink to="/portfolios">
                      <li
                        onClick={() => {
                          setUsersOpen(false);
                          setClientsOpen(false);
                          setPortfoliosOpen(!portfoliosOpen);
                          setProductsOpen(false);
                          setSuccessCasesOpen(false);
                          setRequestsOpen(false);
                        }}
                      >
                        <div className="icon3"></div>
                        <label>Portafolios</label>
                      </li>
                    </NavLink>
                    {/* collapse */}
                    <Collapse in={portfoliosOpen}>
                      <div>
                        <NavLink to="/portfolios">
                          <li className="collapse-link">
                            <label>Portafolios Registrados</label>
                          </li>
                        </NavLink>
                        <NavLink to="/distributors">
                          <li className="collapse-link">
                            <label>Distribuidores</label>
                          </li>
                        </NavLink>
                      </div>
                    </Collapse>


                    <NavLink to="/products">
                      <li
                        onClick={() => {
                          setUsersOpen(false);
                          setClientsOpen(false);
                          setPortfoliosOpen(false);
                          setProductsOpen(!productsOpen);
                          setSuccessCasesOpen(false);
                          setRequestsOpen(false);
                        }}
                      >
                        <div className="icon4"></div>
                        <label>Productos</label>
                      </li>
                    </NavLink>
                    <Collapse in={productsOpen}>
                      <div>
                        <NavLink to="/products">
                          <li className="collapse-link">
                            <label>Productos</label>
                          </li>
                        </NavLink>
                        <NavLink to="/productlines">
                          <li className="collapse-link">
                            <label>Líneas de Producto</label>
                          </li>
                        </NavLink>
                        <NavLink to="/productstyles">
                          <li className="collapse-link">
                            <label>Estilos de producto</label>
                          </li>
                        </NavLink>
                        <NavLink to="/applications">
                          <li className="collapse-link">
                            <label>Aplicaciones de producto</label>
                          </li>
                        </NavLink>
                        <NavLink to="/installedproducts">
                          <li className="collapse-link">
                            <label>Productos Instalados</label>
                          </li>
                        </NavLink>
                      </div>
                    </Collapse>

                    <NavLink to="/successcases">
                      <li
                        onClick={() => {
                          setUsersOpen(false);
                          setClientsOpen(false);
                          setPortfoliosOpen(false);
                          setProductsOpen(false);
                          setSuccessCasesOpen(!successCasesOpen);
                          setRequestsOpen(false);
                        }}
                      >
                        <div className="icon4"></div>
                        <label>Casos de Éxito</label>
                      </li>
                    </NavLink>
                    <Collapse in={successCasesOpen}>
                      <div>
                        <NavLink to="/successcases">
                          <li className="collapse-link">
                            <label>Líneas de Producto</label>
                          </li>
                        </NavLink>
                        <NavLink to="/industries">
                          <li className="collapse-link">
                            <label>Industrias</label>
                          </li>
                        </NavLink>
                      </div>
                    </Collapse>
                    <NavLink to="/trainingrequests">
                      <li
                        onClick={() => {
                          setUsersOpen(false);
                          setClientsOpen(false);
                          setPortfoliosOpen(false);
                          setProductsOpen(false);
                          setSuccessCasesOpen(false);
                          setRequestsOpen(!requestsOpen);
                        }}
                      >
                        <div className="icon5"></div>
                        <label>Solicitudes de Soporte</label>
                      </li>
                    </NavLink>
                    <Collapse in={requestsOpen}>
                      <div>
                        <NavLink to="/trainingrequests">
                          <li className="collapse-link">
                            <label>Solicitudes Registradas</label>
                          </li>
                        </NavLink>
                        <NavLink to="/reasons">
                          <li className="collapse-link">
                            <label>Razones de solicitud</label>
                          </li>
                        </NavLink>
                      </div>
                    </Collapse>
                    <NavLink to="/chat">
                      <li
                        onClick={() => {
                        }}
                      >
                        <div className="icon7"></div>
                        <label>Chat</label>
                      </li>
                    </NavLink>
                  </>
            }
          </ul>
        </nav>
      )}
    </Consumer>
  );
};

export default SideDrawer;
