/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editResource } from "../../../Util/Queries/Mutations";
import { getResourceById as query } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";

export interface ResourceDetailValidations {
  name: TFieldValidation;
  type: TFieldValidation;
  productLine: TFieldValidation;
  successCase: TFieldValidation;
  enlace: TFieldValidation;
  image: TFieldValidation;
  file: TFieldValidation;
  imageFile: TFieldValidation;
}
export interface ResourceDetailForm {
  name: string;
  type: string;
  productLine: string;
  successCase: string;
  enlace: string;
  image: string;
  file: string;
  imageFile: string;
}

const ResourceDetail: React.FC<any> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<any>();
  const [deleteSuccess, setDelete] = useState<boolean>(false);
  const [quoteTemplate, setQuoteTemplate] = useState<any>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  //const
  const alert = useAlert();
  const { entity, resourceid } = useParams();
  //Apollohooks
  const getResource = useQuery(query, {
    variables: { id: resourceid ? +resourceid : 0 },
    fetchPolicy: "network-only",
  });

  const [performEdit, { loading, data, error }] = useMutation(editResource);
  //formvalues

  const defaultFormValues: ResourceDetailForm = {
    name: "",
    type: "",
    productLine: "",
    successCase: "",
    enlace: "",
    image: "",
    file: "",
    imageFile: "",
  };

  const formValidation: ResourceDetailValidations = {
    name: "not-empty",
    type: "not-empty",
    productLine: "not-empty",
    successCase: "not-empty",
    enlace: "not-empty",
    image: "not-empty",
    file: "not-empty",
    imageFile: "not-empty",
  };


  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getResource.loading) {
      if (getResource.data) {
        setIsChecked(getResource.data.resource.is_public);
        setDescription(getResource.data.resource.description);
        setLoad(true);

        forceUpdateValue({
          name: getResource.data.resource.name,
          type:
            getResource.data.resource.type === "PDF"
              ? "Ficha Técnica o Material de Apoyo"
              : getResource.data.resource.type === "PDF_QUOTE_TEMPLATE"
              ? "Plantilla de Cotización"
              : "Video",
          productLine: entity === 'productlines' ? getResource.data.resource.productLine.name : "",
          successCase: entity === 'successcases' ? getResource.data.resource.successCase.name : "",
          enlace: getResource.data.resource.path,
          image: getResource.data.resource.image,
          file: "",
          imageFile: "",
        });
      }
      if (getResource.error) {
        getResource.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getResource.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<ResourceDetailForm, ResourceDetailValidations>(
    defaultFormValues,
    formValidation
  );

  //funciton to submit changes to backend
  const submitForm = () => {
    let Resourcedata;
    setpressedButton(true)
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    if (getResource.data.resource.type === "VIDEO") {
      if (values.enlace === "") {
        emptyField = true;
      }
    }
    if (getResource.data.resource.type !== "PDF_QUOTE_TEMPLATE") {
      if (!description) {
        emptyField = true;
      }
    }
    if (!values.name) {
      emptyField = true;
    }
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      try {
        Resourcedata = {
          name: values.name,
          description: description,
          path: values.enlace,
          is_public:isChecked
        };
        performEdit({
          variables: {
            id: resourceid ? +resourceid : 0,
            data: Resourcedata,
            image: imageFile ? imageFile : "",
            pdf: quoteTemplate ? quoteTemplate : "",
          },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  //UseEffect to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 505) {
            return alert.error(
              "No se puede eliminar el recurso ya que tiene relación con otros elementos, elimine estos elementos primero"
            );
          } else {
            return  alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        let msg = "";
        if (deleteSuccess) {
          msg = "Se ha eliminado el recurso...";
        } else {
          msg = "Se han guardado los cambios";
        }
         alert.success(msg, {
          onClose: () => {
            props.history.goBack();
          },
        });
      }
    }
  }, [loading]);

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  //execute delete mutation function
  const performDelete = () => {
    setDisabledButton(true);
    let dataModified: any = {
      status: "INACTIVE",
    };
    performEdit({
      variables: {
        id: resourceid ? +resourceid : 0,
        data: dataModified,
      },
    });
    setModal(false);
    setDelete(true);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getResource.loading ? (
          getResource.data ? (
            load ? (
              <Form>
                <div className="content-container">
                  <ConfirmationModal
                    show={modal}
                    onHide={() => setModal(false)}
                    onCancel={() => setModal(false)}
                    clickEvent={performDelete}
                    message={
                      <div>
                        Está seguro que desea eliminar este recurso?
                        <br /> Esta acción no se puede revertir...
                      </div>
                    }
                    action="Eliminar Recurso"
                    type="delete"
                  />
                  <div className="mainContainer">
                    <div className="buttonspan">
                      <label></label>
                      {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                      <div className="buttonCustom">
                        <Button
                          type="danger"
                          elementAttr={{
                            type: "button",
                            disabled: disabledButton,
                          }}
                          clickEvent={() => {
                            setModal(true);
                          }}
                          size="default"
                        >
                          {"Eliminar"}
                        </Button>
                        {edit ? (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{
                                type: "button",
                                disabled: disabledButton,
                              }}
                              clickEvent={submitForm}
                              size="default"
                            >
                              {"Guardar"}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{ type: "button" }}
                              clickEvent={() => {
                                setedit(true);
                              }}
                              size="default"
                            >
                              {"Editar"}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="detailcontainer">
                      <h2>{edit ? "Editar recurso" : "Detalles de recurso"}</h2>
                      <div className="input-container">
                        <FormField
                          type="text"
                          label="Nombre"
                          disabled={!edit}
                          required={{
                            required: true,
                            requiredMsg: "Por favor ingresa un nombre.",
                          }}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="name"
                          value={values.name}
                        />
                        {getResource.data.resource.type !==
                        "PDF_QUOTE_TEMPLATE" ? (
                          <>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label className="form-label">
                                Descripción
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                value={description}
                                onChange={handleTextAreaChange}
                                disabled={!edit}
                              />
                            </Form.Group>
                          </>
                        ) : null}
                        {!edit ? (
                          <>
                            <FormField
                              type="text"
                              label="Tipo"
                              disabled={!edit}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                              name="type"
                              value={values.type}
                            />
                            <FormField
                              type="text"
                              label={ entity === 'productlines' ? "Línea de Producto" : "Caso de Éxito"}
                              disabled={!edit}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                              name={entity === 'productlines' ? "productLine" : "successCase"}
                              value={entity === 'productlines' ? values.productLine : values.successCase}
                            />
                            <FormField
                              type="image"
                              label={
                                getResource.data.resource.type ===
                                "PDF_QUOTE_TEMPLATE"
                                  ? "Plantilla de Cotización"
                                  : getResource.data.resource.type === "VIDEO"
                                  ? "Video"
                                  : "Ficha Técnica o Material de Apoyo"
                              }
                              name="link"
                              value="link"
                              link={getResource.data.resource.path}
                            />
                            {getResource.data.resource.type === "PDF" ? (
                              <FormField
                                type="image"
                                label="Imagen"
                                name="image"
                                value="image"
                                link={getResource.data.resource.image}
                              />
                            ) : null}
                          </>
                        ) : getResource.data.resource.type === "VIDEO" ? (
                          <FormField
                            type="text"
                            label="Enlace"
                            required={{
                              required: true,
                              requiredMsg:
                                "Por favor ingresa una direccion URL.",
                            }}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                              disabled: !edit,
                            }}
                            name="enlace"
                            value={values.enlace}
                          />
                        ) : (
                          <>
                            <h6>
                              {getResource.data.resource.type === "PDF"
                                ? "Selecciona una nueva Ficha Técnica o Material de Apoyo"
                                : "Selecciona una nueva Plantilla de Cotización"}{" "}
                            </h6>
                            <FormField
                              type="file"
                              label={
                                getResource.data.resource.type === "PDF"
                                  ? "Ficha Técnica o Material de Apoyo"
                                  : "Plantilla de Cotización"
                              }
                              required={{
                                required: true,
                                requiredMsg:
                                  "Por favor ingresa un archivo válido.",
                              }}
                              name="file"
                              value={values.file}
                              inputElAttributes={{
                                onChange: (ev) => {
                                  if (ev.target.files)
                                    setQuoteTemplate(ev.target.files[0]);
                                  handleChange(ev);
                                },
                                onBlur: handleBlur,
                                accept: "application/pdf",
                              }}
                              error={pressedButton && errors.file}
                            />
                            {getResource.data.resource.type === "PDF" ? (
                              <FormField
                                type="file"
                                label="Imagen"
                                required={{
                                  required: true,
                                  requiredMsg:
                                    "Por favor ingresa una imagen válida.",
                                }}
                                name="imageFile"
                                value={values.imageFile}
                                inputElAttributes={{
                                  onChange: (ev) => {
                                    if (ev.target.files)
                                      setImageFile(ev.target.files[0]);
                                    handleChange(ev);
                                  },
                                  onBlur: handleBlur,
                                  accept: "application/jpg,png",
                                }}
                                error={pressedButton && errors.imageFile}
                              />
                            ) : null}
                          </>
                        )}
                        <Form.Group>
                          <Form.Check
                            label="Mostrar recurso como público?"
                            checked={isChecked}
                            disabled={!edit}
                            style={{ marginLeft: "1rem" }}
                            onChange={(e: any) => {
                              setIsChecked(e.target.checked);
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="buttonspan"></div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default ResourceDetail;
