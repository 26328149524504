/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editApplication as mutation } from "../../../Util/Queries/Mutations";
import {
  getApplicationById as query,
  getApplications,
  getUserById
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";

export interface ApplicationDetailValidations {
  name: TFieldValidation;
  createdBy: TFieldValidation;
}
export interface ApplicationDetailForm {
  name: string;
  createdBy: string;
}

const ApplicationDetail: React.FC<any> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [deleteItem, setdeleteItem] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false);
  //const
  const alert = useAlert();
  const { id } = useParams();
  //Apollohooks
  const getApplication = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy:"network-only"
  });
  const [getUser,getUserStatus] = useLazyQuery(getUserById, {
    fetchPolicy:"network-only"
  });

  const [editApplication, { loading, data, error }] = useMutation(mutation);
  //formvalues

  const defaultFormValues: ApplicationDetailForm = {
    name: "",
    createdBy: "",
  };

  const formValidation: ApplicationDetailValidations = {
    name: "not-empty",
    createdBy: "not-empty",
  };

  //Update cache for apolloClient
  const updateCache = (application: any) => {
    const data = application.readQuery({
      query: getApplications,
    });
    const newData = {
      applications: data.applications.filter((t: any) => t.id !== id),
    };
    application.writeQuery({
      query: getApplications,
      data: newData,
    });
  };

  useEffect(() => {
    getApplication.refetch();
  }, []);

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getApplication.loading) {
      if (getApplication.data) {
        setDescription(getApplication.data.application.description);
        setLoad(true);
        getUser({variables:{id:+getApplication.data.application.createdBy.id}})
      }
      if (getApplication.error) {
        getApplication.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getApplication.loading]);

useEffect(() => {
  if(!getUserStatus.loading){
    if(getUserStatus.data){
      forceUpdateValue({
        name: getApplication.data.application.name,
        createdBy:getUserStatus.data.user.firstName+" "+getUserStatus.data.user.lastName
      });
    }
  }
}, [getUserStatus.loading])

  //UseEffects to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false)
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } 
          else if (error.message.code === 505) {
            return alert.error("No se puede eliminar la aplicación ya que tiene relacion con otros elementos, elimine estos elementos primero");
          }
          else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        if (deleteItem) {
          alert.success("Se ha eliminado el aplicación...", {
            onClose: () => {
              props.history.push(`/applications`);
            },
          });
        } else {
          alert.success("Se han modificado los datos...", {
            onClose: () => {
              props.history.push(`/applications`);
            },
          });
        }
      }
    }
  }, [loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<ApplicationDetailForm, ApplicationDetailValidations>(
    defaultFormValues,
    formValidation
  );

  const submitForm = () => {
    let Applicationdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });

    if (!description) {
      emptyField = true;
    }
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true)
      try {
        Applicationdata = { 
          name: values.name,
          description: description,
        };
        editApplication({
          variables: {
            id: id ? +id : 0,
            data: Applicationdata
          },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false)
      }
    }
  };

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  const performDelete = () => {
    setDisabledButton(true)
    let dataModified: any = {
      name: values.name,
      description: description,
      status: "INACTIVE",
    };
    editApplication({
      variables: {
        id: id ? +id : 0,
        data: dataModified,
      },
      update: updateCache,
    });
    setdeleteItem(true);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getUserStatus.loading ? (
          getUserStatus.data ? (
            load ? (
              <Form>
                <div className="content-container">
                  <ConfirmationModal
                    show={modal}
                    onHide={() => setModal(false)}
                    clickEvent={performDelete}
                    onCancel={() => setModal(false)}
                    message={
                      <div>
                        Está seguro que desea eliminar esta aplicación?
                        <br /> Esta acción no se puede revertir...
                      </div>
                    }
                    action="Eliminar Aplicación de Producto"
                    type="delete"
                  />
                  <div className="mainContainer">
                    <div className="buttonspan">
                      <label></label>
                      {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                      <div className="buttonCustom">
                            <Button
                              type="danger"
                              elementAttr={{ type: "button",disabled:disabledButton }}
                              clickEvent={() => {
                                setModal(true);
                              }}
                              size="default"
                            >
                              {"Eliminar"}
                            </Button>
                        {edit ? (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{ type: "button",disabled:disabledButton }}
                              clickEvent={submitForm}
                              size="default"
                            >
                              {"Guardar"}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{ type: "button" }}
                              clickEvent={() => {
                                setedit(true);
                              }}
                              size="default"
                            >
                              {"Editar"}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="detailcontainer">
                      <h2>
                        {edit ? "Editar Aplicación" : "Detalles de Aplicación"}
                      </h2>
                      <div className="input-container">
                        <FormField
                          type="text"
                          label="Nombre"
                          disabled={!edit}
                          required={{
                            required: true,
                            requiredMsg: "Por favor ingresa un nombre.",
                          }}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="name"
                          value={values.name}
                        />
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label className="form-label">
                            Descripción
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={description}
                            onChange={handleTextAreaChange}
                            disabled={!edit}
                          />
                        </Form.Group>
                        {!edit ? (
                          load ? (
                            <>
                              <Link
                                className="form-link"
                                to={
                                  "/users/detail/" +
                                  getApplication.data.application.createdBy.id
                                }
                                style={{textDecoration:"none"}}
                              >
                                <FormField
                                  type="text"
                                  label="Creado por el usuario"
                                  disabled={!edit}
                                  inputElAttributes={{
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                  }}
                                  name="createdBy"
                                  value={values.createdBy}
                                />
                              </Link>
                            </>
                          ) : null
                        ) : null}
                      </div>
                      <div className="buttonspan"></div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default ApplicationDetail;
