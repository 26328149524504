import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createApplication as mutation } from "../../../Util/Queries/Mutations";
import { getApplications } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface ApplicationFormValues {
  name: string;
}

export interface ApplicationFormValidation {
  name: TFieldValidation;
}

const RegisterApplication: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [createApplication, { data, loading, error }] = useMutation<any>(
    mutation,
    {
      update: (cache, { data: { createApplication } }) => {
        const { applications }: any = cache.readQuery({
          query: getApplications,
        });
        const newApplications = [...applications, createApplication];
        cache.writeQuery({
          query: getApplications,
          data: { applications: newApplications },
        });
      },
    }
  );
  const [mainpage, setmainpage] = useState<boolean>(false);

  const defaultFormValues: ApplicationFormValues = {
    name: "",
  };

  const formValidation: ApplicationFormValidation = {
    name: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    ApplicationFormValues,
    ApplicationFormValidation
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on register mutation
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado la aplicación con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data, error, loading]);

  //submit register data to backend
  const submitForm = () => {
    let Applicationdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });

    if (!description) {
      emptyField = true;
    }

    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      try {
        Applicationdata = {
          name: values.name,
          description: description,
        };
        createApplication({ variables: { data: Applicationdata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Aplicación</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa el nombre de la aplicación.",
                      }}
                      name="name"
                      value={values.name}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.name}
                    />
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={description}
                        onChange={handleTextAreaChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/applications" />
        )
      }
    </Consumer>
  );
};

export default RegisterApplication;
