import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState, useCallback } from "react";
import { useAlert } from "react-alert";
import { Pagination, FormControl, InputGroup, Spinner } from "react-bootstrap";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getInstalledProductsWithPagination, downloadInstalledProductsReport } from "../../Util/Queries/Queries";
import Table, { Row } from "../Atoms/Table/Table";
import Button from "../Atoms/Button";
import "./styles.scss";
import { getDays } from '../../Util/utils';
import { preProcessFile } from "typescript";

const InstalledProducts: React.FC<{ history: History }> = (props) => {
  const [searchString, setSearchString] = useState<string>('');
  const [load, setLoad] = useState<boolean>(false);
  const [array, setArray] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [active, setActive] = useState<number>(1);
  const [exec, { loading, error, data }] = useLazyQuery(getInstalledProductsWithPagination, {
    fetchPolicy: "network-only",
  });

  const [downloadReportExec, downloadReportResponse] = useLazyQuery(downloadInstalledProductsReport, {
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if(!downloadReportResponse.loading){
      if(downloadReportResponse.data){
        window.open(downloadReportResponse.data.downloadInstalledProductsReport.link, "_blank")
        //props.history.push(downloadReportResponse.data.downloadInstalledProductsReport.link);
      }
      if (downloadReportResponse.error) {
        downloadReportResponse.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          }
          else{
            return errores.error("Ha ocurrido un error");
          }
        });
      }
    }
  },[downloadReportResponse.loading])
 

  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);
  const [paginationItems, setpaginationItems] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();

  //main Useeffect that retreives data from backend, and handles errors, if it success fet chng data it fills pagination, and table components
  useEffect(() => {
    if (!loading) {
      if (data) {
        setShowLoader(false);
        fillPagination();
        createtable();
      }
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          }
          else{
            return errores.error("Ha ocurrido un error");
          }
        });
        if(error.networkError){
          console.log(error.networkError);
          exec({
            variables: { filter: { pagination: { currentPage: 1, limit: 10 } } },
          });
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);


  //useEffect that executes the lazyquery on component mount
  useEffect(() => {
    exec({
      variables: { filter: { pagination: { currentPage: 1, limit: 10 } } },
    });
  }, [exec]);

  //pagination and search functions

  const handleClick = useCallback(
    (page:any) => {
      setActive(page);
      exec({
        variables: { 
          filter: { 
            pagination: { currentPage: page, limit: 10 },
            search: {
              value: searchString,
              fields: [
                "id",
                "registered_product__application.name",
                "registered_product__user.firstName",
                "registered_product__user.lastName",
                "registered_product__responsible.firstName",
                "registered_product__responsible.lastName",
                "registered_product__plant__plantPortfolio__salesman.firstName",
                "registered_product__plant__plantPortfolio__salesman.lastName",
                "registered_product__plant.name",
                "registered_product__plant__client.name",
                "registered_product__application.name",
                "distributor",
              ],
            }
          }
        },
      });
    },
    [exec, searchString],
  )


  const fillPagination = useCallback(
    () => {
      let items: any = [];
      setTotalPages(data.registeredProductsWithPagination.pagination.totalPages);
      for (let number = active - 4; number < active; number++) {
        if (number >= 1) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === active}
              onClick={() => handleClick(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }
      for (let number = active; number <= active + 4; number++) {
        if (number <= data.registeredProductsWithPagination.pagination.totalPages) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === active}
              onClick={() => handleClick(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }
  
      setpaginationItems(items);
      setPaginationLoad(true);
    },
    [active, data, handleClick],
  )

  //load table content
  const createtable = useCallback(
    () => {
      var arr: any = [];
      var rows: any = [];
      data.registeredProductsWithPagination.items.map((product:any)=>{
        arr = [...arr, product];
        const row: Row = {
          tds: [
            {
              valor: product.id,
            },
            {
              valor: product.product?product.product.code:""
            },
            {
              valor: product.internalCode
            },
            {
              valor: product.plant.client.name
            },
            {
              valor: product.plant.name
            },
            {
              valor: product.application.name
            },
            {
              valor: product.identifier
            },
            {
              valor: product.productStyle.productLine.name
            },
            {
              valor: product.user ? (
                `${product.user.firstName} ${product.user.lastName}`
              ):(product.responsible ? (
                `${product.responsible.firstName} ${product.responsible.lastName}`
              ): "")
            },
            {
              valor: product.productStyle.name,
            },
            {
              valor: product.fluidType,
            },
            {
              valor: product.installedAt,
            },
            {
              valor: product.estimatedChange,
            },
            {
              valor: product.replacedBy ? (
                product.replacedBy[0] ? (
                  product.replacedBy[0].installedAt ? (
                    product.replacedBy[0].installedAt
                  ) : ""
                ) : ""
              ): ""
            },
            {
              valor: product.replacedBy ? (
                product.replacedBy[0] ? (
                  product.replacedBy[0].installedAt ? (
                    `${getDays(product.installedAt, product.replacedBy[0].installedAt)} días`
                  ) : ""
                ) : ""
              ): ""
            },
            {
              valor: product.plant.plantPortfolio ? (
                product.plant.plantPortfolio.salesman ? (
                  `${product.plant.plantPortfolio.salesman.firstName} ${product.plant.plantPortfolio.salesman.lastName}` 
                ): ""
              ): ""
            },
            {
              valor: `${product.createdBy.firstName} ${product.createdBy.lastName}`,
            },
            {
              valor: product.distributor,
            },
            {
              valor: product.createdInBehalfOf ? (
                `${product.createdInBehalfOf.firstName} ${product.createdInBehalfOf.lastName}`
              ): "",
            }
          ],
        };
        return rows.push(row);
      }) 
      const a = {
        headers: [
          "ID",
          "CODIGO GARLOCK",
          "CODIGO INTERNO",
          "EMPRESA",
          "PLANTA",
          "APLICACION",
          "NUMERO IDENTIFICACION",
          "LINEA PRODUCTO",
          "USUARIO/RESPONSABLE",
          "ESTILO",
          "FLUIDO",
          "FECHA INSTALACIÓN",
          "FECHA ESTIMADA DE CAMBIO",
          "FECHA DE CAMBIO",
          "DURACIÓN",
          "VENDEDOR DE PLANTA",
          "REGISTRADO POR",
          "DISTRIBUIDOR",
          "REGISTRADO EN NOMBRE DE"
        ],
        rows: rows,
      };
      setLoad(false)
      setArray(a);
    },
    [data],
  )

  const performSearch = (e: any) => {
    setActive(1);
    exec({
      variables: {
        filter: {
          search: {
            value: e,
            fields: [
              "id",
              "registered_product__application.name",
              "registered_product__user.firstName",
              "registered_product__user.lastName",
              "registered_product__responsible.firstName",
              "registered_product__responsible.lastName",
              "registered_product__plant__plantPortfolio__salesman.firstName",
              "registered_product__plant__plantPortfolio__salesman.lastName",
              "registered_product__plant.name",
              "registered_product__plant__client.name",
              "registered_product__application.name",
              "distributor",
            ],
          },
          pagination: {
            currentPage: 1,
            limit: 10,
          },
        },
      },
    });
  };

  const debounce = (func: any, wait: number, e: any) => {
    let timeout: any;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(e);
    }, wait);
  };

  const handleSearchChange = (e: any) => {
    setSearchString(e.target.value);
    debounce(performSearch, 400, e.target.value);
  };

  const renderdetails = (product: Row) => {
    props.history.push(`/installedproducts/detail/${product.tds[0].valor}`);
    // errores.show("No se pueden visualizar los detalles del producto, consulta la app para mas información ")
  };

  return (
    <Consumer>
      {(context) => (
        <div className="plants">
          {mainPage === true ? (
            context.setLogged(false)
          ) : showLoader ? (
            <div className="load">
              <div className="dot"></div>
              <div className="outline">
                <span></span>
              </div>
            </div>
          ) : load === false ? (
            //If data is already loaded
            <div className="table-display">
              <div className="title-span">
                <h3>Productos Instalados por Planta</h3>
              </div>
              <div className="btnspan">
                <InputGroup>
                  <FormControl
                    placeholder="Buscar..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="search-bar"
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      type="primary"
                      elementAttr={{ type: "button" }}
                      size="default"
                      clickEvent={() => {
                        console.log('download');
                        downloadReportExec();
                      }}
                    >
                      {"Descargar reporte"}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              <div className="center-table">

                {loading ? (
                  <div className="spinnerSpan">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ):(
                  <Table
                    headers={array.headers}
                    rows={array.rows}
                    type="distributor"
                    onrowclick={renderdetails}
                  />
                )}
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                      <Pagination.First onClick={() => handleClick(1)} />
                      <Pagination.Prev
                        key={active - 1}
                        onClick={() => handleClick(active - 1)}
                      />
                    </>
                  ) : null}
                  {paginationItems}
                  {active < totalPages ? (
                    <>
                      <Pagination.Next
                        key={active + 1}
                        onClick={() => handleClick(active + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handleClick(totalPages)}
                      />
                    </>
                  ) : null}
                </Pagination>
              ) : null}
            </div>
          ): null}
        </div>
      )}
    </Consumer>
  );
};

export default InstalledProducts;
