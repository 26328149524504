import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createPlant as mutation } from "../../../Util/Queries/Mutations";
import {
  getClients,
  getPlants,
  getPortfolios,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface PlantFormValues {
  name: string;
  address: string;
  contactNumber: string;
}

export interface PlantFormValidation {
  name: TFieldValidation;
  address: TFieldValidation;
  contactNumber: TFieldValidation;
}

const RegisterPlant: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [mainpage, setmainpage] = useState<boolean>(false);
  const [portfolioOptions, setPortfolioOptions] = useState<any>([]);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [clientsOptions, setClientsOptions] = useState<any>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [portfolioOptionSelected, setPortfolio] = useState<any>({
    value: " ",
    label: "Selecciona un portafolio",
  });
  const [clientOptionSelected, setClient] = useState({
    value: "",
    label: "Selecciona una empresa",
  });
  const [createPlant, { data, loading, error }] = useMutation<any>(mutation, {
    update: (cache, { data: { createPlant } }) => {
      const { plants }: any = cache.readQuery({ query: getPlants });
      const newPlants = [...plants, createPlant];
      cache.writeQuery({
        query: getPlants,
        data: { plants: newPlants },
      });
    },
  });
  const listPortfolios = useQuery(getPortfolios, {
    fetchPolicy: "network-only",
  });
  const listClients = useQuery(getClients, { fetchPolicy: "network-only" });
  const defaultFormValues: PlantFormValues = {
    name: "",
    address: "",
    contactNumber: "",
  };

  const formValidation: PlantFormValidation = {
    name: "not-empty",
    address: "not-empty",
    contactNumber: "number",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    PlantFormValues,
    PlantFormValidation
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on register mutation
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 468) {
            return alert.error("Una planta con el mismo nombre ya se encuentra registrada");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado la planta con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data, error, loading]);

  //Fetch registered Clients to fill select
  useEffect(() => {
    if (!listClients.loading) {
      if (listClients.data) {
        let arr: any = [];
        listClients.data.clients.items.map((empresa: any) => {
          return arr = [...arr, { value: empresa.id, label: empresa.name }];
        });
        setClientsOptions(arr);
      }
    }
  }, [listClients]);

  useEffect(() => {
    if (!listPortfolios.loading) {
      if (listPortfolios.data) {
        let arr: any = [];
        listPortfolios.data.portfolios.items.map((portafolio: any) => {
          return arr = [...arr, { value: portafolio.id, label: portafolio.name }];
        });
        setPortfolioOptions(arr);
      }
    }
  }, [listPortfolios.data, listPortfolios.loading]);

  const handlePortfolioChange = (option: any) => {
    setPortfolio(option);
  };

  const handleClientChange = (option: any) => {
    setClient(option);
  };
  //submit register data to backend
  const submitForm = () => {
    let Plantdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectClient: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (clientOptionSelected.value === "") {
      errorSelectClient = true;
    }
    if (errorsFound || emptyField || errorSelectClient) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (errorSelectClient) {
        alert.error(
          "Por favor indique la empresa a la que pertenece la planta"
        );
      }
    } else {
      setDisabledButton(true);
      try {
        portfolioOptionSelected.value !== " "
          ? (Plantdata = {
              name: values.name,
              address: values.address,
              contactNumber: values.contactNumber,
              client_id: clientOptionSelected.value,
              portfolio_id: portfolioOptionSelected.value,
            })
          : (Plantdata = {
              name: values.name,
              address: values.address,
              contactNumber: values.contactNumber,
              client_id: clientOptionSelected.value,
            });
        createPlant({ variables: { data: Plantdata } }).catch((error) => {
          console.log(error.graphQLErrors[0].message.code);
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Planta</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa el nombre de la Planta.",
                      }}
                      name="name"
                      value={values.name}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.name}
                    />
                    <FormField
                      type="text"
                      label="Direccion"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa una direccion.",
                      }}
                      name="address"
                      value={values.address}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.address}
                    />

                    <FormField
                      type="text"
                      label="Numero de Contacto"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un telefono válido.",
                      }}
                      name="contactNumber"
                      value={values.contactNumber}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.contactNumber}
                    />
                    <div className="spacer">
                      <h5>Empresa a la que pertenece la planta</h5>
                      
                       {!listClients.loading ? (
                        <Select
                        options={clientsOptions}
                        value={clientOptionSelected}
                        onChange={handleClientChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>
                    <div className="spacer">
                      <h5>Portafolio</h5>
                      
                       {!listPortfolios.loading ? (
                        <Select
                        options={portfolioOptions}
                        value={portfolioOptionSelected}
                        onChange={handlePortfolioChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/plants" />
        )
      }
    </Consumer>
  );
};

export default RegisterPlant;
