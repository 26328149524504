import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Table from "react-bootstrap/Table";
import { ComponentType } from "../../Pages/Dashboard";
import "./Table.scss";


export interface TD {
  valor: string | string[];
}

export interface Row {
  tds: TD[];
}

export interface tableprops {
  onrowclick: (state: Row) => void;
  headers: string[];
  rows: Row[];
  type: ComponentType;
  reorder?:boolean;
  onReorderEnd?:(temp:any)=>void
}

const TableComp: React.FC<tableprops> = (props) => {
const [items, setItems] = useState<any>([])

// a little function to help us with reordering the result
const reorder = (list:any, startIndex:any, endIndex:any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
useEffect(() => {
  let temp:any=[];
  props.rows.map(row=>{
    let obj={id:row.tds[0].valor,name:row.tds[1].valor};
    return temp=[...temp,obj]
  })
  setItems(temp)
}, [props])
const grid = 8;

const getItemStyle = (isDragging:any, draggableStyle:any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver:any) => ({
  background: isDraggingOver ? "lightblue" : "white",
  padding: grid,
  width: '100%'
});

  const onDragEnd=(result:any)=> {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const temp = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    
    setItems(temp)
    //@ts-ignore
    props.onReorderEnd(temp)
  }



    

  return (
    <div className="main-container">

      {props.reorder?
      (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <table
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item:any, index:any) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
  )
      :
      
      props.type === "resources" ? 
      //Special case to render a different table on resources list
      (


        <Table responsive="sm">
          <thead>
            <tr>
              {props.headers.map((header, k) => {
                return <th key={k}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {props.rows.map((row, k) => {
              return (
                <tr key={k}>
                  {row.tds.map((td, k, arr) => {
                    if (arr.length - 1 === k) {
                        //@ts-ignore
                      return (<td key={k}><div className="image-link"><a href={td.valor} target="_blank" rel="noopener noreferrer">Click para abrir</a></div></td>)
                    }
                    else{
                        return <td key={k} onClick={() => props.onrowclick(row)}>{td.valor}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Table responsive="sm">
          <thead>
            <tr>
              {props.headers.map((header, k) => {
                return <th key={k}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            
            {props.rows.map((row, k) => {
              return (
                <tr key={k} onClick={() => props.onrowclick(row)}>
                  {row.tds.map((td, k,arr) => {
                    if(props.type==="product"){
                      if (2=== k) {
                      return <td key={k} className="last-td">{td.valor}</td>
                      }
                      else{
                        return(<td key={k}>{td.valor}</td>)
                      }
                    }
                    else if(props.type==="productLine" || "productStyle"){
                      if (arr.length - 1 === k) {
                      return <td key={k} className="last-td">{td.valor}</td>
                      }
                      else{
                        return(<td key={k}>{td.valor}</td>)
                      }
                    }
                    
                    if(props.type==="users"){
                      if (k===2) {
                      return <td key={k} className="email-td">{td.valor}</td>
                      }
                      else{
                        return(<td key={k}>{td.valor}</td>)
                      }
                    }
                    else{
                    return (
                      <td key={k}>
                        {Array.isArray(td.valor) ? (
                          <ul>
                            {td.valor.map((valor) => (
                              <li>{valor}</li>
                            ))}
                          </ul>
                        ) : (
                          td.valor
                        )}
                      </td>
                    );
                        }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )
          }
    </div>
  );
};

export default TableComp;
