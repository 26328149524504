export const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function getDays(firstDate: string, secondDate: string){
    return Math.floor(( Date.parse(secondDate) - Date.parse(firstDate) ) / 86400000); 
}