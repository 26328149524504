/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Redirect, useParams, Link } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { EditUser } from "../../../Util/Queries/Mutations";
import { getUserById as query, getUsers, getDistributors } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import { deleteUser, deleteUserDSO, resendCode } from "../../../Util/Queries/Mutations";
import Table, { Row } from "../../Atoms/Table/Table";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import { Col, Row as BootstrapRow, Container, Button as Bbutton } from "react-bootstrap";
import Select from 'react-select'
export interface UserDetailValidations {
  firstName: TFieldValidation;
  lastName: TFieldValidation;
  email: TFieldValidation;
  phoneNumber: TFieldValidation;
  department?: TFieldValidation;
  function?: TFieldValidation;
  createdAt?: TFieldValidation;
  status?: TFieldValidation;
  plant: TFieldValidation;
}
export interface UserDetailForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  department?: string;
  function?: string;
  createdAt: string;
  status: string;
  plant: string;
  plantPortfolios?: string[];
}

const UserDetail: React.FC<any> = (props) => {
  //hooks
  const [success, setSuccess] = useState<boolean>(false);
  const [edit, setedit] = useState<boolean>(false);
  const [edituser, { loading, data, error }] = useMutation(EditUser);
  const [deleteuser, deleteStatus] = useMutation(deleteUser);
  const [resend, resendStatus] = useMutation(resendCode);
  const [deleteDSO, deleteDSOStatus] = useMutation(deleteUserDSO);
  const [modal, setModal] = useState<boolean>(false);
  const [portfolioArray, setPortfolioArray] = useState<any>([]);
  const [distributorsArray, setDistributorsArray] = useState<any>([]);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(true);
  //const
  const alert = useAlert();
  const porfolioRows: any = [];
  const distributorRows: any = [];
  const { id } = useParams();
  //Apollohooks
  const getUser = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy: "network-only"
  });

  const [distributorOptions, setDistributorOptions] = useState<any>([]);
  const [selectedDistributor, setSelectedDistributor] = useState<any>({
    value: null, label: "",
  })

  const listDistributors = useQuery(getDistributors, {fetchPolicy: "network-only"});

  const updateCache = (user: any) => {
    const data = user.readQuery({
      query: getUsers,
    });
    const newData = {
      users: data.users.filter((t: any) => t.id !== id),
    };
    user.writeQuery({
      query: getUsers,
      data: newData,
    });
  };

  //formvalues

  const defaultFormValues: UserDetailForm = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    department: "",
    function: "",
    createdAt: "",
    status: "",
    plantPortfolios: [],
    plant: "",
  };

  const formValidation: UserDetailValidations = {
    firstName: "not-empty",
    lastName: "not-empty",
    email: "email",
    phoneNumber: "number",
    department: "not-empty",
    function: "not-empty",
    createdAt: "not-empty",
    status: "not-empty",
    plant: "not-empty",
  };

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getUser.loading) {
      if (getUser.data) {
        if (getUser.data.user.role === "SALESMAN") {
          createtable();
          setSelectedDistributor({
            value: getUser.data.user.distributor 
              ? getUser.data.user.distributor.id 
              : null,
            label: getUser.data.user.distributor
              ? getUser.data.user.distributor.name
              : null,
          });
        }
        forceUpdateValue({
          firstName: getUser.data.user.firstName,
          lastName: getUser.data.user.lastName,
          email: getUser.data.user.email,
          phoneNumber: getUser.data.user.phoneNumber
            ? getUser.data.user.phoneNumber
            : "",
          department: getUser.data.user.department
            ? getUser.data.user.department
            : "",
          function: getUser.data.user.function
            ? getUser.data.user.function
            : "",
          plant: getUser.data.user.plant
            ? getUser.data.user.plant.name
            : "",
          createdAt: getUser.data.user.createdAt,
          status:
            getUser.data.user.status === "ACTIVE"
              ? "Activo"
              : getUser.data.user.status === "INACTIVE"
                ? "Inactivo"
                : getUser.data.user.status === "DELETED"
                  ? "Eliminado"
                  : getUser.data.user.status === "USER_DEACTIVE" ?
                  "Desactivado" :
                  "Pendiente",
        });
      }
      if (getUser.error) {
        getUser.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getUser.loading]);

  // Use effect to get the possible distributors for this user.
  useEffect(() => {
    if(!listDistributors.loading){
      if(listDistributors.data){
        // Fill the distributors options array
        let arr: any = [];
        listDistributors.data.distributors.items.map((distributor: any) => {
          return arr = [
            ...arr,
            {
              value: distributor.id,
              label: distributor.name,
            }
          ]
        });
        setDistributorOptions(arr);
      }
    }
  }, [listDistributors.data, listDistributors.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    forceUpdateValue,
  } = useForm<UserDetailForm, UserDetailValidations>(
    defaultFormValues,
    formValidation
  );

  const handleDistributorChange = (option: any) => {
    setSelectedDistributor(option);
  }
 
  //submit edited data to bakcend
  const submitForm = () => {
    let dataModified: any = {};
    let emptyField: boolean = false;
    let phoneError: boolean = false;
    let errorsFound: boolean = false;
    let phoneLength: boolean = false;
    let phoneNaN: boolean = false;
    if (values.firstName === "" || values.lastName === "") {
      emptyField = true;
    }
    if (getUser.data.user.role === "USER_CLIENT") {
      if (values.department === "" || values.function === "") {
        emptyField = true;
      }
    }
    phoneError =
      values.phoneNumber.length > 0 ? isNaN(+values.phoneNumber) : true;
    for (let i in values) {
      //@ts-ignore
      if (i !== "__typename" && values[i].length > 0) {
        //@ts-ignore
        if (getUser.data.user[i] !== values[i])
          if (i !== "plant" && i !== "plantPortfolio") {
            //@ts-ignore
            dataModified[i] = values[i];
          }
      }
    }
    if (dataModified.status === "Pendiente") {
      dataModified.status = "PENDING";
    } else {
      dataModified.status = "ACTIVE";
    }

    if(selectedDistributor.value !== null) {
      dataModified["distributor_id"] = parseInt(selectedDistributor.value);
    }

    if (!phoneError) {
      if (values.phoneNumber.length !== 10) {
        phoneLength = true;
      }
    }
    if (errorsFound || emptyField || phoneError || phoneLength) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (phoneError) {
        alert.error("El telefono debe contener solo caracteres numéricos");
      }
      if (phoneNaN) {
        alert.error(
          "El telefono debe contener unicamente caracteres numericos"
        );
      }
      if (phoneLength) {
        alert.error("El telefono debe contener 10 digitos");
      }
    } else {
      setDisabledButton(true)
      try {
        edituser({
          variables: {
            id: +getUser.data.user.id,
            data: dataModified,
          },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false)
      }
    }
  };

  //fill porfolios belonging to this user in casi it's a Salesman User
  const createtable = () => {
    var portfoliosArr: any = [];
    var plants: any = [];
    getUser.data.user.plantPortfolios.map((portfolio: any) => {
      portfoliosArr = [...portfoliosArr, portfolio];
      plants = [];
      portfolio.plants.map((plant: any) => {
        return plants.push(plant.name);
      });
      const porfolioRow: Row = {
        tds: [
          {
            valor: portfolio.id,
          },
          {
            valor: portfolio.name,
          },
          {
            valor: plants,
          },
        ],
      };
      return porfolioRows.push(porfolioRow);
    });
    const porfoliosTable = {
      headers: ["ID", "NOMBRE", "PLANTAS"],
      rows: porfolioRows,
    };
    setPortfolioArray(porfoliosTable);

    var distributorsArr: any = [];
    getUser.data.user.salesmanDistributors.map((distributor: any) => {
      distributorsArr = [...distributorsArr, distributor];
      const distributorRow: Row = {
        tds: [
          {
            valor: distributor.id,
          },
          {
            valor: distributor.name
          },
          {
            valor: distributor.phoneNumber
          },
        ],
      };
      return distributorRows.push(distributorRow); 
    });
    const distributorsTable = {
      headers: ['ID', 'NOMBRE', 'TELÉFONO'],
      rows: distributorRows,
    }

    setDistributorsArray(distributorsTable);
    setLoad(false);
  };

  useEffect(() => {
    if (!resendStatus.loading) {
      if (resendStatus.data) {
        alert.success("Se ha reenviado el código de registro");
      }
      if (resendStatus.error) {
        alert.error("Ha ocurrido un problema al enviar el código");
      }
    }
  }, [resendStatus])

  //UseEffect to handle success/error on delete event
  useEffect(() => {
    if (!deleteStatus.loading || !deleteDSOStatus.loading) {
      if (deleteStatus.error) {
        deleteStatus.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          }
          else if (error.message.code === 505) {
            return alert.error("No se puede eliminar el usuario ya que tiene relación con otros elementos, elimine estos elementos primero");
          }
          else {
            return alert.error("Ha ocurrido un error");
          }
        });
      } else {
        if (deleteStatus.data) {
          alert.success(
            "Se ha eliminado el usuario con exito",
            {
              onClose: () => {
                setSuccess(true);
              },
            }
          );
        }
        if (deleteDSOStatus.data) {
          alert.success(
            "Se ha eliminado el usuario con exito",
            {
              onClose: () => {
                setSuccess(true);
              },
            }
          );
        }
      }
    }
  }, [deleteStatus.loading, deleteDSOStatus.loading]);

  //handle table row click
  const handlePortfolioRowClick = (portfolio: Row) => {
    props.history.push(`/portfolios/detail/${portfolio.tds[0].valor}`);
  };

  const handleDistributorRowClick = (distributor: Row) => {
    props.history.push(`/distributors/detail/${distributor.tds[0].valor}`);
  };

  // execute delete mutation
  const performDelete = (context: any) => {
    setDisabledButton(true)
    if (context.user.role === "ADMIN") {
      deleteuser({
        variables: {
          id: +getUser.data.user.id,
        },
        update: updateCache,
      });
    } else {
      deleteDSO({
        variables: {
          id: +getUser.data.user.id,
          status: "INACTIVE",
        },
        update: updateCache,
      });
    }
    setModal(false);
  };

  const resendRegisterCode = () => {
    resend({
      variables: {
        id: +getUser.data.user.id,
      }
    })
  }
  //UseEffect to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false)
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha modificado el usuario con exito", {
          onClose: () => {
            setSuccess(true);
          },
        });
      }
    }
  }, [loading]);

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : success ? (
          <Redirect to="/users" />
        ) : !getUser.loading ? (
          getUser.data ? (
            <div className="content-container">
              <ConfirmationModal
                show={modal}
                onHide={() => setModal(false)}
                onCancel={() => setModal(false)}
                clickEvent={() => {
                  performDelete(context);
                }}
                message={
                  <div>
                    Está seguro que desea eliminar este usuario?
                    <br /> Esta acción no se puede revertir...
                  </div>
                }
                action="Eliminar Usuario"
                type="delete"
              />
              <div className="mainContainer">
                <div className="buttonspan">
                  <label></label>
                  {
                    edit ?
                      <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={() => { setedit(false) }}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      : null
                  }
                  <div className="buttonCustom">
                    {context.user.role === "DSO" ? (
                      <Button
                        type="danger"
                        elementAttr={{ type: "button", disabled: disabledButton }}
                        clickEvent={() => {
                          setModal(true);
                        }}
                        size="default"
                      >
                        {"Eliminar"}
                      </Button>
                    ) : (
                        <Button
                          type="danger"
                          elementAttr={{ type: "button", disabled: disabledButton }}
                          clickEvent={() => {
                            setModal(true);
                          }}
                          size="default"
                        >
                          {"Eliminar"}
                        </Button>
                      )}
                    {edit ? (
                      <>

                        <Button
                          type="primary"
                          elementAttr={{ type: "button", disabled: disabledButton }}
                          clickEvent={submitForm}
                          size="default"
                        >
                          {"Guardar"}
                        </Button>
                      </>
                    ) : (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button" }}
                            clickEvent={() => {
                              setedit(true);
                            }}
                            size="default"
                          >
                            {"Editar"}
                          </Button>
                        </>
                      )}
                  </div>
                </div>
                <div className="detailcontainer">
                  <h2>{edit ? "Editar Usuario" : "Detalles de Usuario"}</h2>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      disabled={!edit}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      name="firstName"
                      value={values.firstName}
                    />
                    <FormField
                      type="text"
                      label="Apellido"
                      name="lastName"
                      value={values.lastName}
                      disabled={!edit}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                    />
                    <FormField
                      type="text"
                      label="Correo"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un correo válido.",
                      }}
                      name="email"
                      value={values.email}
                      disabled={!edit}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        disabled: true
                      }}
                    />
                    <FormField
                      type="text"
                      label="Telefono"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      disabled={!edit}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur
                      }}
                    />
                    {getUser.data.user.role === "USER_CLIENT" ? (
                      <>
                        {
                          !edit ?
                            <Link
                              className="form-link"
                              to={
                                "/plants/detail/" +
                                getUser.data.user.plant.id
                              }
                              style={{ textDecoration: "none" }}
                            >
                              <FormField
                                type="text"
                                label="Planta"
                                name="plant"
                                //@ts-ignore
                                value={values.plant}
                                disabled={!edit}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Link> : null
                        }
                        <FormField
                          type="text"
                          label="Departamento"
                          name="department"
                          //@ts-ignore
                          value={values.department}
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                        <FormField
                          type="text"
                          label="Funcion"
                          name="function"
                          //@ts-ignore
                          value={values.function}
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                      </>
                    ) : null}
                    <Container fluid>

                      <BootstrapRow>
                        <Col style={{ paddingLeft: '0' }}>

                          <FormField
                            type="text"
                            label="Estatus"
                            name="status"
                            //@ts-ignore
                            value={values.status}
                            disabled={true}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                              disabled: edit
                            }}
                          />
                        </Col>
                        {
                          getUser.data.user.status === "PENDING" ?
                            <Col md="2" style={{ paddingLeft: '0' }}>
                              <Button
                                type="primary"
                                elementAttr={{ type: "button", style: { position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 'auto' } }}
                                clickEvent={resendRegisterCode}
                                size="small"
                              >
                                {"Reenviar código"}
                              </Button>
                            </Col>
                            : null
                        }
                      </BootstrapRow>
                    </Container>
                    <FormField
                      type="text"
                      label="Rol"
                      name="Rol"
                      value={
                        getUser.data.user.role === "ADMIN"
                          ? "Administrador"
                          : getUser.data.user.role === "USER_CLIENT"
                            ? "Cliente"
                            : getUser.data.user.role === "DSO"
                              ? "DSO"
                              : getUser.data.user.role === "SALESMAN"
                                ? "Vendedor"
                                : getUser.data.user.role === "AGENT"
                                  ? "Agente"
                                  : ""
                      }
                      disabled={true}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        disabled: edit
                      }}
                    />
                    {getUser.data.user.role === "SALESMAN" ? (
                      <>
                        {!load && !edit ? (
                          <>
                           {selectedDistributor.value ? (
                              <Link
                                className="form-link"
                                to={
                                  "/distributors/detail/" +
                                  selectedDistributor.value
                                }
                              >
                                <FormField
                                  type="text"
                                  label="Distribuidor"
                                  name="distributor"
                                  //@ts-ignore
                                  value={selectedDistributor.label}
                                  disabled={true}
                                  inputElAttributes={{
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                  }}
                                />
                              </Link>
                            ) :  (
                              <FormField
                                type="text"
                                label="Distribuidor"
                                name="distributor"
                                //@ts-ignore
                                value={selectedDistributor.label}
                                disabled={true}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            )}
                            <h6>Portafolios del vendedor</h6>
                            <Table
                              headers={portfolioArray.headers}
                              rows={portfolioArray.rows}
                              type="plants"
                              onrowclick={handlePortfolioRowClick}
                            />
                            <h6>Distribuidores del vendedor</h6>
                            <Table
                              headers={distributorsArray.headers}
                              rows={distributorsArray.rows}
                              type="plants"
                              onrowclick={handleDistributorRowClick}
                            />
                          </>
                        ) : !load && edit ? (
                          <>
                          <h6>Selecciona un nuevo distribuidor</h6>
                          <div className="spacer">
                            <Select
                              styles={{
                                menu: provided => ({...provided, zIndex: 9999}),
                                menuPortal: provided => ({...provided, zIndex: 9999})
                              }}
                              menuPortalTarget={document.body}
                              menuPlacement="top"
                              value={selectedDistributor}
                              options={distributorOptions}
                              onChange={handleDistributorChange}
                            />
                          </div>
                        </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  <div className="buttonspan"></div>
                </div>
              </div>
            </div>
          ) : null
        ) : (
                <div className="load">
                  <div className="dot"></div>
                  <div className="outline">
                    <span></span>
                  </div>
                </div>
              )
      }
    </Consumer>
  );
};

export default UserDetail;
