import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as SvgCheckboxIcon } from "../../../assets/Icons/checkmark.svg";
import "./index.scss";
type TFormField = "text" | "checkbox" | "file" | "image"|"password";

export interface IRequiredInput {
  required: boolean;
  requiredMsg: string;
}

interface Props {
  type: TFormField;
  name: string;
  label: string;
  disabled?:boolean;
  link?: string;
  classVariant?:string;
  required?: IRequiredInput;
  error?: boolean | false;
  show?:()=>any;
  inputElAttributes?: React.InputHTMLAttributes<HTMLInputElement>;
  value: string | number;
  forceChangeValue?: (name: string, val: string) => void;
}

const FormField: React.FC<Props> = (props) => {
  let element = null;
  const [checked, setChecked] = useState<boolean>(false);

  if (props.type === "text") {
    element = (
        <div className="FormField FormFieldInputText">
        <div className="FormFieldWrapper">
          {props.disabled?
          <>
          <label className="form-label">{props.label}</label>
          <label className="inputDisabledElement">{props.value}</label>
        </>
        :
        (
          <>
        <input
            type="text"
            className={
              "FormFieldElement" +
              (props.value.toString().length ? " notEmpty" : "") +
              (props.error ? " isInvalid" : "")
            }
            {...props.inputElAttributes}
            required={props.required && props.required.required}
            value={props.value}
            name={props.name}
          />
          <label className="FormFieldLabel">
            <span className="FormFieldLabelText">{props.label}</span>
          </label>
          <span className="FormFieldBackground"></span>
          {props.required && props.required.required && (
            <div
            className={"FormFieldValidation " + (!props.error && "isHidden")}
            >
            <div className="FormFieldMessageContainer">
            <div className="FormFieldMessage FormFieldMessageError">
            {props.required.requiredMsg}
            </div>
            </div>
            </div>
            )}
            </>
            )
          }
          </div>
      </div>
    );
  } 
  if (props.type === "password") {
    element = (
        <div className="FormField FormFieldInputText">
        <div className="FormFieldWrapper">
          {props.disabled?
          <>
          <label className="form-label">{props.label}</label>
          <label className="inputDisabledElement">{props.value}</label>
        </>
        :
        (
          <>
        <input
            type="text"
            className={
              "FormFieldElement" +
              (props.value.toString().length ? " notEmpty" : "") +
              (props.error ? " isInvalid" : "") +(props.classVariant?(" " +props.classVariant) :"" )
            }
            {...props.inputElAttributes}
            required={props.required && props.required.required}
            value={props.value}
            name={props.name}
          />
          <label className="FormFieldLabel">
            <span className="FormFieldLabelText">{props.label}</span>
          </label>
          <span className="FormFieldBackground"></span>
          {props.required && props.required.required && (
            <div
            className={"FormFieldValidation " + (!props.error && "isHidden")}
            >
            <div className="FormFieldMessageContainer">
            <div className="FormFieldMessage FormFieldMessageError">
            {props.required.requiredMsg}
            </div>
            </div>
            </div>
            )}
            </>
            )
          }
          <div className="iconEye" onClick={props.show}></div>
          </div>

      </div>
    );
  }
  else if (props.type === "checkbox") {
    element = (
      <div className="FormField FormFieldInputCheckbox">
        <div
          className="FormFieldWrapper"
          onClick={() => {
            props.forceChangeValue &&
              props.forceChangeValue(props.name, checked ? "" : "1");
            setChecked(!checked);
          }}
        >
          <input
            type="checkbox"
            className={
              "FormFieldElement" +
              (props.value.toString().length ? " notEmpty" : "")
            }
            {...props.inputElAttributes}
            required={props.required && props.required.required}
            checked={checked}
            value={checked ? "1" : "2"}
            name={props.name}
          />
          <label className="FormFieldLabel">
            <span className="FormFieldIcon">
              <SvgCheckboxIcon className="Icon" />
            </span>
            <span
              className={
                "FormFieldLabelText" + (props.error ? " isInvalid" : "")
              }
            >
              {props.label}
            </span>
          </label>
        </div>
      </div>
    );
  } else if (props.type === "file") {
    element = (
      <div className="FormField FormFieldInputFile">
        <div className="FormFieldWrapper">
          <div className="upload-btn-wrapper">
            <Button variant="light">{props.label}</Button>
            <input
              type="file"
              {...props.inputElAttributes}
              required={props.required && props.required.required}
              value={props.value}
              name={props.name}
              id="file"
            />
          </div>

          <span className="FormFieldBackground"></span>
        </div>
        {props.required && props.required.required && (
          <div
            className={"FormFieldValidation " + (!props.error && "isHidden")}
          >
            <div className="FormFieldMessageContainer">
              <div className="FormFieldMessage FormFieldMessageError">
                {props.required.requiredMsg}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (props.type === "image") {
    element = (
      <div className="FormField FormFieldInputFile">
        <div className="FormFieldWrapper">
          <label
            className={
              "FormFieldElement" +
              (props.value.toString().length ? " notEmpty" : "") +
              " formfield-label"
            }
          >
            <div className="image-link">
              <a href={props.link ? props.link : "/"} target="_blank" rel="noopener noreferrer">
                Click para abrir
              </a>
            </div>
          </label>
          <label className="FormFieldLabel">
            <span className="FormFieldLabelText">{props.label}</span>
          </label>
          <span className="FormFieldBackground"></span>
        </div>
        {props.required && props.required.required && (
          <div
            className={"FormFieldValidation " + (!props.error && "isHidden")}
          >
            <div className="FormFieldMessageContainer">
              <div className="FormFieldMessage FormFieldMessageError">
                {props.required.requiredMsg}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return element;
};

export default FormField;
