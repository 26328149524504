/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { closeTrainingRequest as mutation } from "../../../Util/Queries/Mutations";
import {
  getTrainingRequestById as query,
  getTrainingRequests
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import Table, { Row } from "../../Atoms/Table/Table";

export interface TrainingRequestDetailValidations {
  type: TFieldValidation;
  proposedDate: TFieldValidation;
  user: TFieldValidation;
  status: TFieldValidation;
  id: TFieldValidation;
}
export interface TrainingRequestDetailForm {
  type: string;
  proposedDate: string;
  user: string;
  status: string;
  id: string;
}

const TrainingRequestDetail: React.FC<{ history: History }> = (props) => {
  //hooks
  const edit: boolean = false;
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [array, setArray] = useState<any>([]);
  const [modalMessage, setModalMessage] = useState<any>();
  const [notes, setNotes] = useState<string>("");
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [closeTrainingRequest, { data, loading, error }] = useMutation<any>(
    mutation
  );
  //const
  const alert = useAlert();
  const { id } = useParams();
  //Apollohooks
  const getTrainingRequest = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy: "network-only",
  });
  //formvalues
  const defaultFormValues: TrainingRequestDetailForm = {
    type: "",
    proposedDate: "",
    user: "",
    status: "",
    id: "",
  };

  const formValidation: TrainingRequestDetailValidations = {
    type: "not-empty",
    proposedDate: "not-empty",
    user: "number",
    status: "not-empty",
    id: "not-empty",
  };

  //update apolloclient cache
  const updateCache = (trainingRequest: any) => {
    const data = trainingRequest.readQuery({
      query: getTrainingRequests,
    });
    const newData = {
      trainingRequests: data.trainingRequests.filter((t: any) => t.id !== id),
    };
    trainingRequest.writeQuery({
      query: getTrainingRequests,
      data: newData,
    });
  };

  const createtable = () => {
    var arr: any = [];
    let rows: any = [];
    getTrainingRequest.data.trainingRequest.productLines.map(
      (productLine: any) => {
        arr = [...arr, productLine];
        const row: Row = {
          tds: [
            {
              valor: productLine.id,
            },
            {
              valor: productLine.name,
            },
            {
              valor: productLine.description,
            },
          ],
        };
        return rows.push(row);
      }
    );
    const a = {
      headers: ["ID", "NOMBRE", "DESCRIPCION"],
      rows: rows,
    };
    setArray(a);
  };

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getTrainingRequest.loading) {
      if (getTrainingRequest.data) {
        setLoad(true);
        setNotes(getTrainingRequest.data.trainingRequest.notes);
        setReason(getTrainingRequest.data.trainingRequest.reason.reason);
        createtable();
        forceUpdateValue({
          id: getTrainingRequest.data.trainingRequest.id,
          type: getTrainingRequest.data.trainingRequest.type,
          proposedDate: getTrainingRequest.data.trainingRequest.proposedDate,
          user: getTrainingRequest.data.trainingRequest.user
            ? getTrainingRequest.data.trainingRequest.user.firstName +
              " " +
              getTrainingRequest.data.trainingRequest.user.lastName
            : "",
          status: getTrainingRequest.data.trainingRequest.status,
        });
      }
      if (getTrainingRequest.error) {
        getTrainingRequest.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getTrainingRequest.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    forceUpdateValue,
  } = useForm<TrainingRequestDetailForm, TrainingRequestDetailValidations>(
    defaultFormValues,
    formValidation
  );

  const handleRowClick = (line: Row) => {
    props.history.push(`/productlines/detail/${line.tds[0].valor}`);
  };
  //UseEffects to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (data) {
        let msg = "";
        deleteRequest
          ? (msg = "Se ha eliminado la solicitud con exito")
          : (msg = "Se ha cerrado la solicitud con exito");

        alert.success(msg, {
          onClose: () => {
            props.history.push(`/trainingrequests`);
          },
        });
      }
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);

  //close training request mutation
  const closeRequest = () => {
    setDisabledButton(true);
    let dataModified: any = {};
    deleteRequest
      ? (dataModified = {
          status: "INACTIVE",
        })
      : (dataModified = {
          status: "CLOSED",
        });

    closeTrainingRequest({
      variables: {
        id: id ? +id : 0,
        data: dataModified,
      },
      update: updateCache,
    });
    setModal(false);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getTrainingRequest.loading ? (
          getTrainingRequest.data ? (
            load ? (
              <Form>
                <div className="content-container">
                  <ConfirmationModal
                    show={modal}
                    onCancel={() => setModal(false)}
                    onHide={() => setModal(false)}
                    clickEvent={closeRequest}
                    message={modalMessage}
                    action={
                      deleteRequest
                        ? "Eliminar Solicitud de Soporte"
                        : "Cerrar Solicitud de Soporte"
                    }
                    type={deleteRequest ? "delete" : "close"}
                  />
                  <div className="mainContainer">
                    <div className="buttonspan">
                      <label></label>
                      <div className="buttonCustom">
                        <>
                          <Button
                            type="primary"
                            elementAttr={{
                              type: "button",
                              disabled: disabledButton,
                            }}
                            clickEvent={() => {
                              setModalMessage(
                                "Se cerrara la solicitud de soporte..."
                              );
                              setModal(true);
                            }}
                            size="default"
                          >
                            {"Cerrar"}
                          </Button>
                          <Button
                            type="danger"
                            elementAttr={{
                              type: "button",
                              disabled: disabledButton,
                            }}
                            clickEvent={() => {
                              setDeleteRequest(true);
                              setModalMessage(
                                <div>
                                  Está seguro que desea eliminar este esta
                                  solicitud?
                                  <br /> Esta acción no se puede revertir...
                                </div>
                              );
                              setModal(true);
                            }}
                            size="default"
                          >
                            {"Eliminar"}
                          </Button>
                        </>
                      </div>
                    </div>
                    <div className="detailcontainer">
                      <h2>
                        {edit ? "Editar Solicitud" : "Detalles de la Solicitud"}
                      </h2>
                      <div className="input-container">
                        <FormField
                          type="text"
                          label="ID"
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="type"
                          value={values.id}
                        />
                        <FormField
                          type="text"
                          label="Tipo"
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="type"
                          value={
                            values.type === "TRAINING"
                              ? "Capacitación"
                              : "Visita Técnica"
                          }
                        />
                        <FormField
                          type="text"
                          label="Fecha propuesta"
                          name="proposedDate"
                          value={values.proposedDate}
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                        <Link
                          className="form-link"
                          to={
                            "/users/detail/" +
                            getTrainingRequest.data.trainingRequest.user.id
                          }
                          style={{ textDecoration: "none" }}
                        >
                          <FormField
                            type="text"
                            label="Usuario que la solicita"
                            name="user"
                            disabled={!edit}
                            value={values.user}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                            }}
                          />
                        </Link>
                        <FormField
                          type="text"
                          label="Estatus"
                          name="status"
                          disabled={!edit}
                          //@ts-ignore
                          value={
                            values.status === "ACTIVE"
                              ? "Activa"
                              : values.status === "CLOSED"
                              ? "Cerrada"
                              : "Inactiva"
                          }
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                            disabled: edit,
                          }}
                        />
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label className="form-label">
                            Anotaciones
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={notes}
                            disabled={!edit}
                          />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea2">
                          <Form.Label className="form-label">Razón</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={reason}
                            disabled={!edit}
                          />
                        </Form.Group>
                        <h6>Lineas de producto de la solicitud</h6>
                        <Table
                          headers={array.headers}
                          rows={array.rows}
                          type="productLine"
                          onrowclick={handleRowClick}
                        />
                      </div>
                      <div className="buttonspan"></div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default TrainingRequestDetail;
