import React from 'react';

interface Props {
    children: React.ReactElement | string,
    type?: 'default' | 'primary' | 'secondary'|'danger',
    size?: 'default' | 'small' | 'tiny' | 'large',
    elementAttr?: React.ButtonHTMLAttributes<HTMLButtonElement>,
    clickEvent?: any
};

const Button: React.FC<Props> = (props) => {
    return(
        <button
        onClick={props.clickEvent}
        className={'button button-' + (!props.type ? 'default' : props.type)
         + ' button-size-' + (!props.size ? 'default' : props.size)
         + ((props.elementAttr && props.elementAttr.disabled) ? ' isDisabled' : '') }
         {...props.elementAttr}
         >
        {props.children}
        </button>
    )
};

export default Button;