/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editTrainingRequestReason as mutation } from "../../../Util/Queries/Mutations";
import {
  getTrainingRequestReasonById as query,
  getTrainingRequestReasons,
  getUserById,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";

export interface TrainingRequestReasonDetailValidations {
  reason: TFieldValidation;
  createdAt: TFieldValidation;
  createdBy: TFieldValidation;
  status: TFieldValidation;
  id: TFieldValidation;
}
export interface TrainingRequestReasonDetailForm {
  reason: string;
  createdAt: string;
  createdBy: string;
  status: string;
  id: string;
}

const TrainingRequestReasonDetail: React.FC<{ history: History }> = (props) => {
  //hooks
  const [edit, setEdit] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [editTrainingRequestReason, { data, loading, error }] = useMutation<
    any
  >(mutation);
  //const
  const alert = useAlert();
  const { id } = useParams();
  //Apollohooks
  const getTrainingRequestReason = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy: "network-only",
  });
  const [getUser, getUserStatus] = useLazyQuery(getUserById, {
    fetchPolicy: "network-only",
  });
  //formvalues
  const defaultFormValues: TrainingRequestReasonDetailForm = {
    reason: "",
    createdAt: "",
    createdBy: "",
    status: "",
    id: "",
  };

  const formValidation: TrainingRequestReasonDetailValidations = {
    reason: "not-empty",
    createdAt: "not-empty",
    createdBy: "number",
    status: "not-empty",
    id: "not-empty",
  };
  //update apolloclient cache
  const updateCache = (trainingRequestReason: any) => {
    const data = trainingRequestReason.readQuery({
      query: getTrainingRequestReasons,
    });
    const newData = {
      trainingRequestReasons: data.trainingRequestReasons.filter(
        (t: any) => t.id !== id
      ),
    };
    trainingRequestReason.writeQuery({
      query: getTrainingRequestReasons,
      data: newData,
    });
  };

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getTrainingRequestReason.loading) {
      if (getTrainingRequestReason.data) {
        setLoad(true);
        getUser({
          variables: {
            id: +getTrainingRequestReason.data.trainingRequestReason.createdBy
              .id,
          },
        });
      }
      if (getTrainingRequestReason.error) {
        getTrainingRequestReason.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getTrainingRequestReason.loading]);

  useEffect(() => {
    if (!getUserStatus.loading) {
      if (getUserStatus.data) {
        forceUpdateValue({
          id: getTrainingRequestReason.data.trainingRequestReason.id,
          reason: getTrainingRequestReason.data.trainingRequestReason.reason,
          createdAt:
            getTrainingRequestReason.data.trainingRequestReason.createdAt,
          createdBy:
            getUserStatus.data.user.firstName +
            " " +
            getUserStatus.data.user.lastName,
          status: getTrainingRequestReason.data.trainingRequestReason.status,
        });
      }
    }
  }, [getUserStatus.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<
    TrainingRequestReasonDetailForm,
    TrainingRequestReasonDetailValidations
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (data) {
        let msg = "";
        deleteRequest
          ? (msg = "Se ha eliminado con éxito la razón de solicitud")
          : (msg = "Se han guardado los cambios...");
        alert.success(msg, {
          onClose: () => {
            props.history.push(`/reasons`);
          },
        });
      }
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 505) {
            return alert.error("Existen solicitudes de soporte con esta razón por lo que no es posible eliminarla.");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);

  //perform delete mutation
  const performDelete = () => {
    setDisabledButton(true);
    let dataModified: any = {};
    dataModified = {
      status: "INACTIVE",
    };
    editTrainingRequestReason({
      variables: {
        id: id ? +id : 0,
        data: dataModified,
      },
      update: updateCache,
    });
    setDeleteRequest(true);
    setModal(false);
  };

  //submit edited data to backend
  const submitForm = () => {
    let TrainingRequestReasondata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });

    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      try {
        TrainingRequestReasondata = {
          reason: values.reason,
          status: "ACTIVE",
        };
        editTrainingRequestReason({
          variables: { id: id ? +id : 0, data: TrainingRequestReasondata },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getUserStatus.loading ? (
          getUserStatus.data ? (
            load ? (
              <Form>
                <div className="content-container">
                  <ConfirmationModal
                    show={modal}
                    onHide={() => setModal(false)}
                    onCancel={() => setModal(false)}
                    clickEvent={performDelete}
                    message={
                      <div>
                        Está seguro que desea eliminar este esta razón?
                        <br /> Esta acción no se puede revertir...
                      </div>
                    }
                    action="Eliminar razón de solicitud de soporte"
                    reason="close"
                  />
                  <div className="mainContainer">
                    <div className="buttonspan">
                      <label></label>
                      {edit ? (
                        <Bbutton
                          size="sm"
                          variant="light"
                          className="backButton"
                          onClick={() => {
                            setEdit(false);
                          }}
                        >
                          <div className="backIcon"></div>
                        </Bbutton>
                      ) : null}
                      <div className="buttonCustom">
                        <Button
                          type="danger"
                          elementAttr={{
                            type: "button",
                            disabled: disabledButton,
                          }}
                          clickEvent={() => {
                            setModal(true);
                          }}
                          size="default"
                        >
                          {"Eliminar"}
                        </Button>
                        {edit ? (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{
                                type: "button",
                                disabled: disabledButton,
                              }}
                              size="default"
                              clickEvent={submitForm}
                            >
                              {"Guardar"}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{ type: "button" }}
                              clickEvent={() => {
                                setEdit(true);
                              }}
                              size="default"
                            >
                              {"Editar"}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="detailcontainer">
                      <h2>
                        {edit
                          ? "Editar Razón de solicitud de soporte"
                          : "Detalles de Razón de Solicitud de soporte"}
                      </h2>
                      <div className="input-container">
                        <FormField
                          type="text"
                          label="ID"
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                            disabled: edit,
                          }}
                          name="reason"
                          value={values.id}
                        />
                        <FormField
                          type="text"
                          label="Razón"
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="reason"
                          disabled={!edit}
                          value={values.reason}
                        />
                        {!edit ? (
                          <>
                            <FormField
                              type="text"
                              label="Fecha de creación"
                              name="createdAt"
                              value={values.createdAt}
                              disabled={!edit}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                            />
                            <Link
                              className="form-link"
                              to={
                                "/users/detail/" +
                                getTrainingRequestReason.data
                                  .trainingRequestReason.createdBy.id
                              }
                              style={{ textDecoration: "none" }}
                            >
                              <FormField
                                type="text"
                                label="Creado por el Usuario"
                                name="createdBy"
                                value={values.createdBy}
                                disabled={!edit}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Link>
                            <FormField
                              type="text"
                              label="Estatus"
                              name="status"
                              disabled={!edit}
                              //@ts-ignore
                              value={
                                values.status === "ACTIVE"
                                  ? "Activa"
                                  : "Inactiva"
                              }
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                                disabled: edit,
                              }}
                            />
                          </>
                        ) : null}
                      </div>
                      <div className="buttonspan"></div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default TrainingRequestReasonDetail;
