import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createProductLine as mutation } from "../../../Util/Queries/Mutations";
import { getProductLines } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface ProductLineFormValues {
  name: string;
  image: string;
  file: string;
}

export interface ProductLineFormValidation {
  name: TFieldValidation;
  image: TFieldValidation;
  file: TFieldValidation;
}

const RegisterProductLine: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [createProductLine, { data, loading, error }] = useMutation<any>(
    mutation,
    {
      update: (cache, { data: { createProductLine } }) => {
        const { productLines }: any = cache.readQuery({
          query: getProductLines,
        });
        const newProductLines = [...productLines, createProductLine];
        cache.writeQuery({
          query: getProductLines,
          data: { productLines: newProductLines },
        });
      },
    }
  );
  const [mainpage, setmainpage] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [checked, setChecked] = useState<any>([]);
  const [all, setAll] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [quoteTemplate, setQuoteTemplate] = useState<any>();
  const specs = [
    { label: "Fluído", value: "needs_fluid" },
    { label: "pH", value: "needs_pH" },
    { label: "Temperatura", value: "needs_temperature" },
    { label: "Presión", value: "needs_pressure" },
    { label: "RPM", value: "needs_RPM" },
    { label: "Diámetro de Eje/Brida/Tubería", value: "needs_axisDiameter" },
    { label: "Norma Dimensional", value: "needs_Norm" },
    { label: "Tipo de Válvula", value: "needs_valveType" },
    { label: "Marca Bomba", value: "needs_bombBrand" },
    { label: "Presentación Textil", value: "needs_textileType" },
  ];
  const [description, setDescription] = useState<string>();
  const defaultFormValues: ProductLineFormValues = {
    name: "",
    image: "",
    file: "",
  };

  const formValidation: ProductLineFormValidation = {
    name: "not-empty",
    image: "not-empty",
    file: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    ProductLineFormValues,
    ProductLineFormValidation
  >(defaultFormValues, formValidation);

  //Submit register to backend
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado la Línea de producto con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loading]);

  useEffect(() => {
    if (all === true) {
      let arr: any = [];
      specs.map((spec: any) => {
        return arr = [...arr, spec.value];
      });
      setChecked(arr);
    } else {
      setChecked([]);
    }
  }, [all]);
  //submit register data to backend
  const submitForm = () => {
    let ProductLinedata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectStyle: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (description === "") {
      emptyField = true;
    }
    if (errorsFound || emptyField || errorSelectStyle) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      try {
        ProductLinedata = {
          name: values.name,
          description: description,
        };
        checked.map((spec: any) => {
          return ProductLinedata[spec] = true;
        });
        createProductLine({
          variables: { data: ProductLinedata, image, quoteTemplate },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  return mainpage === false ? (
    <div className="content-container">
      <div className="mainContainer" style={{ marginTop: "2rem" }}>
        <div className="register-container">
          <h2>Registro de Línea de Producto</h2>
          <Form>
            <div className="input-container">
              <FormField
                type="text"
                label="Nombre"
                required={{
                  required: true,
                  requiredMsg:
                    "Por favor ingresa el nombre de la línea de producto.",
                }}
                name="name"
                value={values.name}
                inputElAttributes={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={pressedButton && errors.name}
              />
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={handleTextAreaChange}
                />
              </Form.Group>
              <FormField
                type="file"
                label="Imagen"
                required={{
                  required: true,
                  requiredMsg: "Por favor selecciona una imagen válida.",
                }}
                name="image"
                value={values.image}
                inputElAttributes={{
                  onChange: (ev) => {
                    if (ev.target.files) setImage(ev.target.files[0]);
                    handleChange(ev);
                  },
                  onBlur: handleBlur,
                  accept: "image/png,jpg",
                }}
                error={pressedButton && errors.image}
              />

              <FormField
                type="file"
                label="Plantilla de Cotización"
                required={{
                  required: true,
                  requiredMsg: "Por favor selecciona un pdf válido.",
                }}
                name="file"
                value={values.file}
                inputElAttributes={{
                  onChange: (ev) => {
                    if (ev.target.files) setQuoteTemplate(ev.target.files[0]);
                    handleChange(ev);
                  },
                  onBlur: handleBlur,
                  accept: "application/pdf",
                }}
                error={pressedButton && errors.file}
              />
              <Form.Group controlId="formCheckbox">
                <Form.Label>Especificaciones</Form.Label>
                <Button
                  type="primary"
                  elementAttr={{ type: "button" }}
                  size="tiny"
                  clickEvent={() => {
                    setAll(!all);
                  }}
                >
                  Seleccionar todo
                </Button>
                {specs.map((spec: any) => {
                  return (
                    <Form.Check
                      style={{ marginLeft: "1rem" }}
                      name={spec.value}
                      type="checkbox"
                      label={spec.label}
                      checked={
                        checked.filter((check: any) => check === spec.value)
                          .length > 0
                      }
                      onChange={(e: any) => {
                        let isIn = false;
                        if (e.target.checked) {
                          checked.map((check: any) => {
                            if (check === e.target.name) {
                              return isIn = true;
                            }
                            return null
                          });
                          if (!isIn) {
                            setChecked([...checked, e.target.name]);
                          }
                        } else {
                          checked.map((check: any) => {
                            if (check === e.target.name) {
                              return isIn = true;
                            }
                            return null
                          });
                          if (isIn) {
                            setChecked(
                              checked.filter(
                                (item: any) => item !== e.target.name
                              )
                            );
                          }
                        }
                      }}
                    />
                  );
                })}
              </Form.Group>
            </div>
            <div className="button-span">
              <Button
                type="primary"
                elementAttr={{ type: "button", disabled: disabledButton }}
                clickEvent={submitForm}
                size="default"
              >
                {!loading ? (
                  "Registrar"
                ) : (
                  <div
                    className="spinner-border spinner-border-sm text-primary"
                    role="status"
                  ></div>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/productlines" />
  );
};

export default RegisterProductLine;
