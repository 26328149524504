import { Avatar } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { IListElementProps } from './ListElement.props'
import './ListElement.scss'


export const ListElement: FunctionComponent<IListElementProps> = (props) => {
    const { selected, from, date, preview, pending, _new } = props

    return (
        <div {...props} className="chat-list-container">
            <div className={`chatList-content ${selected && 'selected selected-text'}`}>
                <div className="avatar-container">
                    <Avatar className="chatList-Avatar"></Avatar>
                </div>
                <div className="chatList-text">
                    <b className={`chatList-title ${selected && 'selected-text'}`}>{from}</b>
                    <span className="preview">{preview}</span>
                </div>
                <div className="rest-container">
                    <p className={`date-element ${selected && 'selected-text'}`}>{date}</p>
                    {
                        pending && <p className="pending-element"></p>
                    }
                    {
                        _new && <p className='read-element'></p>
                    }
                </div>
            </div>
        </div>
    )
}