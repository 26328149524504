import { createContext } from 'react';
import { user } from '../../App';
export interface contexttype {
    user: user,
    setUser: (user: user) => any,
    logged: boolean,
    setLogged:(logged:boolean)=>any
    DSO:boolean,
    setDSO:(role:boolean)=>any
}
export const { Provider , Consumer } = createContext<contexttype>({ user: { firstName:'',lastName:'',id:'',role:'ADMIN',email:'',phoneNumber:'' }, setUser: () => null, logged: false, setLogged:()=>null,DSO: false, setDSO:()=>null});