/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Redirect, useParams, Link } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import {Button as Bbutton} from 'react-bootstrap'
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editResponsible } from "../../../Util/Queries/Mutations";
import {
  getResponsibleById as query,
  getResponsibles,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import Table, { Row } from "../../Atoms/Table/Table";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
export interface ResponsibleDetailValidations {
  firstName: TFieldValidation;
  lastName: TFieldValidation;
  email: TFieldValidation;
  phoneNumber: TFieldValidation;
  plant?: TFieldValidation;
  client?: TFieldValidation;
  status?: TFieldValidation;
}
export interface ResponsibleDetailForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  plant: string;
  client: string;
  status: string;
}

const ResponsibleDetail: React.FC<any> = (props) => {
  //hooks
  const [success, setSuccess] = useState<boolean>(false);
  const [edit, setedit] = useState<boolean>(false);
  const [deletedUser, setDeletedUser] = useState<boolean>(false);
  const [editresponsible, { loading, data, error }] = useMutation(
    editResponsible
  );
  const [modal, setModal] = useState<boolean>(false);
  const [array, setArray] = useState<any>([]);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  //const
  const alert = useAlert();
  const rows: any = [];
  const { id } = useParams();
  //Apollohooks
  const getResponsible = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy:"network-only"
  });

  const updateCache = (responsible: any) => {
    const data = responsible.readQuery({
      query: getResponsibles,
    });
    const newData = {
      responsibles: data.responsibles.filter((t: any) => t.id !== id),
    };
    responsible.writeQuery({
      query: getResponsibles,
      data: newData,
    });
  };

  //formvalues

  const defaultFormValues: ResponsibleDetailForm = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    plant: "",
    client: "",
    status: "",
  };

  const formValidation: ResponsibleDetailValidations = {
    firstName: "not-empty",
    lastName: "not-empty",
    email: "email",
    phoneNumber: "number",
    plant: "not-empty",
    client: "not-empty",
    status: "not-empty",
  };

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getResponsible.loading) {
      if (getResponsible.data) {
        createtable();
        forceUpdateValue({
          firstName: getResponsible.data.responsible.firstName,
          lastName: getResponsible.data.responsible.lastName,
          email: getResponsible.data.responsible.email,
          plant: getResponsible.data.responsible.plant.name,
          client: getResponsible.data.responsible.plant.client.name,
          phoneNumber: getResponsible.data.responsible.phoneNumber
            ? getResponsible.data.responsible.phoneNumber
            : "",
          status:
            getResponsible.data.responsible.status === "ACTIVE"
              ? "Activo"
              : "Pendiente",
        });
      }
      if (getResponsible.error) {
        getResponsible.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getResponsible.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<ResponsibleDetailForm, ResponsibleDetailValidations>(
    defaultFormValues,
    formValidation
  );

  //submit data edited to backend
  const submitForm = () => {
    let responsibleData;
     let errorsFound: boolean = false;
    let emptyField: boolean = false;
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    if(values.firstName===""||values.lastName===""||values.email===""||values.phoneNumber===""){
      emptyField = true;
    }
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }

    } else {
      setDisabledButton(true)
    try {
      responsibleData = {
        firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      };
      editresponsible({
        variables: {id: +getResponsible.data.responsible.id, data: responsibleData},
      });
    } catch (error) {
      alert.error("Ha ocurrido un error");
      setDisabledButton(false)
    }
  }
  };
  //fill registered products registered by this responsible
  const createtable = () => {
    var arr: any = [];
    if (getResponsible.data.responsible.registeredProducts) {
      getResponsible.data.responsible.registeredProducts.map((product: any) => {
        arr = [...arr, product];
        const row: Row = {
          tds: [
            {
              valor: product.id,
            },
            {
              valor: product.product?product.product.code:"",
            },
            {
              valor: product.application?product.application.name:"",
            },
          ],
        };
        return rows.push(row);
      });
    }
    const a = {
      headers: ["ID", "PRODUCTO", "APLICACION"],
      rows: rows,
    };
    setArray(a);
    setLoad(false);
  };

  //handle table row click
  const handleRowClick = (product: Row) => {
    props.history.push(`/installedproducts/detail/${product.tds[0].valor}`);
  };

  //execute delete mutation
  const performDelete = () => {
    setDisabledButton(true)
    let deleteData = {
      status: "INACTIVE",
    };
    editresponsible({
      variables: {
        id: +getResponsible.data.responsible.id,
        data: deleteData,
      },
      update: updateCache,
    });
    setDeletedUser(true);
    setModal(false);
  };

  //UseEffect to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } 
          else if (error.message.code === 505) {
            return alert.error("No se puede eliminar el responsable ya que tiene relación con otros elementos, elimine estos elementos primero");
          }else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        let msg;
        if (deletedUser) {
          msg = "Se ha eliminado el responsable...";
        } else {
          msg = "Se ha modificado el responsable con exito...";
        }
         alert.success(msg, {
          onClose: () => {
            setSuccess(true);
          },
        });
      }
    }
  }, [loading]);

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : success ? (
          <Redirect to="/responsibles" />
        ) : !getResponsible.loading ? (
          getResponsible.data ? (
            <div className="content-container">
              <ConfirmationModal
                show={modal}
                onHide={() => setModal(false)}
                onCancel={() => setModal(false)}
                clickEvent={performDelete}
                message={
                  <div>
                    Está seguro que desea eliminar este responsable?
                    <br /> Esta acción no se puede revertir...
                  </div>
                }
                action="Eliminar Usuario"
                type="delete"
              />
              <div className="mainContainer">
                <div className="buttonspan">
                  <label></label>
                  {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                  <div className="buttonCustom">
                        <Button
                          type="danger"
                          elementAttr={{ type: "button",disabled:disabledButton }}
                          clickEvent={() => {
                            setModal(true);
                          }}
                          size="default"
                        >
                          {"Eliminar"}
                        </Button>
                    {edit ? (
                      <>

                        <Button
                          type="primary"
                          elementAttr={{ type: "button",disabled:disabledButton }}
                          clickEvent={submitForm}
                          size="default"
                        >
                          {"Guardar"}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="primary"
                          elementAttr={{ type: "button" }}
                          clickEvent={() => {
                            setedit(true);
                          }}
                          size="default"
                        >
                          {"Editar"}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className="detailcontainer">
                  <h2>
                    {edit ? "Editar Responsable" : "Detalles de Responsable"}
                  </h2>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      disabled={!edit}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      name="firstName"
                      value={values.firstName}
                    />
                    <FormField
                      type="text"
                      label="Apellido"
                      name="lastName"
                      value={values.lastName}
                      disabled={!edit}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                    />
                    <FormField
                      type="text"
                      label="Correo"
                      disabled={!edit}
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un correo válido.",
                      }}
                      name="email"
                      value={values.email}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                    />
                    <FormField
                      type="text"
                      label="Telefono"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      disabled={!edit}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                    />
                    {!edit ? (
                      <>
                        <Link
                          className="form-link"
                          to={
                            "/clients/detail/" +
                            getResponsible.data.responsible.plant.client.id
                          }
                          style={{textDecoration:"none"}}
                        >
                          <FormField
                            type="text"
                            label="Empresa"
                            disabled={!edit}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                            }}
                            name="client"
                            value={values.client}
                          />
                        </Link>
                        <Link
                          className="form-link"
                          to={
                            "/plants/detail/" +
                            getResponsible.data.responsible.plant.id
                          }
                          style={{textDecoration:"none"}}

                        >
                          <FormField
                            type="text"
                            label="Planta"
                            disabled={!edit}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                            }}
                            name="plant"
                            value={values.plant}
                          />
                        </Link>
                        <FormField
                          type="text"
                          label="Estatus"
                          name="status"
                          //@ts-ignore
                          value={values.status}
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                            disabled: edit,
                          }}
                        />
                        {!load ? (
                          <>
                            <h6>Productos Instalados</h6>
                            <Table
                              headers={array.headers}
                              rows={array.rows}
                              type="plants"
                              onrowclick={handleRowClick}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  <div className="buttonspan"></div>
                </div>
              </div>
            </div>
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default ResponsibleDetail;
