import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form,Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createProduct as mutation } from "../../../Util/Queries/Mutations";
import { getProducts, getProductStyles } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface ProductFormValues {
  code: string;
}

export interface ProductFormValidation {
  code: TFieldValidation;
}

const RegisterProduct: React.FC<{}> = (props) => {
  const alert = useAlert();
  const productStylesQuery = useQuery(getProductStyles, {
    fetchPolicy: "network-only",
  });
  const [description, setDescription] = useState<string>();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [productStyleList, setProductStyleList] = useState<any>([]);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [productStyleSelected, setProductStyleSelected] = useState<any>({
    value: "",
    label: "Selecciona un Estilo de producto...",
  });
  const [logOut, setLogOut] = useState<boolean>(false);
  const [createProduct, { data, loading, error }] = useMutation<any>(mutation, {
    update: (cache, { data: { createProduct } }) => {
      const { products }: any = cache.readQuery({
        query: getProducts,
      });
      const newProducts = [...products, createProduct];
      cache.writeQuery({
        query: getProducts,
        data: { products: newProducts },
      });
    },
  });
  const [mainpage, setmainpage] = useState<boolean>(false);

  const defaultFormValues: ProductFormValues = {
    code: "",
  };

  const formValidation: ProductFormValidation = {
    code: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    ProductFormValues,
    ProductFormValidation
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on register mutation
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado el producto con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data, error, loading]);

  //Fetch styles that will this new priduct belong to, this fills productStyleSelect component
  useEffect(() => {
    if (!productStylesQuery.loading) {
      if (productStylesQuery.data) {
        let arr: any = [];
        productStylesQuery.data.productStyles.items.map((productstyle: any) => {
          return arr = [...arr, { value: productstyle.id, label: productstyle.name }];
        });
        setProductStyleList(arr);
      }
      if (productStylesQuery.error) {
        productStylesQuery.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ productStylesQuery]);

  const handleSelectChange = (stylea: any) => {
    setProductStyleSelected(stylea);
  };
  //submit register data to backend
  const submitForm = () => {
    let Productdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectStyle: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (productStyleSelected.value === "") {
      errorSelectStyle = true;
    }
    if (!description) {
      emptyField = true;
    }

    if (errorsFound || emptyField || errorSelectStyle) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (errorSelectStyle) {
        alert.error(
          "Por favor seleccione el estilo al que pertenece el producto"
        );
      }
    } else {
      setDisabledButton(true);
      try {
        Productdata = {
          code: values.code,
          description: description,
          productStyleID: productStyleSelected.value,
        };

        createProduct({ variables: { data: Productdata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Producto</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Código"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa un código para el producto.",
                      }}
                      name="code"
                      value={values.code}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.code}
                    />
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={description}
                        onChange={handleTextAreaChange}
                      />
                    </Form.Group>
                    <div className="spacer">

                    {!productStylesQuery.loading ? (
                        <Select
                        value={productStyleSelected}
                        options={productStyleList}
                        onChange={handleSelectChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                      
                    </div>
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/Products" />
        )
      }
    </Consumer>
  );
};

export default RegisterProduct;
