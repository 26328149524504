import React, { FunctionComponent } from 'react'
import { useApolloClient } from 'react-apollo'
import { notificationSubscription } from '../../../Util/Queries/Queries'
import { sleep } from '../../../Util/utils'
import './Chat.scss'
import { ChatList, ContactInfo, Messages } from './Components'
import useChatContext from './Context/ChatContext'
import { useNotificationContext } from './Context/NotificationProvider'

export const ChatInterface: FunctionComponent = () => {

    const { contact } = useChatContext().currentChat
    const { startNotificationSubscription } = useNotificationContext()
    const client = useApolloClient()

    React.useEffect(() => {
        // sleep(100).then(() => {
        //     const observer = client.subscribe({
        //         query: notificationSubscription
        //     })

        //     observer.subscribe(({ data }) => console.log(data))

        //     console.log(client.link)
        // })

        startNotificationSubscription()
    }, [])

    return (
        <div className="Chat">
            <div className="table-display">
                <div className="title-span">
                    <h3>Mensajes</h3>
                </div>
                <div className="chatDisplay">
                    <div className="chatList">
                        <div className="chat-title list">
                            {/* <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label> */}
                            <p className="title" style={{ marginLeft: '5%' }}>
                                Lista de Chats
                            </p>
                        </div>
                        <ChatList />
                    </div>
                    <div className="chatMessages">
                        <div className="chat-title messages">
                            <p className="title rest">
                                {contact.name}
                            </p>
                        </div>
                        <Messages />
                    </div>
                    <div className="chatContactInfo">
                        <div className="chat-title contact">
                            <p className="title rest">
                                Información de contacto
                            </p>
                        </div>
                        <ContactInfo />
                    </div>
                </div>
            </div>
        </div>
    )
}