import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { recordsAmount } from "../../Util/Queries/Queries";
import { NavLink } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useAlert } from "react-alert";

const HomePage: React.FC<{}> = (props) => {
  //UseQuery hook to get data
  const getRecords = useQuery(recordsAmount,{fetchPolicy:"network-only"});
  //If data is already loaded
  const [load, setLoad] = useState<boolean>(false);
  //AlertProvider hook
  const alerta = useAlert();

  //UseEffect to retreive records data
  useEffect(() => {
    if (!getRecords.loading) {
      if (getRecords.data) {
        setLoad(true);
      }
      if (getRecords.error) {
        alerta.error(
          "No se pueden cargar los datos en este momento..."
        );
      }
    }
  }, [alerta, getRecords]);

  return (
    <div className="mainPage">
      {load === true ? (
        <>
          <div className="cardWrapper">
          <NavLink to="/users">
            <Card>
              <Card.Body>
                <div className="icons a"></div>
                <label>Usuarios Registrados</label>
                <h2>{getRecords.data.recordsAmount.users}</h2>
              </Card.Body>
            </Card>
            </NavLink>
              <NavLink to="/products">
            <Card>
                <Card.Body>
                  <div className="icons b"></div>
                  <label>Productos Registrados</label>
                  <h2>{getRecords.data.recordsAmount.products}</h2>
                </Card.Body>
            </Card>
              </NavLink>
              <NavLink to="/clients">
            <Card>
              <Card.Body>
                <div className="icons c"></div>
                <label>Empresas Registradas</label>
                <h2>{getRecords.data.recordsAmount.clients}</h2>
              </Card.Body>
            </Card>
            </NavLink>
          </div>

          <div className="cardWrapper">
          <NavLink to="/plants">
            <Card>
              <Card.Body>
                <div className="icons d"></div>
                <label>Plantas Registradas</label>
                <h2>{getRecords.data.recordsAmount.plants}</h2>
              </Card.Body>
            </Card>
            </NavLink>
            <NavLink to="/installedproducts">
            <Card>
              <Card.Body>
                <div className="icons e"></div>
                <label>Productos Instalados</label>
                <h2>{getRecords.data.recordsAmount.installedProd}</h2>
              </Card.Body>
            </Card>
            </NavLink>
            <NavLink to="/trainingrequests">
            <Card>
              <Card.Body>
                <div className="icons f"></div>
                <label>Solicitudes de Soporte</label>
                <h2>{getRecords.data.recordsAmount.support}</h2>
              </Card.Body>
            </Card>
            </NavLink>
          </div>
        </>
      ) : (
        <div className="load">
          <div className="dot"></div>
          <div className="outline">
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
