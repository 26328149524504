import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Redirect } from "react-router-dom";
import { Consumer } from "../../Util/Context/Context";
import Footer from "../Atoms/Footer/Footer";
import SideDrawer from "../Atoms/SideDrawer/SideDrawer";
import Toolbar from "../Atoms/Toolbar/Toolbar";
import "./styles.scss";

//component type to select which kind of data we're rendering
export type ComponentType =
  | "users"
  | "clients"
  | "plants"
  | "portfolios"
  | "productLine"
  | "productstyle"
  | "product"
  | "application"
  | "distributor"
  | "trainingrequest"
  | "resources"
  | "industry"
  | "successCase"
  | null;

const query = gql`
  query {
    user {
      id
      role
      firstName
      lastName
    }
  }
`;

const Dashboard: React.FC<{}> = (props) => {
  const alerta = useAlert();

  //hook to get user data to send it to toolbar
  const getUser = useQuery(query, { fetchPolicy: "network-only" });
  // hook to handle open/close side drawer with toggler button
  // even it's not implemented it can do it, in case it needs to be implemented in future
  const [sideDrawerOpen, setsideDrawerOPen] = useState<boolean>(false);
  const drawerToggleClick = () => {
    setsideDrawerOPen(!sideDrawerOpen);
  };
  const backDropClick = () => {
    setsideDrawerOPen(false);
  };
  useEffect(() => {
    if (!getUser.loading) {
      if (getUser.error) {
        getUser.error.graphQLErrors.map((error) => {
          const newError: any = error.message;
          if (newError.statusCode === 401) {
            sessionStorage.removeItem("token");
            return alerta.error("Su sesion ha caducado");
          }
          return null
        });
      }
    }
  }, [alerta, getUser.error, getUser.loading]);

  let backdrop: any;

  return (
    <Consumer>
      {(context) =>
        sessionStorage.getItem("token") ? (
          !getUser.loading ? (
            getUser.error ? (
              context.setLogged(false)
            ) : getUser.data ? (
              (context.setUser(getUser.data.user),
              context.setLogged(true),
              (
                <div className="App" style={{ height: "100%" }}>
                  <Toolbar
                    drawerclickhandler={drawerToggleClick}
                    name={
                      getUser.data.user.firstName +
                      " " +
                      getUser.data.user.lastName
                    }
                  />
                  <SideDrawer show={sideDrawerOpen} click={backDropClick} />
                  {backdrop}
                  <main>{props.children}</main>
                  <Footer />
                </div>
              ))
            ) : null
          ) : null
        ) : (
          <Redirect to="/login" />
        )
      }
    </Consumer>
  );
};

export default Dashboard;
