import { useLazyQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState, useCallback } from "react";
import { useAlert } from "react-alert";
import { FormControl, InputGroup, Pagination, Spinner } from "react-bootstrap";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getRegisterCodes } from "../../Util/Queries/Queries";
import Table, { Row } from "../Atoms/Table/Table";
import "./styles.scss";

const RegisterCodes: React.FC<{ history: History }> = (props) => {
  const [load, setLoad] = useState<boolean>(true);
  const [array, setArray] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [active, setActive] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  //Getquery hook
  const [exec, { loading, error, data }] = useLazyQuery(getRegisterCodes, {
    fetchPolicy: "network-only",
  });
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);
  const [paginationItems, setpaginationItems] = useState<any>([]);
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const rows: any = [];
  const orderByOptions =  { "field":"createdAt", "ascending":false, "nullFirst":true};


    //pagination and search functions

    const handleClick = useCallback((page: any) => {
      setActive(page);
      exec({
        variables: { 
          filter: { pagination: { currentPage: page, limit: 10 } },
          orderBy: orderByOptions,
        },
      });
    },[exec]);

  //load table content
  const createtable = useCallback(
    () => {
      var arr: any = [];
      data.registerCodes.items.map((registerCodes: any) => {
        arr = [...arr, registerCodes];
        const row: Row = {
          tds: [
            {
              valor: registerCodes.id,
            },
            {
              valor: registerCodes.user?registerCodes.user.firstName + " " + registerCodes.user.lastName:"",
            },
            {
              valor: registerCodes.code,
            },
            {
              valor: registerCodes.createdAt?registerCodes.createdAt.split('T')[0]:"",
            },
            {
              valor: registerCodes.usedAt?registerCodes.usedAt.split('T')[0]:"",
            },
          ],
        };
        return rows.push(row);
      });
      const a = {
        headers: ["ID", "NOMBRE", "CÓDIGO", "CREADO EN", "USADO EN"],
        rows: rows,
      };
  
      setArray(a);
      setLoad(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  //funciton that fills pagination component
  const fillPagination = useCallback(
    () => {
      let items: any = [];
      setTotalPages(data.registerCodes.pagination.totalPages);
      for (let number = active - 4; number < active; number++) {
        if (number >= 1) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === active}
              onClick={() => handleClick(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }
      for (let number = active; number <= active + 4; number++) {
        if (number <= data.registerCodes.pagination.totalPages) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === active}
              onClick={() => handleClick(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }

      setpaginationItems(items);
      setPaginationLoad(true);
    },
    [active, data, handleClick]
  )

  const performSearch = (e: any) => {
    exec({
      variables: {
        filter: {
          search: {
            value: e,
            fields: ["register_code__user.firstName", "register_code__user.lastName"],
          },
          pagination: {
            currentPage: 1,
            limit: 10,
          },
        },
      },
    });
  };

  //main Useeffect that retreives data from backend, and handles errors, if it success fet ching data it fills pagination, and table components
  useEffect(() => {
    if (!loading) {
      if (data) {
        setShowLoader(false);
        fillPagination();
        createtable();
      }
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          } else {
            return errores.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [createtable, data, error, errores, fillPagination, loading]);

  //useEffect that executes the lazyquery on component mount
  useEffect(() => {
    exec({
      variables: { 
        filter: { pagination: { currentPage: 1, limit: 10 } },
        orderBy: orderByOptions,
      },
    });
  }, [exec]);

  
  const debounce = (func: any, wait: number, e: any) => {
    let timeout: any;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(e);
    }, wait);
  };

  const handleSearchChange = (e: any) => {
    console.log(e.target.value);
    debounce(performSearch, 400, e.target.value);
  };

   

  //funtion to redirect to detail page
  /*const renderdetails = (client: Row) => {
    props.history.push(`/clients/detail/${client.tds[0].valor}`);
  };*/

  return (
    <Consumer>
      {(context) => (
        <div className="clients">
          {mainPage === true ? (
            context.setLogged(false)
          ) : showLoader ? (
            <div className="load">
              <div className="dot"></div>
              <div className="outline">
                <span></span>
              </div>
            </div>
          ) : load === false ? (
            //If data is already loaded
            <div className="table-display">
              <div className="title-span">
                <h3>Códigos de registro</h3>
              </div>
              <div className="btnspan">
                <InputGroup>
                  <FormControl
                    placeholder="Buscar..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="search-bar"
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                  />
                </InputGroup>
              </div>
              <div className="center-table">
                {loading ? (
                  <div className="spinnerSpan">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Table
                    headers={array.headers}
                    rows={array.rows}
                    type="clients"
                    onrowclick={() =>{}}
                  />
                )}
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                      <Pagination.First onClick={() => handleClick(1)} />
                      <Pagination.Prev
                        key={active - 1}
                        onClick={() => handleClick(active - 1)}
                      />
                    </>
                  ) : null}
                  {paginationItems}
                  {active < totalPages ? (
                    <>
                      <Pagination.Next
                        key={active + 1}
                        onClick={() => handleClick(active + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handleClick(totalPages)}
                      />
                    </>
                  ) : null}
                </Pagination>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </Consumer>
  );
};

export default RegisterCodes;
