/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editClient as mutation } from "../../../Util/Queries/Mutations";
import {
  getClientById as query,
  getClients
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import Table, { Row } from "../../Atoms/Table/Table";

export interface ClientDetailValidations {
  name: TFieldValidation;
  address: TFieldValidation;
  contactNumber: TFieldValidation;
  status: TFieldValidation;
}
export interface ClientDetailForm {
  name: string;
  address: string;
  contactNumber: string;
  status: string;
}

const ClientDetail: React.FC<{ history: History }> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [array, setArray] = useState<any>([]);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [deleteSuccess, setDelete] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false);
  const [editClient, { data, loading, error }] = useMutation<any>(mutation);
  //const
  const alert = useAlert();
  const rows: any = [];
  const { id } = useParams();
  //Apollohooks
  const getClient = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy:"network-only"
  });
  //formvalues

  const defaultFormValues: ClientDetailForm = {
    name: "",
    address: "",
    contactNumber: "",
    status: "",
  };

  const formValidation: ClientDetailValidations = {
    name: "not-empty",
    address: "not-empty",
    contactNumber: "number",
    status: "not-empty",
  };

  const updateCache = (client: any) => {
    const data = client.readQuery({
      query: getClients,
    });
    const newData = {
      clients: data.clients.filter((t: any) => t.id !== id),
    };
    client.writeQuery({
      query: getClients,
      data: newData,
    });
  };

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getClient.loading) {
      if (getClient.data) {
        createtable();
        forceUpdateValue({
          name: getClient.data.client.name,
          address: getClient.data.client.address,
          contactNumber: getClient.data.client.contactNumber
            ? getClient.data.client.contactNumber
            : "",
          status: getClient.data.client.status==="ACTIVE"?"Activo":"Inactivo"
        });
      }
      if (getClient.error) {
        getClient.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } 
          else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getClient.loading]);

  // Fill plants info 
  const createtable = () => {
    var arr: any = [];
    getClient.data.client.plants.map((plant: any) => {
      arr = [...arr, plant];
      const row: Row = {
        tds: [
          {
            valor: plant.id,
          },
          {
            valor: plant.name,
          },
          {
            valor: plant.address,
          },
          {
            valor: plant.contactNumber,
          },
        ],
      };
      return rows.push(row);
    });
    const a = {
      headers: ["ID", "NOMBRE", "DIRECCION", "TELEFONO"],
      rows: rows,
    };
    setLoad(true);
    setArray(a);
  };

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<ClientDetailForm, ClientDetailValidations>(
    defaultFormValues,
    formValidation
  );

  //UseEffects to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (data) {
        let msg = "";
        if (deleteSuccess) {
          msg = "Se ha eliminado la empresa cliente con exito...";
        } else {
          msg = "Se han guardado los cambios";
        }
        alert.success(msg, {
          onClose: () => {
            props.history.push(`/clients`);
          },
        });
      }
      if (error) {
        setDelete(false)
        setDisabledButton(false)
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          }
          else if (error.message.code === 505) {
            return alert.error("No se puede eliminar el cliente ya que tiene relación con otros elementos, elimine estos elementos primero");
          }
          else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);

  const saveChanges = () => {
    let Clientdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true)
      try {
        Clientdata = {
          name: values.name,
          address: values.address,
          contactNumber: values.contactNumber,
        };
        editClient({ variables: {id:id?+id:0, data: Clientdata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false)
      }
    }
  };

  const performDelete = () => {
    let dataModified = { status: "INACTIVE" };
    setDisabledButton(true)
    editClient({
      variables: {
        id: id ? +id : 0,
        data: dataModified,
      },
      update: updateCache,
    });
    setDelete(true);
    setModal(false);
  };
  const handleRowClick = (plant: Row) => {
    props.history.push(`/plants/detail/${plant.tds[0].valor}`);
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getClient.loading ? (
          getClient.data ? (
            load ? (
              <div
                  className="content-container"
                >
                <ConfirmationModal
                  show={modal}
                  onHide={() => setModal(false)}
                  onCancel={() => setModal(false)}
                  clickEvent={performDelete}
                  message={
                    <div>
                      Está seguro que desea eliminar esta empresa?
                      <br /> Esta acción no se puede revertir...
                    </div>
                  }
                  action="Eliminar Empresa Cliente"
                  type="delete"
                />
                <div className="mainContainer">
                  <div className="buttonspan">
                  <label></label>
                  {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                    <div className="buttonCustom">
                          <Button
                            type="danger"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            clickEvent={() => {
                              setModal(true);
                            }}
                            size="default"
                          >
                            {"Eliminar"}
                          </Button>
                      {edit ? (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            size="default"
                            clickEvent={saveChanges}
                          >
                            {"Guardar"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button" }}
                            clickEvent={() => {
                              setedit(true);
                            }}
                            size="default"
                          >
                            {"Editar"}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="detailcontainer">
                    <h2>{edit ? "Editar Empresa" : "Detalles de Empresa"}</h2>
                    <div className="input-container">
                      <FormField
                        type="text"
                        label="Nombre"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="name"
                        value={values.name}
                      />
                      <FormField
                        type="text"
                        label="Dirección"
                        name="address"
                        disabled={!edit}
                        value={values.address}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                      <FormField
                        type="text"
                        label="Telefono"
                        name="contactNumber"
                        disabled={!edit}
                        value={values.contactNumber}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                      <FormField
                        type="text"
                        label="Estatus"
                        name="status"
                        //@ts-ignore
                        value={values.status}
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          disabled: edit,
                        }}
                      />
                      {!edit ? (
                        <>
                          <h6>Plantas</h6>
                          <Table
                            headers={array.headers}
                            rows={array.rows}
                            type="plants"
                            onrowclick={handleRowClick}
                          />
                        </>
                      ) : null}
                    </div>
                    <div className="buttonspan"></div>
                  </div>
                </div>
              </div>
            ) : null
          ) : null
        ) : <div className="load">
        <div className="dot"></div>
        <div className="outline">
          <span></span>
        </div>
      </div>
      }
    </Consumer>
  );
};

export default ClientDetail;
