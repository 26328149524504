/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import useForm, { TFieldValidation } from "../../Util/Hooks/useForm";
import { passwordChange as mutation } from "../../Util/Queries/Mutations";
import Button from "../Atoms/Button";
import FormField from "../Atoms/FormField";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { validatePasswordRecoveryCode } from "../../Util/Queries/Queries";

export interface RecoveryFormValues {
  password: string;
  validatePassword: string;
}

export interface RecoveryFormValidation {
  password: TFieldValidation;
  validatePassword: TFieldValidation;
}

const PasswordRecovery: React.FC<{ history: History }> = (props) => {
  const alert = useAlert();
  const [validCode, setValidCode] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  //Gets the token attached to url 
  const { recoveryCode } = useParams();
  const [passwordChange, { data, loading, error }] = useMutation<any>(mutation);
  //Checks if the token is still active and it belongs to a user
  const checkCode = useQuery(validatePasswordRecoveryCode, {
    variables: { data: { passwordRecoveryToken: recoveryCode } },
    fetchPolicy:"network-only"
  });
  const defaultFormValues: RecoveryFormValues = {
    password: "",
    validatePassword: "",
  };

  const formValidation: RecoveryFormValidation = {
    password: "not-empty",
    validatePassword: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    RecoveryFormValues,
    RecoveryFormValidation
  >(defaultFormValues, formValidation);

  //useEffect that handles error/success fetching data
  useEffect(() => {
    if (checkCode.data) {
      if (checkCode.data.validatePasswordRecoveryToken.code === 232) {
        setValidCode(true);
      } else if (checkCode.data.validatePasswordRecoveryToken.code === 476) {
        setValidCode(false);
      }
    }
  }, [checkCode.loading]);

  //useEffect that handles success/error on submiting data to backend
  useEffect(() => {
    if (!loading) {
      if (error) {
        alert.error("Ha ocurrido un error...");
      }
      if (data) {
         alert.success(
          "Tu contraseña se ha cambiado exitosamente...",
          {
            onClose: () => {
              props.history.push(`/`);
            },
          }
        );
      }
    }
  }, [loading]);

  //function to check if both password fields match
  const checkPasswords = () => {
    if (values.password !== values.validatePassword) {
       alert.error("Las contraseñas no coinciden...");
    }
    else if(values.password.length<=5 || values.validatePassword.length<=5){
       alert.error("La contraseña debe contener al menos 6 caracteres");
    }
    else {
      submitForm();
    }
  };

  //Function to execute mutation function
  const submitForm = () => {
    let Recoverydata;
    setpressedButton(true)
    try {
      Recoverydata = {
        password: values.password,
        passwordRecoveryToken: recoveryCode,
      };

      passwordChange({ variables: { data: Recoverydata } });
    } catch (error) {
       alert.error(error.message);
    }
  };

  return (
    <div className="login">
      {!checkCode.loading ? (
        //If the recovery code is valid renders a form to choose new password
        validCode ? (
          <Form>
            <div className="logo-login"></div>
            <div className="login-container">
              <div className="mainLoginContainer">
                <div className="welcome">
                  <h2>Restaurar Contraseña</h2>
                  <h6>Ingresa tu nueva contraseña</h6>
                </div>
                <FormField
                  type="password"
                  label="Contraseña Nueva"
                  required={{
                    required: true,
                    requiredMsg: "Por favor ingresa una contraseña.",
                  }}
                  name="password"
                  value={values.password}
                  show={()=>{setShowPassword(!showPassword)}}
                  inputElAttributes={{
                    onChange: handleChange,
                    onBlur: handleBlur,
                    type: showPassword===false?"password":"",
                  }}
                  error={pressedButton && errors.password}
                />
                <FormField
                  type="text"
                  label="Confirma tu contraseña"
                  required={{
                    required: true,
                    requiredMsg: "Por favor ingresa una contraseña.",
                  }}
                  name="validatePassword"
                  value={values.validatePassword}
                  inputElAttributes={{
                    onChange: handleChange,
                    onBlur: handleBlur,
                    type: "password",
                  }}
                  error={pressedButton && errors.validatePassword}
                />
                <div className="buttonspan">
                  <Button
                    type="primary"
                    clickEvent={checkPasswords}
                    elementAttr={{
                      type: "button",
                    }}
                    size="large"
                  >
                    {"Aceptar"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <div className="recovery-container">
            <div className="logo-login"></div>
            <div className="login-container">
              <div className="mainLoginContainer">
                {/* In case recovery token is not valid it shows a screen notifying the user to request password change again/ password already changed */}
                <img src="https://upload.wikimedia.org/wikipedia/commons/2/28/White_X_in_red_background.svg" alt="code_invalid"></img>
                <h2>El código de recuperación no es válido</h2>
                <p>
                  Por favor vuelve a solicitar la restauración de tu contraseña
                </p>
                <div className="button-span"></div>
                <Button
                    type="primary"
                    clickEvent={()=>{props.history.push(`/login`)}}
                    elementAttr={{
                      type: "button",
                    }}
                    size="default"
                  >
                    {"Ir al Login"}
                  </Button>
              </div>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};

export default PasswordRecovery;
