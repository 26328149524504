/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, Modal } from "react-bootstrap";
import useForm, { TFieldValidation } from "../../Util/Hooks/useForm";
import { passwordRecovery as mutation } from "../../Util/Queries/Mutations";
import Button from "../Atoms/Button";
import FormField from "../Atoms/FormField";
import "./styles.scss";
import success from './success.png';
export interface RecoveryFormValues {
  email: string;
}

export interface RecoveryFormValidation {
  email: TFieldValidation;
}

const PasswordForgot: React.FC<{ history: History }> = (props) => {
  const alert = useAlert();
  const [modal, setmodal] = useState(false)
  const [passwordRecovery, { data, loading, error }] = useMutation<any>(mutation);

  const defaultFormValues: RecoveryFormValues = {
    email: "",
  };

  const formValidation: RecoveryFormValidation = {
    email: "email",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    RecoveryFormValues,
    RecoveryFormValidation
  >(defaultFormValues, formValidation);

  const [pressedButton, setpressedButton] = useState<boolean>(false)
  //UseEffect that handless confirmation modal to close and redirect to login on 5000 ms passed
useEffect(() => {
  if(modal){
    setTimeout(() => {
      setmodal(false);
      props.history.push(`/login`);
    }, 5000);
  }
}, [modal])

//UseEffect to handle error/success submiting data
  useEffect(() => {
    if (!loading) {
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if(error.message.code===462){
             return alert.error("El correo pertenece a un usuario que no se encuentra activo");
        }
        else if(error.message.code===459){
             return alert.error("No se encuentra ningun usuario con ese correo");
        }
        return alert.error("Ha ocurrido un error")
          }
            );
      }
      if (data) {
        setmodal(true)
      }
    }
  }, [loading]);

//funtion to submit data and execute mutation function 
  const submitForm = () => {
    let Recoverydata;
    setpressedButton(true)
    try {
      Recoverydata = {
        email: values.email,
      };

      passwordRecovery({ variables: { data: Recoverydata } });
    } catch (error) {
       alert.error(error.message);
    }
  };
  return (
    <div className="login">
      <Modal show={modal}  aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="successLottie">
        <img src={success} width="150px" height="150px" alt="success"/>
        <h2>
          Correo Electrónico enviado
        </h2>
        <p>Te hemos enviado instrucciones a tu correo electrónico, revisa tu bandeja de entrada</p>
        </div>
      </Modal.Body>
    </Modal>
    <Form>
    <div className="logo-login"></div>
      <div className="login-container">
        <div className="mainLoginContainer">
          <div className="welcome">
            <h2>Recupera tu Contraseña</h2>
            <p>Ingresa el correo electrónico asociado a tu cuenta y te enviaremos las instrucciones para cambiar tu contraseña.</p>
          </div>
          <FormField
            type="text"
            label="Correo"
            required={{
              required: true,
              requiredMsg: "Por favor ingresa un correo válido."
            }}
            name="email"
            value={values.email}
            inputElAttributes={{
              onChange: handleChange,
              onBlur: handleBlur
            }}
            error={pressedButton && errors.email}
          />
          <div className="buttonspan">
            <Button
              type="primary"
              clickEvent={submitForm}
              elementAttr={{
                type: "button"
              }}
              size="large"
            >
              {"Recuperar Contraseña"}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  </div>
  );
};

export default PasswordForgot;
