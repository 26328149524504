import ApolloClient from 'apollo-client'
import { Observable } from 'apollo-client/util/Observable'
import { FetchResult } from 'apollo-link/lib/types'
import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { notificationSubscription } from '../../../../Util/Queries/Queries'
import { ApolloError } from 'apollo-client/errors/ApolloError'
import { sleep } from '../../../../Util/utils'

export interface IChat {
    id: number
    createdAt: string
    closedAt: string
    openedAt: string
    email: string
    status: string
    first_name: string
    last_name: string
    company: string
    phone: string
}

export interface INotification {
    id: number,
    type: string,
    status: string,
    chat: IChat
}

interface INotificationContext {
    notifications: INotification[],
    updateNotifications: React.Dispatch<React.SetStateAction<INotification[]>>
    startNotificationSubscription: () => void

}

const NotificationContext = createContext<INotificationContext>({
    notifications: [],
    updateNotifications: () => undefined,
    startNotificationSubscription: () => undefined
})

/*class Subscription {

    public observer: Observable<FetchResult<any, Record<string, any>, Record<string, any>>>

    constructor(client: ApolloClient<object>) {
        this.observer = client.subscribe({
            query: notificationSubscription,
            variables: {}
        })
    }
}

var SubscriptionSingleton = (function () {
    var instance: Subscription;

    function createInstance(client: ApolloClient<object>) {
        var object = new Subscription(client);
        return object;
    }

    return {
        getInstance: function (client: ApolloClient<object>) {
            if (!instance) {
                instance = createInstance(client);
            }
            return instance;
        }
    };
})();*/


const Notificationprovider: FunctionComponent = ({ children }) => {

    const [notifications, updateNotifications] = useState<INotification[]>([])
    const client = useApolloClient()

    const startNotificationSubscription = () => {
        const observer = client.subscribe({
            query: notificationSubscription,
            variables: {}
        })

        observer.subscribe(({ data }) => {
            updateNotifications([data.adminNotifications])
        },(error) =>{
            // This is a very weird error. (I believe the error comes from the subscription package)
            // What happens is that, in the first try of the subscription, it returns you a 401 unauthorized error.
            // But, for some reason that I don't know, in the second try it works! 
            // What I did here is to check if there was a 401 error,
            // and then, after two seconds, send the subscription again.
            // If you send the second subscription without the sleep it doesn't work,
            // I've tried with 100, 500 and 1000 ms and it doesn't work, it seems to work fine with two seconds.
            if(error.graphQLErrors[0].message.statusCode === 401){
                sleep(2000).then(() => {
                    observer.subscribe(({ data }) => {
                        updateNotifications([data.adminNotifications])
                    });
                })
            }
        } )
    }


    return (
        <NotificationContext.Provider value={{ notifications, updateNotifications, startNotificationSubscription }}>
            {children}
        </NotificationContext.Provider>
    )
}

export const useNotificationContext = () => {
    const context = useContext(NotificationContext)

    if (!context) throw Error("Can't use this hook without the provider")

    return {
        ...context
    }
}

export default Notificationprovider