/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editProduct as mutation } from "../../../Util/Queries/Mutations";
import {
  getProductById as query,
  getProducts,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";

export interface ProductDetailValidations {
  code: TFieldValidation;
  style: TFieldValidation;
}
export interface ProductDetailForm {
  code: string;
  style: string;
}

const ProductDetail: React.FC<any> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [deleteItem, setdeleteItem] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  //const
  const alert = useAlert();
  const { id } = useParams();
  //Apollohooks
  const getProduct = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy: "network-only",
  });

  const [editProduct, { loading, data, error }] = useMutation(mutation);
  //formvalues

  const defaultFormValues: ProductDetailForm = {
    code: "",
    style: "",
  };

  const formValidation: ProductDetailValidations = {
    code: "not-empty",
    style: "not-empty",
  };

  const updateCache = (product: any) => {
    const data = product.readQuery({
      query: getProducts,
    });
    const newData = {
      products: data.products.filter((t: any) => t.id !== id),
    };
    product.writeQuery({
      query: getProducts,
      data: newData,
    });
  };


  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getProduct.loading) {
      if (getProduct.data) {
        setDescription(getProduct.data.product.description);
        setLoad(true);
        forceUpdateValue({
          code: getProduct.data.product.code,
          style: getProduct.data.product.productStyle.name,
        });
      }
      if (getProduct.error) {
        getProduct.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getProduct.loading]);

  //UseEffects to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode===401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code===505) {
            return alert.error(
              "No se puede eliminar el producto ya que tiene relación con otros elementos, elimine estos elementos primero"
            );
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        if (deleteItem) {
          alert.success("Se ha eliminado el producto...", {
            onClose: () => {
              props.history.push(`/products`);
            },
          });
        } else {
          alert.success("Se han modificado los datos...", {
            onClose: () => {
              props.history.push(`/products`);
            },
          });
        }
      }
    }
  }, [loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<ProductDetailForm, ProductDetailValidations>(
    defaultFormValues,
    formValidation
  );

  const submitForm = () => {
    let Productdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectStyle: boolean = false;

    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });

    if (!description) {
      emptyField = true;
    }

    if (errorsFound || emptyField || errorSelectStyle) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (errorSelectStyle) {
        alert.error(
          "Por favor seleccione el estilo al que pertenece el producto"
        );
      }
    } else {
      setDisabledButton(true);
      try {
        Productdata = {
          code: values.code,
          description: description,
          status: "ACTIVE",
        };

        editProduct({ variables: { id: id ? +id : 0, data: Productdata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  //Execute delete mutation function
  const performDelete = () => {
    let dataModified: any = {
      status: "INACTIVE",
    };
    setDisabledButton(true);
    editProduct({
      variables: {
        id: id ? +id : 0,
        data: dataModified,
      },
      update: updateCache,
    });
    setdeleteItem(true);
    setModal(false);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getProduct.loading ? (
          getProduct.data ? (
            load ? (
              <Form>
                <div className="content-container">
                  <ConfirmationModal
                    show={modal}
                    onCancel={() => setModal(false)}
                    onHide={() => setModal(false)}
                    clickEvent={performDelete}
                    message={
                      <div>
                        Está seguro que desea eliminar este producto?
                        <br /> Esta acción no se puede revertir...
                      </div>
                    }
                    action="Eliminar Producto"
                    type="delete"
                  />
                  <div className="mainContainer">
                    <div className="buttonspan">
                      <label></label>
                      {edit ? (
                        <Bbutton
                          size="sm"
                          variant="light"
                          className="backButton"
                          onClick={() => {
                            setedit(false);
                          }}
                        >
                          <div className="backIcon"></div>
                        </Bbutton>
                      ) : null}
                      <div className="buttonCustom">
                        <Button
                          type="danger"
                          elementAttr={{
                            type: "button",
                            disabled: disabledButton,
                          }}
                          clickEvent={() => {
                            setModal(true);
                          }}
                          size="default"
                        >
                          {"Eliminar"}
                        </Button>
                        {edit ? (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{
                                type: "button",
                                disabled: disabledButton,
                              }}
                              clickEvent={submitForm}
                              size="default"
                            >
                              {"Guardar"}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{ type: "button" }}
                              clickEvent={() => {
                                setedit(true);
                              }}
                              size="default"
                            >
                              {"Editar"}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="detailcontainer">
                      <h2>
                        {edit ? "Editar Producto" : "Detalles de Producto"}
                      </h2>
                      <div className="input-container">
                        <FormField
                          type="text"
                          label="Código"
                          required={{
                            required: true,
                            requiredMsg: "Por favor ingresa un código.",
                          }}
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="code"
                          value={values.code}
                        />
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label className="form-label">
                            Descripción
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={description}
                            onChange={handleTextAreaChange}
                            disabled={!edit}
                          />
                        </Form.Group>
                        {!edit ? (
                          load ? (
                            <>
                              <Link
                                className="form-link"
                                to={
                                  "/productstyles/detail/" +
                                  getProduct.data.product.productStyle.id
                                }
                                style={{ textDecoration: "none" }}
                              >
                                <FormField
                                  type="text"
                                  label="Estilo de Producto"
                                  name="style"
                                  //@ts-ignore
                                  value={values.style}
                                  disabled={!edit}
                                  inputElAttributes={{
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                  }}
                                />
                              </Link>
                              {getProduct.data.product.image ? (
                                <FormField
                                  type="image"
                                  label="Imagen"
                                  name="image"
                                  value="Imagen"
                                  link={getProduct.data.product.image}
                                />
                              ) : null}
                            </>
                          ) : null
                        ) : null}
                      </div>
                      <div className="buttonspan"></div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default ProductDetail;
