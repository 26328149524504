import React, { useState, useEffect } from "react";
import FormField from "../../Atoms/FormField";
import { Redirect } from "react-router-dom";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import "../styles.scss";
import Button from "../../Atoms/Button";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { createPortfolio as mutation } from "../../../Util/Queries/Mutations";
import { useAlert } from "react-alert";
import { Form, Spinner } from "react-bootstrap";
import { getUsers, getPortfolios } from "../../../Util/Queries/Queries";
import Select from "react-select";
import { Consumer } from "../../../Util/Context/Context";

export interface PortfolioFormValues {
  name: string;
}

export interface PortfolioFormValidation {
  name: TFieldValidation;
}

const RegisterPortfolio: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [mainpage, setmainpage] = useState<boolean>(false);
  const [usersOptions, setUsersOptions] = useState<any>([]);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [userOptionSelected, setUser] = useState({
    value: "",
    label: "Selecciona un usuario",
  });
  const [registerPortfolio, { data, loading, error }] = useMutation<any>(
    mutation,
    {
      update: (cache, { data: { createPortfolio } }) => {
        const { portfolios }: any = cache.readQuery({ query: getPortfolios });
        const newPortfolios = [...portfolios, createPortfolio];
        cache.writeQuery({
          query: getPortfolios,
          data: { portfolios: newPortfolios },
        });
      },
    }
  );
  const listUsers = useQuery(getUsers, { fetchPolicy: "network-only" });
  const defaultFormValues: PortfolioFormValues = {
    name: "",
  };

  const formValidation: PortfolioFormValidation = {
    name: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    PortfolioFormValues,
    PortfolioFormValidation
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on register mutation
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 462) {
            return alert.error(
              "El usuario seleccionado no ha completado su registro"
            );
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado el portafolio con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data, error, loading]);

  //Fetch users to assign this new portfolio, this fills the Usersselect component
  useEffect(() => {
    if (!listUsers.loading) {
      if (listUsers.data) {
        let arr: any = [];
        listUsers.data.users.items.map((usuario: any) => {
          if (usuario.role === "SALESMAN") {
            return arr = [
              ...arr,
              {
                value: usuario.id,
                label: usuario.firstName + " " + usuario.lastName,
              },
            ];
          }
          return null
        });
        setUsersOptions(arr);
      }
    }
  }, [listUsers.data, listUsers.loading]);

  const handleUserChange = (option: any) => {
    setUser(option);
  };
  //submit register data to backend
  const submitForm = async () => {
    let Portfoliodata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      setDisabledButton(true);
      try {
        userOptionSelected.value !== ""
          ? (Portfoliodata = {
              name: values.name,
              salesmanID: userOptionSelected.value,
            })
          : (Portfoliodata = {
              name: values.name,
            });
        await registerPortfolio({ variables: { data: Portfoliodata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Portafolio</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa el nombre de el portafolio.",
                      }}
                      name="name"
                      value={values.name}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.name}
                    />

                    <div className="spacer">
                      <h5>Asignar portafolio a un vendedor</h5>
                      
                      {!listUsers.loading ? (
                        <Select
                        options={usersOptions}
                        value={userOptionSelected}
                        onChange={handleUserChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/portfolios" />
        )
      }
    </Consumer>
  );
};

export default RegisterPortfolio;
