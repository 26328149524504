/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormControl, InputGroup, Pagination, Spinner } from "react-bootstrap";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import {downloadUsersReport, getUsers} from "../../Util/Queries/Queries";
import Button from "../Atoms/Button/index";
import Table, { Row } from "../Atoms/Table/Table";
import "./loader.scss";
import "./styles.scss";

const Users: React.FC<{ history: History }> = (props) => {
  const [load, setload] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [search, setsearch] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(0);
  const [array, setarray] = useState<any>([]);
  const [exec, { loading, error, data }] = useLazyQuery(getUsers, {
    fetchPolicy: "network-only",
  });
  const [downloadReportExec, downloadReportResponse] = useLazyQuery(downloadUsersReport, {
    fetchPolicy: "network-only",
  })
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);

  const [active, setActive] = useState<number>(1);
  const [paginationItems, setpaginationItems] = useState<any>([]);

  const handleClick = (page: any) => {
    setActive(page);
    exec({
      variables: { filter: { 
        pagination: { currentPage: page, limit: 10 },
        filter: {status: ["ACTIVE","PENDING"]}
      } },
    });
  };
  const rows: any = [];

  //funciton that fills pagination component

  const fillPagination = () => {
    let items: any = [];
    setTotalPages(data.users.pagination.totalPages);
    for (let number = active - 4; number < active; number++) {
      if (number >= 1) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    for (let number = active; number <= active + 4; number++) {
      if (number <= data.users.pagination.totalPages) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    setpaginationItems(items);
    setPaginationLoad(true);
  };
  //main Useeffect that retreives data from backend, and handles errors, if it success fet ching data it fills pagination, and table components

  useEffect(() => {
    if (!loading) {
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          } else {
            console.log(error)
            return errores.error("Ha ocurrido un error");
          }
        });
      } else {
        if (data) {
          setShowLoader(false);
          fillPagination();
          createtable();
        }
      }
    }
  }, [loading]);

  const firstState = () =>{
    exec({
      variables: { filter: { 
        pagination: { currentPage: 1, limit: 10 },
        filter: {
          status: ["ACTIVE","PENDING"],
        }
      } },
    })
  }

  useEffect(() => {
    firstState()
  }, []);

  // Searching listening search variable
  useEffect(() => {
    // console.log(search, "->", search.length)
    if(search.length > 0){
      debounce(performSearch, 400, search);
    }else{
      firstState()
    }
  }, [search]);

  useEffect(() => {
    if (!downloadReportResponse.loading) {
      if (downloadReportResponse.data) {
        window.open(downloadReportResponse.data.downloadUsersReport.link, '_blank');
      }

      if (downloadReportResponse.error) {
        downloadReportResponse.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);

            return errores.error('Su sesión ha caducado');
          } else {
            return errores.error('Ha ocurrido un error');
          }
        });
      }
    }
  }, [downloadReportResponse.loading]);

  //load table content
  const createtable = () => {
    var arr: any = [];
    //@ts-ignore
    // data.users.items.filter( d=> !d.email.includes("deleted")).map((user: any) => {
    data.users.items.map((user: any) => {
      arr = [...arr, user];
      const row: Row = {
        tds: [
          {
            valor: user.id,
          },
          {
            valor: user.firstName + " " + user.lastName,
          },
          {
            valor: user.email,
          },
          {
            valor: user.company,
          },
          {
            valor: user.phoneNumber,
          },
          {
            valor:
              user.role === "ADMIN"
                ? "Administrador"
                : user.role === "USER_CLIENT"
                ? "Cliente"
                : user.role === "DSO"
                ? "DSO"
                : user.role === "SALESMAN"
                ? "Vendedor"
                : user.role === "AGENT"
                ? "Agente"
                : ""
          },
          {
            valor: user.status === "ACTIVE" ? 'Activo' : 'Pendiente',
          },
        ],
      };
      return rows.push(row);
    });
    const a = {
      headers: ["ID", "NOMBRE", "EMAIL", "COMPAÑIA", "TELEFONO", "ROL", "ESTATUS"],
      rows: rows,
    };
    setarray(a);
    setload(false);
  };
  const renderdetails = (user: Row) => {
    props.history.push(`/users/detail/${user.tds[0].valor}`);
  };

  const performSearch = (e: any) => {
    exec({
      variables: {
        filter: {
          search: {
            value: e,
            fields: ["firstName", "lastName","phoneNumber","email"],
          },
          pagination: {
            currentPage: 1,
            limit: 10,
          },
        },
      },
    });
  };
  const debounce = (func: any, wait: number, e: any) => {
    let timeout: any;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(e);
    }, wait);
  };

  const handleSearchChange = (e: any) => {
    setsearch(e.target.value);
  };

  return (
    <Consumer>
      {(context) =>
        mainPage === true ? (
          context.setLogged(false)
        ) : showLoader ? (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        ) : load === false ? (
          //If data is already loaded
          <div className="Users">
            <div className="table-display">
              <div className="title-span">
                <h3>Usuarios Registrados</h3>
              </div>
              <div className="btnspan">
                <InputGroup>
                  <FormControl
                    placeholder="Buscar..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="search-bar"
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                    value={search}
                  />
                  {context.user.role === "DSO" ? null : (
                    <InputGroup.Append>
                      <Button
                        type="primary"
                        elementAttr={{ type: "button" }}
                        size="default"
                        clickEvent={() => {
                          props.history.push("/registeruser");
                        }}
                      >
                        {"Crear usuario"}
                      </Button>
                      <Button
                          type="primary"
                          elementAttr={{ type: "button" }}
                          size="default"
                          clickEvent={() => {
                            downloadReportExec();
                          }}>
                        {"Descargar reporte"}
                      </Button>
                    </InputGroup.Append>
                  )}
                </InputGroup>
              </div>
              <div className="center-table">
                {loading ? (
                  <div className="spinnerSpan">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Table
                    headers={array.headers}
                    rows={array.rows}
                    type="users"
                    onrowclick={renderdetails}
                  />
                )}
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                      <Pagination.First onClick={() => handleClick(1)} />
                      <Pagination.Prev
                        key={active - 1}
                        onClick={() => handleClick(active - 1)}
                      />
                    </>
                  ) : null}
                  {paginationItems}
                  {active < totalPages ? (
                    <>
                      <Pagination.Next
                        key={active + 1}
                        onClick={() => handleClick(active + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handleClick(totalPages)}
                      />
                    </>
                  ) : null}
                </Pagination>
              ) : null}
            </div>
          </div>
        ) : null
      }
    </Consumer>
  );
};

export default Users;
