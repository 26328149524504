import { FormatListBulletedTwoTone } from '@material-ui/icons'
import React, { FunctionComponent, createContext, useContext } from 'react'
import { Message } from 'react-chat-ui'

class MessageCache {
    messages: Message[]

    constructor() {
        this.messages = []
    }

    updateMessages = (local: boolean, message: string, senderName?: boolean) => {
        const msg = new Message({
            id: local ? 0 : 1,
            message: message
        })

        this.messages.push(msg)
    }

    clearMessages = () => {
        this.messages = []
    }
}

const SingletonMessageCache = (function () {
    let instance: MessageCache | undefined

    function createInstance() {
        let object = new MessageCache()

        return object
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }

            return instance
        }
    }
})

export interface IUser {
    id: number
    name: string
    mail: string
    phone: string
    company: string
    open: boolean
    status: string
}

interface IChatContext {
    messageCache: {
        messages: Message[],
        updateMessages: (local: boolean, message: string, senderName?: boolean) => void,
        clearMessages: () => void
    },
    currentChat: {
        contact: IUser,
        changeContact: React.Dispatch<React.SetStateAction<IUser>>
    }

}

const ChatContext = createContext<IChatContext>({
    messageCache: {
        messages: [],
        updateMessages: (local: boolean, message: string, senderName?: boolean) => undefined,
        clearMessages: () => undefined
    },
    currentChat: {
        contact: {
            id: 0,
            name: '',
            mail: '',
            phone: '',
            company: '',
            open: false,
            status: ''
        },
        changeContact: () => undefined
    }
})


export const ChatContextProvider: FunctionComponent = ({ children }) => {

    const [messageCache] = React.useState<MessageCache>(SingletonMessageCache().getInstance())
    const [currentContact, updateCurrentContact] = React.useState<IUser>({
        id: 0,
        name: '',
        mail: '',
        phone: '',
        company: '',
        open: false,
        status: ''
    })

    return (
        <ChatContext.Provider value={
            {
                messageCache: {
                    messages: messageCache.messages,
                    updateMessages: messageCache.updateMessages,
                    clearMessages: messageCache.clearMessages
                },
                currentChat: {
                    contact: currentContact,
                    changeContact: updateCurrentContact
                }
            }
        }>
            {children}
        </ChatContext.Provider>
    )
}

const useChatContext = () => {
    const context = useContext(ChatContext)

    if (!context) throw new Error('Please wrap the app with the ChatContextProvider component before calling the hook useChatContext')

    return context
}

export default useChatContext