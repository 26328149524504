import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createCase as mutation } from "../../../Util/Queries/Mutations";
import {
  getIndustries,
  getSuccessCases,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface SuccessCaseValues {
  name: string;
}

export interface SuccessCaseFormValidation {
  name: TFieldValidation;
}

const RegisterSuccessCase: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [mainpage, setmainpage] = useState<boolean>(false);  
  // const [description, setDescription] = useState<string>();
  // const [image, setImage] = useState<any>();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [industries, setIndustries] = useState<any>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [industrySelected, setIndustrySelected] = useState({
    value: "",
    label: "Selecciona una industria",
  });
  const [createCase, { data, loading, error }] = useMutation<any>(
    mutation, 
    {
      update: (cache, { data: { createCase } }) => {
        const { successCases }: any = cache.readQuery({ query: getSuccessCases });
        const newCases = [...successCases, createCase];
        cache.writeQuery({
          query: getSuccessCases,
          data: { successCases: newCases },
        });
      },
    }
  );
  const listIndustries = useQuery(getIndustries, { fetchPolicy: "network-only" });
  const defaultFormValues: SuccessCaseValues = {
    name: "", 
  };

  const formValidation: SuccessCaseFormValidation = {
    name: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    SuccessCaseValues,
    SuccessCaseFormValidation
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on register mutation
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado el caso con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  //Fetch registered Clients to fill select
  useEffect(() => {
    if (!listIndustries.loading) {
      if (listIndustries.data) {
        let arr: any = [];
        listIndustries.data.industries.items.map((industry: any) => {
          return arr = [...arr, { value: industry.id, label: industry.name }];
        });
        setIndustries(arr);
      }
    }
    if (listIndustries.error) {
      listIndustries.error.graphQLErrors.map((error: any) => {
        if (error.message.statusCode === 401) {
          setLogOut(true);
          return alert.error("Su sesion ha caducado");
        } else {
          return alert.error("Ha ocurrido un error");
        }
      });
    }
  }, [listIndustries]);

  // const handleTextAreaChange = (event: any) => {
  //   setDescription(event.target.value);
  // };

  const handleIndustryChange = (option: any) => {
    setIndustrySelected(option);
  };
  //submit register data to backend
  const submitForm = () => {
    let successCaseData;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectIndustry: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (industrySelected.value === "") {
      errorSelectIndustry = true;
    }
    if (errorsFound || emptyField || errorSelectIndustry) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (errorSelectIndustry) {
        alert.error(
          "Por favor seleccione la industria a la que pertenece el caso de éxito"
        );
      }
    } else {
      setDisabledButton(true);
      try {
        successCaseData = {
          name: values.name,
          description: "-",
          industryID: industrySelected.value,
        };
        createCase({ variables: { data: successCaseData } }).catch((error) => {
          console.log(error);
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Línea de Producto</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa el nombre de línea",
                      }}
                      name="name"
                      value={values.name}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.name}
                    />
                    {/* <Form.Group controlId="formcontrolID.textarea">
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={description}
                        onChange={handleTextAreaChange}
                      />
                    </Form.Group> */}

                    {/* <FormField
                      type="file"
                      label="Imagen"
                      required={{
                        required: true,
                        requiredMsg: "Por favor selecciona una imagen válida.",
                      }}
                      name="image"
                      value={values.image}
                      inputElAttributes={{
                        onChange: (ev) => {
                          if (ev.target.files) setImage(ev.target.files[0]);
                          handleChange(ev);
                        },
                        onBlur: handleBlur,
                        accept: "image/png,jpg",
                      }}
                      error={pressedButton && errors.image}
                    /> */}
                 
                    <div className="spacer">
                      <h5>Industria a la que pertenece esta línea</h5>
                      
                       {!listIndustries.loading ? (
                        <Select
                        options={industries}
                        value={industrySelected}
                        onChange={handleIndustryChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                  
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/successcases" />
        )
      }
    </Consumer>
  );
};

export default RegisterSuccessCase;
