/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormControl, InputGroup, Pagination, Spinner } from "react-bootstrap";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getSuccessCases } from "../../Util/Queries/Queries";
import Button from "../Atoms/Button";
import Table, { Row } from "../Atoms/Table/Table";
import "./styles.scss";

const SuccessCases: React.FC<{ history: History }> = (props) => {
  const [load, setLoad] = useState<boolean>(true);
  const [array, setArray] = useState<any>([]);
  const [active, setActive] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  let [exec, { loading, error, data }] = useLazyQuery(getSuccessCases, {
    fetchPolicy: "network-only",
  });
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);
  const [paginationItems, setpaginationItems] = useState<any>([]);
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const rows: any = [];

  useEffect(() => {
    if (!loading) {
      if (data) {
        setShowLoader(false);
        fillPagination();
        createtable();
      }
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          } else {
            return errores.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);


  //useEffect that executes the lazyquery on component mount
  useEffect(() => {
    exec({
      variables: { filter: { pagination: { currentPage: 1, limit: 10 } } },
    });
  }, []);
  

  const handleClick = (page: any) => {
    setActive(page);
    exec({
      variables: { filter: { pagination: { currentPage: page, limit: 10 } } },
    });
  };

  //funciton that fills pagination component
  const fillPagination = () => {
    let items: any = [];
    setTotalPages(data.successCases.pagination.totalPages);
    for (let number = active - 4; number < active; number++) {
      if (number >= 1) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    for (let number = active; number <= active + 4; number++) {
      if (number <= data.successCases.pagination.totalPages) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    setpaginationItems(items);
    setPaginationLoad(true);
  };


  const performSearch = (e: any) => {
    exec({
      variables: {
        filter: {
          search: {
            value: e,
            fields: ["name"],
          },
          pagination: {
            currentPage: 1,
            limit: 10,
          },
        },
      },
    });
  };


  const debounce = (func: any, wait: number, e: any) => {
    let timeout: any;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(e);
    }, wait);
  };

  const handleSearchChange = (e: any) => {
    debounce(performSearch, 400, e.target.value);
  };


  const createtable = () => {
    var arr: any = [];
    data.successCases.items.map((successCase: any) => {
      arr = [...arr, successCase];
      const row: Row = {
        tds: [
          {
            valor: successCase.id,
          },
          {
            valor: successCase.name,
          },
          {
            valor: successCase.industry.name,
          },
        ],
      };
      return rows.push(row);
    });
    const a = {
      headers: ["ID", "NOMBRE", "INDUSTRIA"],
      rows: rows,
    };
    setArray(a);
    setLoad(false);
  };

  const renderdetails = (successCase: Row) => {
    props.history.push(`/successcases/detail/${successCase.tds[0].valor}`);
  };


  return (
    <Consumer>
      {(context) => (
        <div className="SuccessCases">
          {mainPage === true ? (
            context.setLogged(false)
          ) : showLoader ? (
            <div className="load">
              <div className="dot"></div>
              <div className="outline">
                <span></span>
              </div>
            </div>
          ) : load === false ? (
            <div className="table-display">
              <div className="title-span">
                <h3>Lineas de Producto</h3>
              </div>

              <div className="btnspan">
              <InputGroup>
                  <FormControl
                    placeholder="Buscar..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="search-bar"
                    onChange={(e)=>{handleSearchChange(e)}}
                  />
                    <InputGroup.Append>
                      <Button
                        type="primary"
                        elementAttr={{ type: "button" }}
                        size="default"
                        clickEvent={()=>{
                          props.history.push('/registersuccesscase')}}
                      >
                        {"Crear Línea"}
                      </Button>
                  </InputGroup.Append>
              </InputGroup>
              </div>


              <div className="center-table">
                {loading ? (
                  <div className="spinnerSpan">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Table
                    headers={array.headers}
                    rows={array.rows}
                    type="successCase"
                    onrowclick={renderdetails}
                  />
                )}
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                      <Pagination.First onClick={() => handleClick(1)} />
                      <Pagination.Prev
                        key={ active - 1}
                        onClick={() => handleClick(active - 1)}
                      />
                    </>
                  ) : null}
                  {paginationItems}
                  {active < totalPages ? (
                    <>
                      <Pagination.Next
                        key={active + 1}
                        onClick={() => handleClick(active + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handleClick(totalPages)}
                      />
                    </>
                  ) : null}
                </Pagination>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </Consumer>
  );
};

export default SuccessCases;
