/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../Util/Hooks/useForm";
import { getUserToken as mutation } from "../../Util/Queries/Mutations";
import { getUserLogged } from "../../Util/Queries/Queries";
import Button from "../Atoms/Button";
import FormField from "../Atoms/FormField";
import "./styles.scss";
export interface LoginValues {
  email: string;
  password: string;
}
export interface LoginValidation {
  email: TFieldValidation;
  password: TFieldValidation;
}

const Login: React.FC<any> = (props) => {
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [executeQuery, getUser] = useLazyQuery(getUserLogged, {
    fetchPolicy: "network-only",
  });
  const userError = useAlert();
  const defaultFormValues: LoginValues = {
    email: "",
    password: "",
  };

  const formValidation: LoginValidation = {
    email: "email",
    password: "not-empty",
  };


  const {
    handleChange,
    values,
    handleBlur,
    errors,
  } = useForm<LoginValues, LoginValidation>(defaultFormValues, formValidation);
  //hookstate to handle redirect to Dashboard on success logIn
  const [red, setRedirect] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false)
  //UseMutation hook to retreive token 
  const [signIn, { error, loading, data }] = useMutation<any>(mutation);

//useEffect that handles fetch token of sent data
  useEffect(() => {
    if (!loading) {
      if (error) {
        error.graphQLErrors.map((error) => {
          const newError: any = error.message;
          if (newError.code === 454) {
            //If no matching user with that data...
            return userError.error(
              "Los datos ingresados no son validos"
            );
          }
          if (newError.code === 462) {
            return userError.error(
              //In case user is not completed it's register
              "El usuario no se encuentra activo"
            );
          }
          if (newError.code === 506) {
            return userError.error(
              //In case user tried to logIn several times
              "Demasiados intentos fallidos de inicio de sesion, intentelo de nuevo en una hora"
            );
          }
          else{
            return userError.error("Ha ocurrido un error")
          }
        });
      }
      if (data) {
        sessionStorage.setItem("token", data.signIn.token);
        checkUser();
      }
    }
  }, [loading]);

  //Fetch data of user's that belongs token saved in sessionStorage
  useEffect(() => {
    if (!getUser.loading) {
      if (getUser.data) {
        if (getUser.data.user.role !== "ADMIN" ) {
          if (getUser.data.user.role !== "DSO" ) {
            if (getUser.data.user.role !== "AGENT" ) {
            sessionStorage.removeItem("token");
            userError.error(
            //If the user exist but it's not an admin or a dso ...
            "El usuario ingresado no tiene accesos al panel..."
          );
          }
          else {
            setRedirect(true);
          }
        }
        else {
          setRedirect(true);
        }
      } else {
        setRedirect(true);
      }
      
      }
    }
  }, [getUser.loading]);

  const checkUser = () => {
    executeQuery();
  };
  //async submit function to get a token based on email and password, it has to be asyn so we can catch errors and not show a error response until the fetch is complete
  const submitForm = async () => {
    setpressedButton(true)
    sessionStorage.removeItem("token");
    try {
      //Validation provided by formfield useForm
      if (errors.email || errors.password) {
        return userError.error(
          "Por favor llene los campos en el formato indicado"
        );
      }
      else if(values.email===""||values.password===""){
        return userError.error(
          "No puede dejar campos vacios"
        );
      }
     else{
      const logdata = { email: values.email, password: values.password };
      await signIn({ variables: { data: logdata } });
     }
    } catch (e) {
      if(e.graphQLErrors.length===0){
        //Handling HTTP errors
        if(e.networkError.message==="Failed to fetch"){
          return userError.error("No se pudo establecer la conexión...");
        }
      }
    }
  };

  //OnKeyPress handler to submit form if you press 'Enter' key
  const handleKeyPress=(event:any)=>{
    if(event.key==="Enter"){
      submitForm()
    }
  }

  return (
    <Consumer>
      {(context) =>
        !loading ? (
          red === true ? (
            //If data is correct and user is and admin or a DSO, it changes the context so in App.tsx will be moved to main routing
            //also it saves the user in context to check it's role to restrict it's access to private content
            context.setLogged(true),
            context.setUser(getUser.data.user)
          ) : (
            <div className="login">
              <form>
                <div className="logo-login"></div>
                <div className="login-container">
                  <div className="mainLoginContainer">
                    <div className="welcome">
                      <h2>Bienvenido</h2>
                      <label>Para comenzar inicia sesión</label>
                    </div>
                    <FormField
                      type="text"
                      label="Correo"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un correo válido.",
                      }}
                      name="email"
                      value={values.email}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        onKeyPress:handleKeyPress
                      }}
                      error={pressedButton && errors.email}
                    />
                    <FormField
                      type="password"
                      label="Contraseña"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa una contraseña",
                      }}
                      name="password"
                      classVariant="password"
                      value={values.password}
                      show={()=>{setShowPassword(!showPassword)}}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        type: showPassword===false?"password":"",
                        onKeyPress:handleKeyPress
                      }}
                      error={pressedButton && errors.password && pressedButton}
                    />
                    <div className="buttonspan">
                      <Button
                        type="primary"
                        clickEvent={submitForm}
                        elementAttr={{
                          type: "button",
                        }}
                        size="large"
                      >
                        {!loading ? (
                        "Ingresar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="recoverpassword">
                  {/* Password Forgot Link */}
                  <Link to="/forgotpassword">
                    Olvidaste tu contraseña? Haz click aquí
                  </Link>
                </div>
              </form>
            </div>
          )
        ) : null
      }
    </Consumer>
  );
};

export default Login;
