/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/react-hooks";
import { History } from 'history';
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getTrainingRequestReasons } from "../../Util/Queries/Queries";
import Button from "../Atoms/Button";
import Table, { Row } from "../Atoms/Table/Table";
import "./styles.scss";
const TrainingRequestReasons: React.FC<{history:History}> = (props) => {
  const [load, setLoad] = useState<boolean>(true);
  const [array, setArray] = useState<any>([]);
  const { loading, error, data} = useQuery(getTrainingRequestReasons,{fetchPolicy:"network-only"});
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const rows: any = [];


  //main Useeffect that retreives data from backend, and handles errors, if it success fet ching data it fills table component
  useEffect(() => {
    if (!loading) {
      if (data) {
        setShowLoader(false);
        createtable();
      }
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          }
          else{
            return errores.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);

  //load table content
  const createtable = () => {
    var arr: any = [];
    data.trainingRequestReasons.map((trainingRequestReason: any) => {
      arr = [...arr, trainingRequestReason];
      const row: Row = {
        tds: [
          {
            valor: trainingRequestReason.id
          },
          {
            valor: trainingRequestReason.reason
          },
          {
            valor: trainingRequestReason.createdAt
          }
        ]
      };
      return rows.push(row);
    });
    const a = {
        headers: ["ID", "RAZÓN", "FECHA DE CREACIÓN"],
        rows: rows
    };

    setArray(a);
    setLoad(false);
  };
  const renderdetails = (trainingRequestReason: Row) => {
    props.history.push(`/reasons/detail/${trainingRequestReason.tds[0].valor}`)
  };

  return (
    <Consumer>
      {context => (
    <div className="Portfolios">
      {mainPage === true ? (
            context.setLogged(false)
          ) :
      showLoader? (
        <div className="load">
          <div className="dot"></div>
          <div className="outline">
            <span></span>
          </div>
        </div>
      ) : load === false ? (
        //If data is already loaded
           (
            <div className="table-display">
              <div className="title-span">
                  <h3>Razones de Solicitud de Soporte</h3>  
                </div>
              <div className="btnspan">
              <Link to="/registerreason">
                  <Button
                    type="primary"
                    elementAttr={{ type: "button" }}
                    size="default"
                  >
                    {"Crear Razón"}
                  </Button>
                </Link>
              </div>
              <div className="center-table">
              <Table
                headers={array.headers}
                rows={array.rows}
                type="trainingrequest"
                onrowclick={renderdetails}
              />
                </div>
            </div>
          )
        
      ) : null}
    </div>
      )}
    </Consumer>
  );
};

export default TrainingRequestReasons;
