import { Modal, Paper, Typography } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { sleep } from '../../../../../Util/utils'
import { useNotificationContext } from '../../Context/NotificationProvider'
import CloseIcon from '@material-ui/icons/Close';
import './Notification.scss'

export const Notification: FunctionComponent = () => {

    const [state, hide] = useState(false)
    const { notifications } = useNotificationContext()

    const [text, updateText] = useState<{ title: string, content: string }>({
        title: 'Title',
        content: "Notification content"
    })

    useEffect(() => {
        if (notifications.length === 0) return

        const notification = notifications[0]

        switch (notification.type) {
            case "NEW_MESSAGE":
                updateText({
                    title: 'Nuevo mensaje',
                    content: `Nuevo mensaje recibido de: ${notification.chat.first_name} ${notification.chat.last_name}`
                })
                break;
            case "NEW_CHAT":
                updateText({
                    title: 'Nuevo chat',
                    content: `${notification.chat.first_name} ${notification.chat.last_name} inicio un nuevo chat`
                })
                break;
        }

        hide(true)
        sleep(8000).then(() => hide(false))

    }, [notifications])

    const handleClose = () => {
        hide(false)
    }

    if (!state) return (<></>)

    return (
        <Paper className={`notification`} elevation={2} square>
            <div className="text">
                <Typography variant="h5" component="h6" className="title">{text.title}</Typography>
                <Typography variant="h6" component="h6" className="content">{text.content}</Typography>
            </div>
            <div className="closeContainer" onClick={handleClose}>
                <CloseIcon fontSize="large" style={{ color: 'white' }} className="closeIcon" />
            </div>
        </Paper>
    )
}