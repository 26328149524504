import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createClient as mutation } from "../../../Util/Queries/Mutations";
import { getClients } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface ClientFormValues {
  name: string;
  address: string;
  contactNumber: string;
}

export interface ClientFormValidation {
  name: TFieldValidation;
  address: TFieldValidation;
  contactNumber: TFieldValidation;
}

const RegisterClient: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [createClient, { data, loading, error }] = useMutation<any>(mutation, {
    update: (cache, { data: { createClient } }) => {
      const { clients }: any = cache.readQuery({ query: getClients });
      const newClients = [...clients, createClient];
      cache.writeQuery({
        query: getClients,
        data: { clients: newClients },
      });
    },
  });
  const [mainpage, setmainpage] = useState<boolean>(false);

  const defaultFormValues: ClientFormValues = {
    name: "",
    address: "",
    contactNumber: "",
  };

  const formValidation: ClientFormValidation = {
    name: "not-empty",
    address: "not-empty",
    contactNumber: "number",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    ClientFormValues,
    ClientFormValidation
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on register mutation
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado la empresa con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data, error, loading]);

  //submit register data to backend
  const submitForm = () => {
    let Clientdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      try {
        Clientdata = {
          name: values.name,
          address: values.address,
          contactNumber: values.contactNumber,
        };
        createClient({ variables: { data: Clientdata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Empresa</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa el nombre de la empresa.",
                      }}
                      name="name"
                      value={values.name}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.name}
                    />
                    <FormField
                      type="text"
                      label="Direccion"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa una direccion.",
                      }}
                      name="address"
                      value={values.address}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.address}
                    />

                    <FormField
                      type="text"
                      label="Numero de Contacto"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un telefono válido.",
                      }}
                      name="contactNumber"
                      value={values.contactNumber}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.contactNumber}
                    />
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/Clients" />
        )
      }
    </Consumer>
  );
};

export default RegisterClient;
