/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getProductLines } from "../../Util/Queries/Queries";
import Button from "../Atoms/Button";
import Table, { Row } from "../Atoms/Table/Table";
import "./styles.scss";
import { InputGroup, FormControl, Pagination,Spinner } from "react-bootstrap";

const ProductLines: React.FC<{ history: History }> = (props) => {
  const [load, setLoad] = useState<boolean>(true);
  const [array, setArray] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [active, setActive] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>('');
  //Getquery hook
  const [exec, { loading, error, data }] = useLazyQuery(getProductLines, {
    fetchPolicy: "network-only",
  });
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);
  const [paginationItems, setpaginationItems] = useState<any>([]);
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const rows: any = [];


  //main Useeffect that retreives data from backend, and handles errors, if it success fet ching data it fills pagination, and table components
  useEffect(() => {
    if (!loading) {
      if (data) {
        setShowLoader(false);
        fillPagination();
        createtable();
      }
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
             return errores.error("Su sesion ha caducado");
          } else {
            return errores.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);
  //useEffect that executes the lazyquery on component mount
  useEffect(() => {
    exec({
      variables: { 
        filter: { pagination: { currentPage: 1, limit: 10 } },
        orderBy: { field:"name", ascending:true, nullFirst:false }
      },
    });
  }, []);

  //pagination and search functions

  const handleClick = (page: any) => {
    setActive(page);
    exec({
      variables: { 
        filter: { 
          pagination: { currentPage: page, limit: 10 },
          search: {
            value: searchString,
            fields: ["name"],
          },
        },
        orderBy: { field:"name", ascending:true, nullFirst:false }
      },
    });
  };

  //funciton that fills pagination component

  const fillPagination = () => {
    let items: any = [];
    setTotalPages(data.productLines.pagination.totalPages);
    for (
      let number = active-4;
      number < active;
      number++
    ) {
      if(number>=1){
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    for (
      let number = active;
      number <= active+4;
      number++
    ) {
      if(number<=data.productLines.pagination.totalPages){
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    
    setpaginationItems(items);
    setPaginationLoad(true);
  };

  const performSearch = (e: any) => {
    setActive(1);
    exec({
      variables: {
        filter: {
          search: {
            value: e,
            fields: ["name"],
          },
          pagination: {
            currentPage: 1,
            limit: 10,
          },
        },
        orderBy: { field:"name", ascending:true, nullFirst:false }
      },
    });
  };
  const debounce = (func: any, wait: number, e: any) => {
    let timeout: any;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(e);
    }, wait);
  };
  const handleSearchChange = (e: any) => {
    setSearchString(e.target.value);
    debounce(performSearch, 400, e.target.value);
  };

  //end pagination and search
  //load table content
  const createtable = () => {
    var arr: any = [];
    var temp: any = [];
    data.productLines.items.map((productLine: any) => {
      temp = [];
      arr = [...arr, productLine];
      productLine.productStyles.map((plant: any) => {
        return temp.push(plant.name);
      });
      const row: Row = {
        tds: [
          {
            valor: productLine.id,
          },
          {
            valor: productLine.name,
          },
          {
            valor: productLine.description,
          },
        ],
      };
      return rows.push(row);
    });
    const a = {
      headers: ["ID", "NOMBRE", "DESCRIPCION"],
      rows: rows,
    };
    setArray(a);
    setLoad(false);
  };
  const renderdetails = (productLine: Row) => {
    props.history.push(`/productlines/detail/${productLine.tds[0].valor}`);
  };

  return (
    <Consumer>
      {(context) => (
        <div className="ProductLines">
          {mainPage === true ? (
            context.setLogged(false)
          ) : showLoader===true ? (
            <div className="load">
              <div className="dot"></div>
              <div className="outline">
                <span></span>
              </div>
            </div>
          ) : load === false ? (
            //If data is already loaded
            <div className="table-display">
              <div className="title-span">
                <h3>Líneas de Producto Registradas</h3>
              </div>
              <div className="btnspan">
              <InputGroup>
                  <FormControl
                    placeholder="Buscar..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="search-bar"
                    onChange={(e)=>{handleSearchChange(e)}}
                  />
                    <InputGroup.Append>
                      <Button
                        type="primary"
                        elementAttr={{ type: "button" }}
                        size="default"
                        clickEvent={()=>{props.history.push('/registerproductline')}}
                      >
                        {"Crear Línea"}
                      </Button>
                  </InputGroup.Append>
                  </InputGroup>
              </div>
              <div className="center-table">
                {loading?
                <div className="spinnerSpan"><Spinner animation="border" variant="primary" /></div>
              :
              <Table
                  headers={array.headers}
                  rows={array.rows}
                  type="productLine"
                  onrowclick={renderdetails}
                />  
              }
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                  <Pagination.First
                    onClick={() => handleClick(1)}
                  />
                    <Pagination.Prev
                      key={active-1}
                      onClick={() => handleClick(active-1)}
                    />
                    </>
                  ) : null}
                  {paginationItems}
                  {active <totalPages ? (
                    <>
                  <Pagination.Next
                      key={active+1}
                      onClick={() => handleClick(active+1)}
                    />
                  <Pagination.Last
                    onClick={() => handleClick(totalPages)}
                  />
                  </>
                  )
                  :null}
                </Pagination>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </Consumer>
  );
};

export default ProductLines;
