import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createProductStyle as mutation } from "../../../Util/Queries/Mutations";
import {
  getProductLines,
  getProductStyles,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface ProductStyleFormValues {
  name: string;
}

export interface ProductStyleFormValidation {
  name: TFieldValidation;
}

const RegisterProductStyle: React.FC<{}> = (props) => {
  const alert = useAlert();
  const productLinesQuery = useQuery(getProductLines, {
    variables: { filter: { pagination: { limit: 1000, currentPage: 1 } } },
    fetchPolicy: "network-only",
  });
  const [description, setDescription] = useState<string>();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [productLineList, setProductLineList] = useState<any>([]);
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [productLineSelected, setProductLineSelected] = useState<any>({
    value: "",
    label: "Selecciona una Línea de producto...",
  });
  const [logOut, setLogOut] = useState<boolean>(false);
  const [createProductStyle, { data, loading, error }] = useMutation<any>(
    mutation,
    {
      update: (cache, { data: { createProductStyle } }) => {
        const { productStyles }: any = cache.readQuery({
          query: getProductStyles,
        });
        const newProductStyles = [...productStyles, createProductStyle];
        cache.writeQuery({
          query: getProductStyles,
          data: { productStyles: newProductStyles },
        });
      },
    }
  );
  const [mainpage, setmainpage] = useState<boolean>(false);

  const defaultFormValues: ProductStyleFormValues = {
    name: "",
  };

  const formValidation: ProductStyleFormValidation = {
    name: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    ProductStyleFormValues,
    ProductStyleFormValidation
  >(defaultFormValues, formValidation);

  //useeffect to handle success/error on register data
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado el Estilo de Producto con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  // fetch registered productLines to which will belong the style, this fill productLinesSelect component
  useEffect(() => {
    if (!productLinesQuery.loading) {
      if (productLinesQuery.data) {
        let arr: any = [];
        productLinesQuery.data.productLines.items.map((productline: any) => {
          return (arr = [
            ...arr,
            { value: productline.id, label: productline.name },
          ]);
        });
        setProductLineList(arr);
      }
      if (productLinesQuery.error) {
        productLinesQuery.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productLinesQuery.data,
    productLinesQuery.error,
    productLinesQuery.loading,
  ]);

  const handleSelectChange = (linea: any) => {
    setProductLineSelected(linea);
  };
  //submit register data to backend
  const submitForm = () => {
    let ProductStyledata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectLine: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return (errorsFound = true);
      }
      return null;
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return (emptyField = true);
      }
      return null;
    });
    if (productLineSelected.value === "") {
      errorSelectLine = true;
    }
    if (!description) {
      emptyField = true;
    }

    if (errorsFound || emptyField || errorSelectLine) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (errorSelectLine) {
        alert.error(
          "Por favor seleccione la línea a la que pertenece el estilo"
        );
      }
    } else {
      setDisabledButton(true);
      try {
        ProductStyledata = {
          name: values.name,
          description: description,
          productLineID: productLineSelected.value,
        };
        createProductStyle({ variables: { data: ProductStyledata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Estilo de Producto</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa un nombre para el estilo de producto.",
                      }}
                      name="name"
                      value={values.name}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.name}
                    />
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={description}
                        onChange={handleTextAreaChange}
                      />
                    </Form.Group>
                    <div className="spacer">
                      {!productLinesQuery.loading ? (
                        <Select
                          value={productLineSelected}
                          options={productLineList}
                          onChange={handleSelectChange}
                        />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/productstyles" />
        )
      }
    </Consumer>
  );
};

export default RegisterProductStyle;
