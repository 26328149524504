import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createTrainingRequestReason as mutation } from "../../../Util/Queries/Mutations";
import { getTrainingRequestReasons } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface TrainingRequestReasonFormValues {
  reason: string;
}

export interface TrainingRequestReasonFormValidation {
  reason: TFieldValidation;
}

const RegisterTrainingRequestReason: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false);
  const [createTrainingRequestReason, { data, loading, error }] = useMutation<
    any
  >(mutation, {
    update: (cache, { data: { createTrainingRequestReason } }) => {
      const { trainingRequestReasons }: any = cache.readQuery({
        query: getTrainingRequestReasons,
      });
      const newTrainingRequestReasons = [
        ...trainingRequestReasons,
        createTrainingRequestReason,
      ];
      cache.writeQuery({
        query: getTrainingRequestReasons,
        data: { trainingRequestReasons: newTrainingRequestReasons },
      });
    },
  });
  const [mainpage, setmainpage] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const defaultFormValues: TrainingRequestReasonFormValues = {
    reason: "",
  };

  const formValidation: TrainingRequestReasonFormValidation = {
    reason: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    TrainingRequestReasonFormValues,
    TrainingRequestReasonFormValidation
  >(defaultFormValues, formValidation);

  //useeffect to handle success/error on register data
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado la razón de solicitud con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  //submit register data to backend
  const submitForm = () => {
    let TrainingRequestReasondata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });

    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      try {
        TrainingRequestReasondata = {
          reason: values.reason,
        };
        createTrainingRequestReason({
          variables: { data: TrainingRequestReasondata },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Razón de Solicitud de Soporte</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Razón"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa una razón.",
                      }}
                      name="reason"
                      value={values.reason}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.reason}
                    />
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/reasons" />
        )
      }
    </Consumer>
  );
};

export default RegisterTrainingRequestReason;
