import { InputAdornment, TextField } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import './ChatList.scss'
import { ListElement } from './ListElement/ListElement'
import SearchIcon from '@material-ui/icons/Search';
import useChatContext from '../../Context/ChatContext';
import { useLazyQuery } from 'react-apollo';
import { getChats } from '../../../../../Util/Queries/Queries';
import { capitalize } from '../../../../../Util/utils';
import { useNotificationContext } from '../../Context/NotificationProvider';

interface IMessage {
    from: string
    preview: string
    date: string
    selected: boolean,
    email: string,
    phone: string,
    company: string,
    status: string,
    id: number
}

export const ChatList: FunctionComponent = () => {

    const { changeContact, contact } = useChatContext().currentChat
    const { clearMessages } = useChatContext().messageCache

    const [loadChats, { loading, error, data }] = useLazyQuery(getChats, {
        fetchPolicy: "network-only", 
        onCompleted: () => {
            if (data != null) {
                let msg = data.getChats.map((el: any) => {
                    const selected = el.id === contact.id
                    return {
                        from: `${(capitalize(el.first_name))} ${capitalize(el.last_name)}`,
                        preview: el.email,
                        date: `${el.createdAt.split('T')[0]}`,
                        selected: selected,
                        email: el.email,
                        phone: el.phone,
                        company: el.company,
                        status: el.status,
                        id: el.id,
                    }
                })

                setMessages(msg)
                setFilteredMessages(msg)
            }
        },
        onError: (error) => {
            console.log(error)
        }
    })

    const [filter, setFilter] = useState<string>('Mostrar: Todos')
    const [messages, setMessages] = useState<IMessage[]>([])

    const [filteredMessages, setFilteredMessages] = useState<IMessage[]>(messages)
    const [query, setQuery] = useState<string>('');

    const { notifications } = useNotificationContext()

    useEffect(() => {
        if (notifications.length === 0) return
        const notification = notifications[0]

        if (notification.type !== 'NEW_CHAT') return

        const { chat } = notification;

        const msg = {
            from: `${(capitalize(chat.first_name))} ${capitalize(chat.last_name)}`,
            preview: chat.email,
            date: `${chat.createdAt.split('T')[0]}`,
            selected: false,
            email: chat.email,
            phone: chat.phone,
            company: chat.company,
            status: chat.status,
            id: chat.id
        }
        //here
        const aux = [...messages]
        aux.unshift(msg)

        setMessages(aux);
        setFilteredMessages(aux);
    }, [notifications])

    useEffect(() => {
        if (contact.status === '') return

        const msg = [...filteredMessages]
        msg.forEach(el => {
            if (el.id === contact.id) el.status = contact.status
        })
        setFilteredMessages(msg)
    }, [contact])

    const filterMessageList = (sender: string) => {
        return filteredMessages.filter(el => el.from.toLowerCase().search(sender.toLowerCase()) !== -1)
    }

    useEffect(() => {
        if (query.length === 0) {
            setFilteredMessages(messages)
            return
        }

        setFilteredMessages(filterMessageList(query))
    }, [query])

    useEffect(() => loadChats({ variables: {} }), [])

    useEffect(() => {
        const _filter = filter.split(':')[1].slice(1)
        switch (_filter) {
            case "Todos":
                setFilteredMessages(messages)
                break
            case "Activos":
                setFilteredMessages(messages.filter(el => el.status !== 'CLOSE'))
                break
            case "Resueltos":
                setFilteredMessages(messages.filter(el => el.status === 'CLOSE'))
                break
        }
    }, [filter])

    return (
        <div className="list-container">
            <TextField
                placeholder="Buscar"
                variant="outlined"
                style={{ width: '90%' }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }} />
            <TextField
                select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                SelectProps={{
                    native: true,
                }}
                style={{ width: '80%', marginTop: '5%', marginBottom: '10%' }}
            >
                <option key='filter0' selected>Mostrar: Todos</option>
                <option key='filter1'>Mostrar: Activos</option>
                <option key='filter2'>Mostrar: Resueltos</option>
            </TextField>
            <div className="chatList">
                {
                    filteredMessages.map((el, index) => (
                        <ListElement key={index} from={el.from} preview={el.preview} date={el.date} selected={el.selected} _new={el.status === 'OPEN'} pending={el.status === 'PENDING'} onClick={() => {
                            const aux = [...filteredMessages]

                            aux.forEach(el => el.selected = false)

                            aux[index].selected = !el.selected

                            changeContact({
                                id: aux[index].id,
                                name: aux[index].from,
                                phone: aux[index].phone,
                                mail: aux[index].email,
                                company: aux[index].company,
                                open: aux[index].status !== 'CLOSE',
                                status: aux[index].status
                            })

                            setMessages(aux)
                            clearMessages()
                        }} />
                    ))
                }
            </div>
        </div>
    )
}

