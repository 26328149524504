/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import Table, { Row } from "../../Atoms/Table/Table";
import { editPlant } from "../../../Util/Queries/Mutations";
import {Button as Bbutton} from 'react-bootstrap'
import {
  getPortfolios,
  getPlantById as query,
  getPlants,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import { History } from "history";
import Select from "react-select";
export interface PlantDetailValidations {
  name: TFieldValidation;
  contactNumber: TFieldValidation;
  address: TFieldValidation;
  plant: TFieldValidation;
  status: TFieldValidation;
  plantPortfolio: TFieldValidation;
}
export interface PlantDetailForm {
  name: string;
  contactNumber: string;
  address: string;
  client: string;
  status: string;
  plantPortfolio: string;
}

const PlantDetail: React.FC<{ history: History }> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [array, setArray] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [editplant, { loading, data, error }] = useMutation(editPlant);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [portfoliosLoaded, setPortfoliosLoaded] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [deleted, setDeleted] = useState<boolean>(false);
  const [portfolioSelected, setportfolioSelected] = useState<any>({
    value: "",
    label: "Selecciona un nuevo portafolio",
  });
  const [portfolioOptions, setportfolioOptions] = useState<any>([]);
  //const
  const alert = useAlert();
  const rows: any = [];
  const { id } = useParams();
  //Apollohooks
  const getPlant = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy:"network-only"
  });
  const listPortfolios = useQuery(getPortfolios,{fetchPolicy:"network-only"});
  //formvalues

  const defaultFormValues: PlantDetailForm = {
    name: "",
    contactNumber: "",
    address: "",
    client: "",
    status: "",
    plantPortfolio: "",
  };

  const formValidation: PlantDetailValidations = {
    name: "not-empty",
    contactNumber: "number",
    address: "not-empty",
    plant: "not-empty",
    status: "not-empty",
    plantPortfolio: "not-empty",
  };

  const updateCache = (plant: any) => {
    const data = plant.readQuery({
      query: getPlants,
    });
    const newData = {
      plants: data.plants.filter((t: any) => t.id !== id),
    };
    plant.writeQuery({
      query: getPlants,
      data: newData,
    });
  };

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getPlant.loading) {
      if (getPlant.data) {
        createtable();
        forceUpdateValue({
          name: getPlant.data.plant.name,
          address: getPlant.data.plant.address,
          contactNumber: getPlant.data.plant.contactNumber,
          client: getPlant.data.plant.client.name,
          status:
            getPlant.data.plant.status === "ACTIVE" ? "Activo" : "Inactivo",
          plantPortfolio: getPlant.data.plant.plantPortfolio
            ? getPlant.data.plant.plantPortfolio.name
            : "",
        });
        if (getPlant.data.plant.plantPortfolio) {
          setportfolioSelected({
            value: getPlant.data.plant.plantPortfolio.id,
            label: getPlant.data.plant.plantPortfolio.name,
          });
        }
      }
      if (getPlant.error) {
        getPlant.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getPlant.loading]);

  //Fill portfolios data to show in select
  useEffect(() => {
    if (!listPortfolios.loading) {
      if (listPortfolios.data) {
        var arr: any = [];
        listPortfolios.data.portfolios.items.map((portfolio: any) => {
          return arr = [...arr, { value: portfolio.id, label: portfolio.name }];
        });
        setportfolioOptions(arr);
        setPortfoliosLoaded(true);
      }
      if (listPortfolios.error) {
        listPortfolios.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [listPortfolios.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<PlantDetailForm, PlantDetailValidations>(
    defaultFormValues,
    formValidation
  );

  const submitForm = () => {
    let Plantdata:any;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectClient: boolean = false;
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.keys(values).map((val:string)=>{
      if(val!=="plantPortfolio"){
        //@ts-ignore
        if(values[val]===""){
          return emptyField=true
        }
      }
      return null
    })
    if (errorsFound || emptyField || errorSelectClient) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (errorSelectClient) {
        alert.error(
          "Por favor indique la empresa a la que pertenece la planta"
        );
      }
    } else {
      setDisabledButton(true)
      try {
        Plantdata = {
          name: values.name,
          address: values.address,
          contactNumber: values.contactNumber
        };
        if(portfolioSelected.value!==""){
          Plantdata["plantPortfolio"]=portfolioSelected.value
        }
        editplant({ variables: { id: id ? +id : 0, data: Plantdata } }).catch((error) => {
          console.log(error.graphQLErrors[0].message.code);
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false)
      }
    }
  };

  const performDelete = () => {
    setDeleted(true);
    setDisabledButton(true)
    let dataModified: any = {
      status: "INACTIVE",
    };
    editplant({
      variables: {
        id: +getPlant.data.plant.id,
        data: dataModified,
      },
      update: updateCache,
    });
    setModal(false);
  };

  //Fill user's data
  const createtable = () => {
    var arr: any = [];
    getPlant.data.plant.users.map((user: any) => {
      arr = [...arr, user];
      const row: Row = {
        tds: [
          {
            valor: user.id,
          },
          {
            valor: user.firstName + " " + user.lastName,
          },
          {
            valor: user.email,
          },
          {
            valor: user.phoneNumber,
          },
          {
            valor: "Usuario",
          }
        ],
      };
      return rows.push(row);
    });
    
    var resArray: any = [];
    getPlant.data.plant.responsibles.map((responsible: any) => {
      resArray = [...resArray, responsible];
      const row: Row = {
        tds: [
          {
            valor: responsible.id,
          },
          {
            valor: responsible.firstName + " " + responsible.lastName,
          },
          {
            valor: responsible.email,
          },
          {
            valor: responsible.phoneNumber,
          },
          {
            valor: "Responsable"
          }
        ],
      };
      return rows.push(row);
    });

    const usersData = {
      headers: ["ID", "NOMBRE", "CORREO", "TELEFONO", "TIPO"],
      rows: rows,
    };
    setArray(usersData);
    setLoad(true);
  };

  const handleRowClick = (user: Row) => {
    if(user.tds[4].valor === "Usuario"){
      props.history.push(`/users/detail/${user.tds[0].valor}`);
    }else if(user.tds[4].valor === "Responsable"){
      props.history.push(`/responsibles/detail/${user.tds[0].valor}`);
    }
  };

  //UseEffects to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false)
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 468) {
            return alert.error("Una planta con el mismo nombre ya se encuentra registrada");
          } else if (error.message.code === 505) {
            return alert.error("No se puede eliminar la planta ya que tiene relación con otros elementos, elimine estos elementos primero");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        var msg = "";
        if (deleted) {
          msg = "Se ha eliminado la planta con exito";
        } else {
          msg = "Se ha modificado la planta con exito";
        }
        alert.success(msg, {
          onClose: () => {
            props.history.push(`/plants`);
          },
        });
      }
    }
  }, [loading]);

  const handleSelectChange = (portfolio: any) => {
    setportfolioSelected(portfolio);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getPlant.loading ? (
          getPlant.data ? (
            load ? (
              <div className="content-container">
                <ConfirmationModal
                  show={modal}
                  onHide={() => setModal(false)}
                  onCancel={() => setModal(false)}
                  clickEvent={performDelete}
                  message={
                    <div>
                      Está seguro que desea eliminar esta planta?
                      <br /> Esta acción no se puede revertir...
                    </div>
                  }
                  action="Eliminar Planta"
                  type="delete"
                />
                <div className="mainContainer">
                  <div className="buttonspan">
                    <label></label>
                    {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                    <div className="buttonCustom">
                          <Button
                            type="danger"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            clickEvent={() => {
                              setModal(true);
                            }}
                            size="default"
                          >
                            {"Eliminar"}
                          </Button>
                      {edit ? (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            clickEvent={submitForm}
                            size="default"
                          >
                            {"Guardar"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button" }}
                            clickEvent={() => {
                              setedit(true);
                            }}
                            size="default"
                          >
                            {"Editar"}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="detailcontainer">
                    <h2>{edit ? "Editar Planta" : "Detalles de Planta"}</h2>
                    <div className="input-container">
                      <FormField
                        type="text"
                        label="Nombre"
                        disabled={!edit}
                        required={{
                          required: true,
                          requiredMsg: "Por favor ingresa un nombre.",
                        }}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="name"
                        value={values.name}
                      />

                      <FormField
                        type="text"
                        label="Dirección"
                        name="address"
                        value={values.address}
                        disabled={!edit}
                        required={{
                          required: true,
                          requiredMsg: "Por favor ingresa una dirección.",
                        }}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />

                      <FormField
                        type="text"
                        label="Numero de Contacto"
                        disabled={!edit}
                        required={{
                          required: true,
                          requiredMsg: "Por favor ingresa un numero válido.",
                        }}
                        name="contactNumber"
                        value={values.contactNumber}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                      {edit ? (
                        portfoliosLoaded ? (
                          <>
                            <h6>Portafolio al que pertenece la planta</h6>
                            <Select
                              value={portfolioSelected}
                              options={portfolioOptions}
                              onChange={handleSelectChange}
                            />
                          </>
                        ) : null
                      ) : (
                        <>
                          <Link
                            className="form-link"
                            to={
                              "/clients/detail/" + getPlant.data.plant.client.id
                            }
                            style={{textDecoration:"none"}}
                          >
                            <FormField
                              type="text"
                              label="Empresa"
                              name="Client"
                              disabled={!edit}
                              value={values.client}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                            />
                          </Link>
                          {getPlant.data.plant.plantPortfolio ? (
                            <Link
                              className="form-link"
                              style={{textDecoration:"none"}}
                              to={
                                "/portfolios/detail/" +
                                getPlant.data.plant.plantPortfolio.id
                              }
                            >
                              <FormField
                                type="text"
                                label="Portafolio"
                                name="plantPortfolio"
                                //@ts-ignore
                                value={values.plantPortfolio}
                                disabled={!edit}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Link>
                          ) : (
                            <FormField
                              type="text"
                              label="Portafolio"
                              name="plantPortfolio"
                              //@ts-ignore
                              value={values.plantPortfolio}
                              disabled={!edit}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                                disabled: edit,
                              }}
                            />
                          )}
                          <FormField
                            type="text"
                            label="Estatus"
                            name="status"
                            //@ts-ignore
                            value={values.status}
                            disabled={!edit}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur
                            }}
                          />
                          <h6>Usuarios de la planta</h6>
                          <Table
                            headers={array.headers}
                            rows={array.rows}
                            type="users"
                            onrowclick={handleRowClick}
                          />
                        </>
                      )}
                    </div>
                    <div className="buttonspan"></div>
                  </div>
                </div>
              </div>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default PlantDetail;
