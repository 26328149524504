import React,{ useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from  "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import {
    getIndustries,
    getSuccessCase as query,
    getSuccessCases,
    getResourcesByOwner
  } from "../../../Util/Queries/Queries";
  import {
    editSuccessCase
  } from "../../../Util/Queries/Mutations";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { Button as Bbutton, Form, Spinner } from "react-bootstrap";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import { Consumer } from "../../../Util/Context/Context";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import { useAlert } from "react-alert";
import Select from "react-select";
import Table, { Row } from "../../Atoms/Table/Table";

  export interface SuccessCaseDetailValidations {
    name: TFieldValidation;
    industry: TFieldValidation;
  }
  export interface SuccessCaseDetailForm {
    name: string;
    industry: string;
    image: string;
  }

const SuccessCaseDetail: React.FC<any> = (props) => {
    const [edit, setedit] = useState<boolean>(false);
    const [description, setDescription] = useState<string>();
    const [logOut, setLogOut] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [deleteSuccess, setDelete] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false);
    const [disabledButton, setDisabledButton] = useState<boolean>(false)
    const [resources, setResources] = useState<any>([]);
    const [industries, setIndustries] = useState<any>([]);
    const [image, setImage] = useState<any>();

      const [industrySelected, setIndustrySelected] = useState<any>({
          value: "",
          label: "Selecciona una Industria...",
        });

    const alert = useAlert();
    const { id } = useParams();
    const resourceRows: any = [];

    const getSuccessCase = useQuery(query, {
        variables: { id: id ? +id : 0 },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    const resourcesByOwner = useQuery(getResourcesByOwner, {
      variables: {
        owner: "successCase",
        itemId: id ? +id : 0
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

    const industriesList = useQuery(getIndustries, {
      fetchPolicy: "network-only",
    });

    // const [performReorder, reorderStatus] = useMutation(editResourcePosition);
    const [performEdit, { loading, data, error }] = useMutation(editSuccessCase);

    const defaultFormValues: SuccessCaseDetailForm = {
        name: "",
        industry: "",
        image: "",
    };

    const formValidation: SuccessCaseDetailValidations = {
        name: "not-empty",
        industry: "not-empty"
    };

    const updateCache = (successCase: any) => {
        const data = successCase.readQuery({
            query: getSuccessCases,
        });
        const newData = {
            successCases: data.successCases.filter((t: any) => t.id !== id),
        };
        successCase.writeQuery({
            query: getSuccessCases,
            data: newData,
        });
    };

    //Main useEffect fetching data to fill form
    useEffect(() => {
        if (!getSuccessCase.loading) {
            if (getSuccessCase.data) {
              console.log(getSuccessCase.data)
                setDescription(getSuccessCase.data.successCase.description);
                setIndustrySelected({
                    value: getSuccessCase.data.successCase.industry.id,
                    label: getSuccessCase.data.successCase.industry.name
                })

                if(getSuccessCase.data.successCase.image !== null){
                  setImage(getSuccessCase.data.successCase.image)
                }else { 
                  setImage("")
                }

                forceUpdateValue({
                    name: getSuccessCase.data.successCase.name,
                    image: "",
                    industry: getSuccessCase.data.successCase.industry.name,
                });
                setLoad(true)
            }
            if (getSuccessCase.error && getSuccessCase.data == null) {
                getSuccessCase.error.graphQLErrors.map((error: any) => {
                if (error.message.statusCode===401) {
                    setLogOut(true);
                    return alert.error("Su sesion ha caducado");
                } else {
                    return alert.error("Ha ocurrido un error");
                }
                });
            }
        }
    }, [getSuccessCase.loading]);

     //Fetch styles that will this new priduct belong to, this fills productStyleSelect component
  useEffect(() => {
    if (!industriesList.loading) {
      if (industriesList.data) {
        let arr: any = [];
        industriesList.data.industries.items.map((industry: any) => {
          return arr = [...arr, { value: industry.id, label: industry.name }];
        });
        setIndustries(arr);
      }
      if (industriesList.error) {
        industriesList.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ industriesList]);

  useEffect(() => {
    var arr: any = [];
    var aData = {
      headers: ["ID", "NOMBRE", "TIPO" ,"ENLACE"],
      rows: [],
    }
    console.log(resourcesByOwner.loading)
    if (!resourcesByOwner.loading) {
      if (resourcesByOwner.data) {
        console.log(resourcesByOwner.data)
        resourcesByOwner.data.resourceByOwner.map((resource: any) => {
            arr = [...arr, resource];
            const row: Row = {
              tds: [
                {
                  valor: resource.id,
                },
                {
                  valor: resource.name,
                },
                {
                  valor:
                    resource.type === "PDF_QUOTE_TEMPLATE"
                      ? "Plantilla de Cotización"
                      : resource.type === "PDF"
                      ? "Ficha Técnica o Material de Apoyo"
                      : "Video",
                },
                {
                  valor: resource.path,
                },
              ],
            };
            resourceRows.push(row);
        })
        aData.rows = resourceRows
        setLoad(true);
        setResources(aData);
      }
      if (resourcesByOwner.error) {
        resourcesByOwner.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }else{
      setResources(aData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesByOwner.loading, resourcesByOwner.data]);

  const {
      handleChange,
      values,
      handleBlur,
      errors,
      forceUpdateValue,
    } = useForm<SuccessCaseDetailForm, SuccessCaseDetailValidations>(
      defaultFormValues,
      formValidation
    );
    

    useEffect(() => {
        if (!loading) {
          if (error) {
            setDisabledButton(false)
            error.graphQLErrors.map((error: any) => {
              if (error.message.statusCode === 401) {
                setLogOut(true);
                return alert.error("Su sesion ha caducado");
              } else if (error.message.code === 505) {
                return alert.error(
                  "No se puede eliminar el caso ya que tiene relacion con otros elementos, elimine estos elementos primero"
                );
              } else {
                return alert.error("Ha ocurrido un error");
              }
            });
          }
          if (data) {
            let msg = "";
            if (deleteSuccess) {
              msg = "Se ha eliminado el caso de éxito...";
            } else {
              msg = "Se han guardado los cambios";
            }
            alert.success(msg, {
              onClose: () => {
                props.history.push(`/successcases`);
              },
            });
          }
        }
      }, [loading]);

      //Onsubmit function execute
    const submitForm = () => {
        let successCaseData;
        let errorsFound: boolean = false;
        let emptyField: boolean = false;
        let errorSelectIndustry: boolean = false;
        //validations provided by Formfield component, also validates empty fields before focus
        Object.values(errors).map((item: any) => {
            if (item === true) {
                return errorsFound = true;
            }
            return null
        });
        if (industrySelected.value === "") {
            errorSelectIndustry = true;
        }
        if (description === "" || values.name === "") {
            emptyField = true;
        }
        if (errorsFound || emptyField || errorSelectIndustry) {
        if (errorsFound) {
            alert.error("Por favor llena todos los campos en el formato indicado");
        }
        if (emptyField) {
            alert.error("No puede dejar campos vacios");
        }
        } else {
          setDisabledButton(true)
          try {
              successCaseData = {
                  name: values.name,
                  description: description,
                  status: "ACTIVE",
                  industryID: industrySelected.value
              };
              performEdit({
                  variables: {
                      id: id ? +id : 0,
                      data: successCaseData,
                      image: image
                  },
              });
          } catch (error) {
              alert.error("Ha ocurrido un error");
              setDisabledButton(false)
          }
        }
    };

    const handleTextAreaChange = (event: any) => {
        setDescription(event.target.value);
    };
    const handleSelectChange = (indus: any) => {
        setIndustrySelected(indus);
      };

    //Show resource detail on row click
    const handleResourceClick = (resource: any) => {
      console.log("Clicked")
      props.history.push(
        `/successcases/detail/${id}/resources/detail/${resource.tds[0].valor}`
      );
    };

    //Execute delete mutation function
    const performDelete = () => {
        setDisabledButton(true)
        let dataModified: any = {
            status: "INACTIVE",
            name: values.name,
            description: description,
            industryID: industrySelected.value
        };
        performEdit({
            variables: {
                id: id ? +id : 0,
                data: dataModified,
                image: image
            },
        update: updateCache,
        });
        setModal(false);
        setDelete(true);
    };

    return(
        
    <Consumer>
    {(context) =>
      logOut === true ? (
        context.setLogged(false)
      ) : getSuccessCase.networkStatus!==1 ? (
        getSuccessCase.data ? (
            <Form>
                <div className="content-container">
                <ConfirmationModal
                    show={modal}
                    onCancel={() => setModal(false)}
                    onHide={() => setModal(false)}
                    clickEvent={performDelete}
                    message={
                    <div>
                        Está seguro que desea eliminar esta línea?
                        <br /> Esta acción no se puede revertir...
                    </div>
                    }
                    action="Eliminar Línea"
                    type="delete"
                />
                <div className="mainContainer">
                    <div className="buttonspan">
                    <label></label>
                    {
                        edit ?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                    >
                        <div className="backIcon"></div>
                    </Bbutton>
                    :null
                    }
                    <div className="buttonCustom">
                        <Button
                        type="danger"
                        elementAttr={{ type: "button",disabled:disabledButton }}
                        clickEvent={() => {
                            setModal(true);
                        }}
                        size="default"
                        >
                        {"Eliminar"}
                        </Button>
                        {edit ? (
                        <>
                            <Button
                            type="primary"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            clickEvent={submitForm}
                            size="default"
                            >
                            {"Guardar"}
                            </Button>
                        </>
                        ) : (
                        <>
                            <Button
                            type="primary"
                            elementAttr={{ type: "button" }}
                            clickEvent={() => {
                                setedit(true);
                            }}
                            size="default"
                            >
                            {"Editar"}
                            </Button>
                        </>
                        )}
                    </div>
                    </div>
                    <div className="detailcontainer">
                        <h2>
                            {edit
                            ? "Editar Línea"
                            : "Detalles de Línea"}
                        </h2>
                        <div className="input-container">
                            <FormField
                                type="text"
                                label="Nombre"
                                required={{
                                    required: true,
                                    requiredMsg: "Por favor ingresa un nombre.",
                                }}
                                disabled={!edit}
                                inputElAttributes={{
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                }}
                                name="name"
                                value={values.name}
                            />
                            {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label className="form-label">
                                    Descripción
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={description}
                                    onChange={handleTextAreaChange}
                                    disabled={!edit}
                                />
                            </Form.Group> */}
                            {/* {!edit ? (
                              image != "" ?
                              <FormField
                                type="image"
                                label="Imagen"
                                name="image"
                                value="Imagen"
                                link={image}
                              />
                              :
                              <Form.Label>No hay imagen guardada</Form.Label>
                            ):(
                              <FormField
                                type="file"
                                label="Selecciona una imagen"
                                required={{
                                  required: false,
                                  requiredMsg:
                                    "Por favor selecciona una imagen válida.",
                                }}
                                name="image"
                                value={values.image}
                                inputElAttributes={{
                                  onChange: (ev) => {
                                    if (ev.target.files)
                                      setImage(ev.target.files[0]);
                                    handleChange(ev);
                                  },
                                  onBlur: handleBlur,
                                  accept: "image/png,jpg",
                                }}
                              />
                            )} */}
                            {!edit ? (
                                load ? (
                                    <>
                                        <FormField
                                            type="text"
                                            label="Industria"
                                            name="style"
                                            //@ts-ignore
                                            value={values.industry}
                                            disabled={!edit}
                                            inputElAttributes={{
                                              onChange: handleChange,
                                              onBlur: handleBlur,
                                            }}
                                        />
                                        <div className="resources">
                                          <h6>Casos de Éxito</h6>
                                          <Button
                                            type="primary"
                                            elementAttr={{ type: "button" }}
                                            size="small"
                                            clickEvent={() => {
                                              console.log("clicked")
                                              props.history.push(
                                                `/successcases/detail/${id}/addresource`
                                              );
                                            }}
                                          >
                                            {"Agregar Recurso"}
                                          </Button>
                                        </div>
                                        {resourcesByOwner.loading ?
                                          <div className="spinnerSpan">
                                            <Spinner animation="border" variant="primary" />
                                          </div>
                                          :
                                          <Table
                                            headers={resources.headers}
                                            rows={resources.rows}
                                            type="resources"
                                            onrowclick={handleResourceClick}
                                          />
                                        }
                                    </>
                                ) : null
                            ) : (
                                <>
                                    <div className="spacer">
                                        {!industriesList.loading ? (
                                            <>
                                                <h6>Industria</h6>
                                                <Select
                                                    value={industrySelected}
                                                    options={industries}
                                                    onChange={handleSelectChange}
                                                />
                                            </>
                                        ) : (
                                            <div className="spinnerSpan">
                                            <Spinner animation="border" variant="primary" />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="buttonspan"></div>
                    </div>
                </div>
                </div>
            </Form>
        ) : null
      ) : (
        <div className="load">
          <div className="dot"></div>
          <div className="outline">
            <span></span>
          </div>
        </div>
      )
    }
  </Consumer>
    )
}


export default SuccessCaseDetail;