import { useMutation, useQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { Consumer } from "../../../Util/Context/Context";
import { createResource as mutation } from "../../../Util/Queries/Mutations";
import {
  getProductLineById,
  getProductLineById as query,
  getSuccessCase as getCase,
  getSuccessCase as querySC,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";
export interface ResourceFormValues {
  name: string;
  link: string;
  image: string;
  file: string;
  video: string;
}

export interface ResourceFormValidation {
  name: TFieldValidation;
  image: TFieldValidation;
  file: TFieldValidation;
  link: TFieldValidation;
  video: TFieldValidation;
}

const RegisterResource: React.FC<{ history: History }> = (props) => {
  console.log("Create Resource from >>>>> ", props.history.location.pathname)

  const alert = useAlert();
  const { entity, id } = useParams();

  console.log(id, entity)
  let getter : any = () => {}
  let dataQuery : any = {}
  if(entity === 'productlines'){
    getter = getProductLineById
  }else{
    getter = getCase
  }

  const [createResource, { data, loading, error }] = useMutation<any>(
    mutation,
    {
      update: (cache, { data: { createResource } }) => {
        const { item }: any = cache.readQuery({
          query: getter,
        });
        const newData = [...item, createResource];

        if(entity === 'productlines'){
          dataQuery = { productLine: newData } 
        }else{
          dataQuery = { successCase: newData }
        }
          
        cache.writeQuery({
          query: getter,
          variables: { id: id ? +id : 0 },
          data: dataQuery,
        });
      },
    }
  );

  const getProductLineDetail = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy: "network-only",
  });

  const getSuccessCaseDetail = useQuery(querySC, {
    variables: { id: id ? +id : 0 },
    fetchPolicy: "network-only",
  });
  

  const [image, setImage] = useState<any>();
  const [load, setLoad] = useState<boolean>(true);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [quoteTemplate, setQuoteTemplate] = useState<any>();
  const [video, setVideo] = useState<any>();
  const [pressedButton, setpressedButton] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const typeOptions = [
    { label: "Ficha Técnica o Material de Apoyo", value: "PDF" },
    { label: "Video", value: "VIDEO" },
    { label: "Plantilla de cotización", value: "PDF_QUOTE_TEMPLATE" },
  ];
  const [typeSelected, setTypeSelected] = useState<any>({
    value: " ",
    label: "Selecciona un tipo...",
  });
  const videoTypeOptions = [
    { label: "Subir video", value: "UPLOAD" },
    { label: "Link hacia un video", value: "LINK" },
  ];
  const [videoTypeSelected, setVideoTypeSelected] = useState<any>({
    value: " ",
    label: "Selecciona un tipo...",
  });
  const [description, setDescription] = useState<string>();
  const defaultFormValues: ResourceFormValues = {
    name: "",
    image: "",
    file: "",
    link: "",
    video: "",
  };

  const formValidation: ResourceFormValidation = {
    name: "not-empty",
    image: "not-empty",
    file: "not-empty",
    link: "not-empty",
    video: "not-empty",
  };

  const {
    handleChange,
    values,
    handleBlur,
    errors,
  } = useForm<ResourceFormValues, ResourceFormValidation>(
    defaultFormValues,
    formValidation
  );

  //useeffect to handle success/error on register data
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if(error.message.code === 508){ // Link to video is not valid.
            return alert.error("El link al video no es link válido de Youtube o LinkedIn");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado el recurso con exito", {
          onClose: () => {
            props.history.push(`/${entity}/detail/${id ? +id : 0}`);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, id, loading, props.history]);

  useEffect(() => {
    if( entity === 'productlines') {
      if (getProductLineDetail.data)
        setLoad(false);
    }else{
      if (getSuccessCaseDetail.data) {
        setLoad(false);
      }
    }
  }, [entity,getProductLineDetail.data, getProductLineDetail.loading, getSuccessCaseDetail.loading, getSuccessCaseDetail.data]);
  
  //submit register data to backend
  const submitForm = () => {
    let Resourcedata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields before focus
    if (typeSelected.value === "VIDEO") {
      if (errors.name || errors.link) {
        errorsFound = true;
      }
      if (values.name === "") {
        emptyField = true;
      }else if(videoTypeSelected.value === "LINK" && values.link === ""){
        emptyField = true;
      }else if(videoTypeSelected.value === "UPLOAD" && values.video === ""){
        emptyField = true;
      }
    } else {
      if (errors.name || errors.image || errors.file) {
        errorsFound = true;
      }
      if (values.name === "" || values.image === "" || values.file === "") {
        emptyField = true;
      }
    }
    if (!description) {
      emptyField = true;
    }
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (typeSelected.value === " ") {
        alert.error("Seleccione un tipo de recurso...");
      }
    } else {
      setDisabledButton(true);
      try {
        if( entity === "productlines"){

          Resourcedata = {
            name: values.name,
            description: description,
            path: values.link,
            productLineId: id ? +id : 0,
            successCaseId: null,
            type: typeSelected.value,
          };
        }else {
          Resourcedata = {
            name: values.name,
            description: description,
            path: values.link,
            productLineId: null,
            successCaseId: id ? +id : 0,
            type: typeSelected.value,
          };
        }
        createResource({
          variables: {
            data: Resourcedata,
            image: image ? image : "",
            pdf: quoteTemplate ? quoteTemplate : "",
            video: video ? video : "",
          },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleTypeChange = (type: any) => {
    setTypeSelected(type);
  };

  const handleVideoTypeChange = (type: any) => {
    setVideoTypeSelected(type);
  };

  return (
    <Consumer>
      {(context) =>
    logOut === true ? (
      context.setLogged(false)
    ):(
    <div className="content-container">
      {!load ? (
        <div className="mainContainer" style={{ marginTop: "2rem" }}>
          <div className="register-container">
            <h2>Agregar Recurso</h2>
            <Form>
              <div className="input-container">
                <FormField
                  type="text"
                  label="Nombre"
                  required={{
                    required: true,
                    requiredMsg: "Por favor ingresa el nombre del recurso.",
                  }}
                  name="name"
                  value={values.name}
                  inputElAttributes={{
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  error={pressedButton && errors.name}
                />
                {
                  <FormField
                    type="text"
                    label="Línea de Producto"
                    name="line"
                    value={ entity == "productlines" ? 
                              getProductLineDetail.data.productLine.name   :
                              getSuccessCaseDetail.data.successCase.name
                    }
                    inputElAttributes={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      disabled: true,
                    }}
                    error={pressedButton && errors.line}
                  />
                }
                    
                <Select
                  options={typeOptions}
                  value={typeSelected}
                  onChange={handleTypeChange}
                />
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={description}
                    onChange={handleTextAreaChange}
                  />
                </Form.Group>

                {typeSelected.value === "PDF" ||
                typeSelected.value === "PDF_QUOTE_TEMPLATE" ? (
                  <>
                    <FormField
                      type="file"
                      label="Imagen"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa una imagen válida.",
                      }}
                      name="image"
                      value={values.image}
                      inputElAttributes={{
                        onChange: (ev) => {
                          if (ev.target.files) setImage(ev.target.files[0]);
                          handleChange(ev);
                        },
                        onBlur: handleBlur,
                        accept: "image/png,jpg",
                      }}
                      error={pressedButton && errors.image}
                    />

                    <FormField
                      type="file"
                      label="PDF"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un archivo válido.",
                      }}
                      name="file"
                      value={values.file}
                      inputElAttributes={{
                        onChange: (ev) => {
                          if (ev.target.files)
                            setQuoteTemplate(ev.target.files[0]);
                          handleChange(ev);
                        },
                        onBlur: handleBlur,
                        accept: "application/pdf",
                      }}
                      error={pressedButton && errors.file}
                    />
                  </>
                ) : typeSelected.value === "VIDEO" ? (
                  <>
                    <Select
                      styles={{
                        menu: provided => ({...provided, zIndex: 9999}),
                        menuPortal: provided => ({...provided, zIndex: 9999})
                      }}
                      menuPortalTarget={document.body}
                      options={videoTypeOptions}
                      value={videoTypeSelected}
                      onChange={handleVideoTypeChange}
                    />
                    <br></br>
                    {videoTypeSelected.value === "LINK" ? (
                      <FormField
                        type="text"
                        label="Enlace"
                        required={{
                          required: true,
                          requiredMsg: "Por favor ingresa el enlace al video.",
                        }}
                        name="link"
                        value={values.link}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        error={pressedButton && errors.link}
                      />
                    ) : videoTypeSelected.value === "UPLOAD" ? (
                      <FormField
                        type="file"
                        label="Video"
                        required={{
                          required: true,
                          requiredMsg: "Por favor ingresa un video válido.",
                        }}
                        name="video"
                        value={values.video}
                        inputElAttributes={{
                          onChange: (ev) => {
                            if (ev.target.files)
                              setVideo(ev.target.files[0]);
                            handleChange(ev);
                          },
                          onBlur: handleBlur,
                          accept: "video/mp4",
                        }}
                        error={pressedButton && errors.video}
                      />
                    ) : null }
                  </>
                ) : null}
              </div>
              <div className="button-span">
                <Button
                  type="primary"
                  elementAttr={{ type: "button", disabled: disabledButton }}
                  clickEvent={submitForm}
                  size="default"
                >
                  {!loading ? (
                    "Registrar"
                  ) : (
                    <div
                      className="spinner-border spinner-border-sm text-primary"
                      role="status"
                    ></div>
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      ) : null}
    </div>
    )
                  }
    </Consumer>
  );
};

export default RegisterResource;
