import { createStyles, Divider, IconButton, InputBase, makeStyles, Paper, Theme } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import React, { FunctionComponent, useState } from 'react'
import { useApolloClient, useLazyQuery, useMutation, useQuery, useSubscription } from 'react-apollo';
import { ChatFeed } from 'react-chat-ui'
import { CHAT_SUBSCRIPTION, getSingleChat, sendMessages } from '../../../../../Util/Queries/Queries';
import { sleep } from '../../../../../Util/utils';
import useChatContext from '../../Context/ChatContext';
import './Messages.scss'


const inputStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '98%',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

const query = gql`
  query {
    user {
      id
      role
      firstName
      lastName
      email
    }
  }
`;

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}



export const Messages: FunctionComponent = () => {

    const classes = inputStyles();
    const { messages, updateMessages, clearMessages } = useChatContext().messageCache
    const { contact } = useChatContext().currentChat
    const [sendMessageMutation, send] = useMutation(sendMessages)
    const [text, setText] = useState<string>('');
    const client = useApolloClient()
    const forceUpdate = useForceUpdate();
    const [load, reload] = useState(true)


    const user = useQuery(query, {
        fetchPolicy: 'network-only'
    })

    const [getChat, { data, loading }] = useLazyQuery(getSingleChat,
        {
            fetchPolicy: 'network-only',
            onCompleted: () => {
                const msg_array = data.getSingleChat.messages;
                msg_array.forEach((el: any) => {
                    const local = el.sentBy !== "USER"
                    updateMessages(local, el.message)
                })
                setText('hello world')
                sleep(5).then(() => setText(''))
            }
        })


    React.useEffect(() => {
        if (loading) return
        if (contact.id === 0) return
        if (contact.status === 'CLOSE') return
        const observer = client.subscribe({
            query: CHAT_SUBSCRIPTION,
            variables: {
                chat_id: Number.parseFloat(`${contact.id}`),
                email: contact.mail
            }
        })
        const subscription = observer.subscribe(({ data }) => {
            const local = data.sendMessage.sentBy !== "USER"
            updateMessages(local, data.sendMessage.message)
            setText(' ')
            setText('')
        })

        return () =>{
            subscription.unsubscribe()
        } 

    }, [loading, contact.status])

    React.useEffect(() => {
        if (contact.id === 0) return
        if (messages.length > 0) {
            clearMessages()
            setText('hello world')
            reload(false)
            sleep(10).then(() => {
                setText('')
                reload(true)
            })
            forceUpdate()
        }
        getChat({
            variables: {
                chat_id: Number.parseFloat(`${contact.id}`)
            },

        })
    }, [contact.id])


    const sendMessage = () => {
        if (text === '') return
        sendMessageMutation({
            variables: {
                message: text,
                email: contact.mail,
                chat_id: Number.parseFloat(`${contact.id}`)
            }
        })
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    return (
        <div className="message-container">
            <div className="messages">
                {
                    load &&
                    <ChatFeed
                        messages={messages} // Array: list of message objects
                        isTyping={false} // Boolean: is the recipient typing
                        hasInputField={false} // Boolean: use our input, or use your own
                        showSenderName // show the name of the user who sent the message
                        bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                        // JSON: Custom bubble styles
                        maxHeight={560}
                        bubbleStyles={
                            {
                                chatbubble: {
                                    marginRight: 14,
                                    marginLeft: 14,
                                    backgroundColor: 'rgb(177, 177, 177)',
                                },
                                userBubble: {
                                    backgroundColor: '#00BDD4'
                                }
                            }
                        }
                    />
                }
            </div>
            <div className="input-container">
                <Paper variant="outlined" className={classes.root}>
                    <InputBase
                        disabled={contact.status !== 'OPEN'}
                        className={classes.input}
                        placeholder="Escribe algo..."
                        inputProps={{
                            'aria-label': 'escribe algo',
                            onKeyDown: handleKeyDown,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setText(e.target.value)
                            },
                        }}
                        value={text}
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton disabled={contact.status !== 'OPEN'} color="primary" className={classes.iconButton} aria-label="directions" onClick={sendMessage}>
                        <SendIcon />
                    </IconButton>
                </Paper>
            </div>
        </div>
    )
}