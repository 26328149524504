import { Avatar } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ReactComponent as MessageIcon } from '../../../../../assets/Icons/mail.svg'
import { ReactComponent as NumerIcon } from '../../../../../assets/Icons/number.svg'
import { ReactComponent as CompanyIcon } from '../../../../../assets/Icons/Company.svg'
import './ContactInfo.scss'
import useChatContext from '../../Context/ChatContext'
import { useMutation, useSubscription } from 'react-apollo'
import { closeChats, openChat, CHAT_SUBSCRIPTION } from '../../../../../Util/Queries/Queries'


export const ContactInfo: FunctionComponent = () => {

    const { contact, changeContact } = useChatContext().currentChat

    const [startChat, start] = useMutation(openChat);
    const [finishChat, finish] = useMutation(closeChats);


    const [state, setState] = useState(contact.status)

    React.useEffect(() => {
        setState(contact.status)
    }, [contact])


    const getButtonText = () => {

        switch (state) {
            case 'OPEN':
                return 'Marcar como resuelto'
            case 'CLOSE':
                return 'Chat cerrado'
            case 'PENDING':
                return 'Iniciar chat'
        }
    }

    const buttonAction = () => {
        switch (contact.status) {
            case 'OPEN':
                finishChat({
                    variables: {
                        chat_id: Number.parseFloat(`${contact.id}`)
                    }
                })
                setState('CLOSE')
                changeContact({ ...contact, status: 'CLOSE' })
                break
            case 'PENDING':
                startChat({
                    variables: {
                        chat_id: Number.parseFloat(`${contact.id}`)
                    }
                })
                    .then(value => { })
                    .catch(e => console.log(e.networkError))
                setState('OPEN')
                changeContact({ ...contact, status: 'OPEN' })
                break
        }
    }

    return (
        <div className="contact-container">
            <div className="first-container">
                <div className="avatar-container">
                    <Avatar className="avatar"></Avatar>
                    <h2 className="title">{contact.name}</h2>
                </div>
                <div className="contact-info">
                    <div className="text-container">
                        <MessageIcon className="icon" />
                        <h4 className="contact-text">{contact.mail}</h4>
                    </div>
                    <div className="text-container">
                        <NumerIcon className="icon" />
                        <h4 className="contact-text">{contact.phone}</h4>
                    </div>
                    <div className="text-container">
                        <CompanyIcon className="icon" />
                        <h4 className="contact-text">{contact.company}</h4>
                    </div>
                </div>
            </div>
            <div className="action-container">
                <div className="action-title">
                    <p className="title">
                        Acciones del chat
                    </p>
                </div>
                <div className="button-container">
                    <button onClick={buttonAction} disabled={state === 'CLOSE' || state === ''} className={`${(state === 'CLOSE' || state === '') && 'disabled'}`}>{getButtonText()}</button>
                </div>
            </div>
        </div>
    )
}