import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import FormField from "../../Atoms/FormField";
import {Button as Bbutton} from 'react-bootstrap'
import Button from "../../Atoms/Button";
import { 
    getIndustries,
    getIndustry as query,
 } from "../../../Util/Queries/Queries";
 import { editIndustry } from "../../../Util/Queries/Mutations";


 export interface IndustryDetailValidations {
    name: TFieldValidation;
  }
  export interface IndustryDetailForm {
    name: string;
    image: string;
  }

const IndustryDetail: React.FC<{ history: History }> = (props) => {
    //hooks
    const [edit, setedit] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [editindustry, { loading, data, error }] = useMutation(editIndustry);
    const [logOut, setLogOut] = useState<boolean>(false);
    const [disabledButton, setDisabledButton] = useState<boolean>(false)
    const [deleted, setDeleted] = useState<boolean>(false);
    const [image, setImage] = useState<any>();

    //const
    const alert = useAlert();
    const { id } = useParams();
    //Apollohooks
    const getIndustry = useQuery(query, {
      variables: { id: id ? +id : 0 },
      fetchPolicy:"network-only",
      notifyOnNetworkStatusChange: true,
    });
  
    const defaultFormValues: IndustryDetailForm = {
        name: "",
        image: "",
    };
  
    const formValidation: IndustryDetailValidations = {
      name: "not-empty"
    };
  
    const updateCache = (industry: any) => {
      const data = industry.readQuery({
        query: getIndustries,
      });
      const newData = {
        industries: data.industries.filter((t: any) => t.id !== id),
      };
      industry.writeQuery({
        query: getIndustries,
        data: newData,
      });
    };
  
    //Main useEffect fetching data to fill form
    useEffect(() => {
      if (!getIndustry.loading) {
        if (getIndustry.data) {
            console.log(getIndustry.data)

            if(getIndustry.data.industry.image !== null){
              setImage(getIndustry.data.industry.image)
            }else { 
              setImage("")
            }
            forceUpdateValue({
                name: getIndustry.data.industry.name,
                image: ""
            });
        }
        if (getIndustry.error) {
          getIndustry.error.graphQLErrors.map((error: any) => {
            if (error.message.statusCode === 401) {
              setLogOut(true);
              return alert.error("Su sesion ha caducado");
            } else {
              return alert.error("Ha ocurrido un error");
            }
          });
        }
      }
    }, [getIndustry.loading]);
  
    const {
      handleChange,
      values,
      handleBlur,
      errors,
      forceUpdateValue,
    } = useForm<IndustryDetailForm,IndustryDetailValidations>(
      defaultFormValues,
      formValidation
    );
  
    const submitForm = () => {
      let industryData:any;
      let errorsFound: boolean = false;
      let emptyField: boolean = false;
      //validations provided by Formfield component, also validates empty fields after focus
      Object.values(errors).map((item: any) => {
        if (item === true) {
          return errorsFound = true;
        }
        return null
      });
      if (errorsFound || emptyField ) {
        if (errorsFound) {
          alert.error("Por favor llena todos los campos en el formato indicado");
        }
        if (emptyField) {
          alert.error("No puede dejar campos vacios");
        }
      } else {
        setDisabledButton(true)
        try {
            industryData = {
                name: values.name,
            };
            editindustry({ 
              variables: { id: id ? +id : 0, 
                data: industryData, 
                image: image, 
              } }).catch((error) => {
                console.log(error);
            });
        } catch (error) {
          alert.error("Ha ocurrido un error");
          setDisabledButton(false)
        }
      }
    };
  
    const performDelete = () => {
      setDeleted(true);
      setDisabledButton(true)
      let dataModified: any = {
        status: "INACTIVE",
        name: getIndustry.data.industry.name
      };
      editindustry({
        variables: {
          id: id ? +id : 0,
          data: dataModified,
          image: image
        },
        update: updateCache,
      });
      setModal(false);
    };
  
    //UseEffects to handle success/error on mutation event
    useEffect(() => {
      if (!loading) {
        if (error) {
          console.log(error)
          setDisabledButton(false)
          error.graphQLErrors.map((error: any) => {
            if (error.message.statusCode === 401) {
              setLogOut(true);
              return alert.error("Su sesion ha caducado");
            } else {
              return alert.error("Ha ocurrido un error");
            }
          });
        }
        if (data) {
          var msg = "";
          if (deleted) {
            msg = "Se ha eliminado la industria con exito";
          } else {
            msg = "Se ha modificado la industria con exito";
          }
          alert.success(msg, {
            onClose: () => {
              props.history.push(`/industries`);
            },
          });
        }
      }
    }, [loading]);
  
    return (
      <Consumer>
        {(context) =>
          logOut === true ? (
            context.setLogged(false)
          ) : !getIndustry.loading ? (
            getIndustry.data ? (
                <div className="content-container">
                    <ConfirmationModal
                        show={modal}
                        onHide={() => setModal(false)}
                        onCancel={() => setModal(false)}
                        clickEvent={performDelete}
                        message={
                        <div>
                            Está seguro que desea eliminar esta industria?
                            <br /> Esta acción no se puede revertir...
                        </div>
                        }
                        action="Eliminar Industria"
                        type="delete"
                    />
                  <div className="mainContainer">
                    <div className="buttonspan">
                        <label></label>
                            {
                            edit?
                                <Bbutton
                                    size="sm"
                                    variant="light"
                                    className="backButton"
                                    onClick={()=>{setedit(false)}}
                                >
                                    <div className="backIcon"></div>
                                </Bbutton>
                            :null
                            }
                        <div className="buttonCustom">
                            <Button
                                type="danger"
                                elementAttr={{ type: "button",disabled:disabledButton }}
                                clickEvent={() => {
                                setModal(true);
                                }}
                                size="default"
                            >
                                {"Eliminar"}
                            </Button>
                            {edit ? (
                                <Button
                                type="primary"
                                elementAttr={{ type: "button",disabled:disabledButton }}
                                clickEvent={submitForm}
                                size="default"
                                >
                                {"Guardar"}
                                </Button>
                            ) : (
                                <Button
                                type="primary"
                                elementAttr={{ type: "button" }}
                                clickEvent={() => {
                                    setedit(true);
                                }}
                                size="default"
                                >
                                {"Editar"}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="detailcontainer">
                      <h2>{edit ? "Editar Industria" : "Detalles de Industria"}</h2>
                      <div className="input-container">
                        <FormField
                            type="text"
                            label="Nombre"
                            disabled={!edit}
                            required={{
                                required: true,
                                requiredMsg: "Por favor ingresa un nombre.",
                            }}
                            inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                            }}
                            name="name"
                            value={values.name}
                        />
                        {!edit ? (
                          image != "" &&
                          <FormField
                            type="image"
                            label="Imagen"
                            name="image"
                            value="Imagen"
                            link={image}
                          />
                        ):(
                          <FormField
                            type="file"
                            label="Selecciona una imagen"
                            required={{
                              required: false,
                              requiredMsg:
                                "Por favor selecciona una imagen válida.",
                            }}
                            name="image"
                            value={values.image}
                            inputElAttributes={{
                              onChange: (ev) => {
                                if (ev.target.files)
                                  setImage(ev.target.files[0]);
                                handleChange(ev);
                              },
                              onBlur: handleBlur,
                              accept: "image/png,jpg",
                            }}
                          />
                        )}
                      </div>
                      <div className="buttonspan"></div>
                    </div>
                  </div>
                </div>
            ) : null
          ) : (
            <div className="load">
              <div className="dot"></div>
              <div className="outline">
                <span></span>
              </div>
            </div>
          )
        }
      </Consumer>
    );
  };

export default IndustryDetail;