/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Consumer } from "../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../Util/Hooks/useForm";
import { EditUserLogged } from "../../Util/Queries/Mutations";
import { getUserLogged as query } from "../../Util/Queries/Queries";
import Button from "../Atoms/Button";
import FormField from "../Atoms/FormField";

export interface UserDetailValidations {
  firstName: TFieldValidation;
  lastName: TFieldValidation;
  email: TFieldValidation;
  phoneNumber: TFieldValidation;
  department?: TFieldValidation;
  function?: TFieldValidation;
  createdAt?: TFieldValidation;
  status?: TFieldValidation;
  oldPassword: TFieldValidation;
  newPassword: TFieldValidation;
}
export interface UserDetailForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  department?: string;
  function?: string;
  createdAt: string;
  status: string;
  plantPortfolios?: string[];
  oldPassword: string;
  newPassword: string;
}

const Profile: React.FC<any> = (props) => {
  //hooks
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [edituser, { loading, data, error }] = useMutation(EditUserLogged);
  const [logOut, setLogOut] = useState<boolean>(false);
  //const
  const alert = useAlert();
  //Apollohooks
  const getUser = useQuery(query, { fetchPolicy: "network-only" });

  //formvalues

  const defaultFormValues: UserDetailForm = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    department: "",
    function: "",
    createdAt: "",
    oldPassword: "",
    newPassword: "",
    status: "",
    plantPortfolios: [],
  };

  const formValidation: UserDetailValidations = {
    firstName: "not-empty",
    lastName: "not-empty",
    email: "email",
    phoneNumber: "number",
    department: "not-empty",
    function: "not-empty",
    createdAt: "not-empty",
    status: "not-empty",
    oldPassword: "not-empty",
    newPassword: "not-empty",
  };

  //useEffect to fetch user logged data and handle errors
  useEffect(() => {
    if (!getUser.loading) {
      if (getUser.data) {
        forceUpdateValue({
          firstName: getUser.data.user.firstName,
          lastName: getUser.data.user.lastName,
          email: getUser.data.user.email,
          phoneNumber: getUser.data.user.phoneNumber
            ? getUser.data.user.phoneNumber
            : "",
          department: getUser.data.user.department
            ? getUser.data.user.department
            : "",
          function: getUser.data.user.function
            ? getUser.data.user.function
            : "",
          createdAt: getUser.data.user.createdAt,
          status: getUser.data.user.status,
          oldPassword: "",
          newPassword: "",
        });
      }
      if (getUser.error) {
        getUser.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getUser.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    forceUpdateValue,
  } = useForm<UserDetailForm, UserDetailValidations>(
    defaultFormValues,
    formValidation
  );

  //submit changes to backend
  const submitForm = () => {
    let dataModified: any = {};
    for (let i in values) {
      //@ts-ignore
      if (i !== "__typename" && values[i].length > 0) {
        //@ts-ignore
        if (getUser.data.user[i] !== values[i])
          //@ts-ignore
          dataModified[i] = values[i];
      }
    }
    if (isNaN(+values.phoneNumber)) {
      alert.show("El telefono debe ser numerico");
    } else if (values.phoneNumber.length !== 10) {
      alert.show("El telefono debe contener 10 digitos");
    } else {
      edituser({
        variables: {
          id: +getUser.data.user.id,
          data: dataModified,
        },
      });
    }
  };

  //useEffect that handles success/error on modified data
  useEffect(() => {
    if (!loading) {
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.code === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 475) {
            return alert.error("La contraseña actual no es correcta");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se han guardado los cambios")
        forceUpdateValue({
          firstName: getUser.data.user.firstName,
          lastName: getUser.data.user.lastName,
          email: getUser.data.user.email,
          phoneNumber: getUser.data.user.phoneNumber
            ? getUser.data.user.phoneNumber
            : "",
          department: getUser.data.user.department
            ? getUser.data.user.department
            : "",
          function: getUser.data.user.function
            ? getUser.data.user.function
            : "",
          createdAt: getUser.data.user.createdAt,
          status: getUser.data.user.status,
          oldPassword: "",
          newPassword: "",
        });
      }
    }
  }, [loading]);

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getUser.loading ? (
          getUser.data ? (
            <div className="content-container">
              <div className="mainContainer">
                <div className="buttonspan">
                  <label></label>
                  <Button
                    type="primary"
                    elementAttr={{ type: "button" }}
                    clickEvent={submitForm}
                    size="default"
                  >
                    {"Guardar"}
                  </Button>
                </div>
                <div className="detailcontainer">
                  <h2>Mi Perfil</h2>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      name="firstName"
                      value={values.firstName}
                    />
                    <FormField
                      type="text"
                      label="Apellido"
                      name="lastName"
                      value={values.lastName}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                    />
                    <FormField
                      type="text"
                      label="Correo"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un correo válido.",
                      }}
                      name="email"
                      value={values.email}
                      disabled={true}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        disabled: true,
                      }}
                    />
                    <FormField
                      type="text"
                      label="Telefono"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                    />
                    {getUser.data.user.role === "USER_CLIENT" ? (
                      <>
                        <FormField
                          type="text"
                          label="Departamento"
                          name="department"
                          //@ts-ignore
                          value={values.department}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                        <FormField
                          type="text"
                          label="Funcion"
                          name="function"
                          //@ts-ignore
                          value={values.function}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                        />
                      </>
                    ) : null}
                    <FormField
                      type="text"
                      label="Rol"
                      name="Rol"
                      value={
                        getUser.data.user.role === "ADMIN"
                          ? "Administrador"
                          : getUser.data.user.role === "USER_CLIENT"
                          ? "Cliente"
                          : getUser.data.user.role === "DSO"
                          ? "DSO"
                          : "Vendedor"
                      }
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        disabled: true,
                      }}
                    />
                  </div>
                  <FormField
                    type="text"
                    label="Actual Contraseña"
                    name="oldPassword"
                    classVariant="password"
                    value={values.oldPassword}
                    inputElAttributes={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      type: "password",
                    }}
                  />
                  <FormField
                    type="password"
                    label="Contraseña Nueva"
                    name="newPassword"
                    show={() => {
                      setShowPassword(!showPassword);
                    }}
                    value={values.newPassword}
                    inputElAttributes={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      type: showPassword === false ? "password" : "",
                    }}
                  />
                  <div className="button-span"></div>
                </div>
              </div>
            </div>
          ) : null
        ) : null
      }
    </Consumer>
  );
};

export default Profile;
