import { useLazyQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState, useCallback } from "react";
import { useAlert } from "react-alert";
import { FormControl, InputGroup, Pagination, Spinner } from "react-bootstrap";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getDistributors } from "../../Util/Queries/Queries";
import Button from "../Atoms/Button";
import Table, { Row } from "../Atoms/Table/Table";
import "./styles.scss";

const Distributors: React.FC<{ history: History }> = (props) => {
  const [load, setLoad] = useState<boolean>(true);
  const [array, setArray] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [active, setActive] = useState<number>(1);
  //Getquery hook
  const [exec, { loading, error, data }] = useLazyQuery(getDistributors, {
    fetchPolicy: "network-only",
  });
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);
  const [paginationItems, setpaginationItems] = useState<any>([]);
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const rows: any = [];


  //pagination and search functions

  const handleClick = useCallback((page: any) => {
    setActive(page);
    exec({
      variables: { filter: { pagination: { currentPage: page, limit: 10 } } },
    });
  },[exec]);
   //funciton that fills pagination component
   const fillPagination = useCallback(
    () => {
      let items: any = [];
    setTotalPages(data.distributors.pagination.totalPages);
    for (let number = active - 4; number < active; number++) {
      if (number >= 1) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    for (let number = active; number <= active + 4; number++) {
      if (number <= data.distributors.pagination.totalPages) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    setpaginationItems(items);
    setPaginationLoad(true);
    },
    [active, data, handleClick],
  )

  const performSearch = (e: any) => {
    exec({
      variables: {
        filter: {
          search: {
            value: e,
            fields: ["name", "phoneNumber", "address"],
          },
          pagination: {
            currentPage: 1,
            limit: 10,
          },
        },
      },
    });
  };
  const debounce = (func: any, wait: number, e: any) => {
    let timeout: any;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(e);
    }, wait);
  };
  const handleSearchChange = (e: any) => {
    debounce(performSearch, 400, e.target.value);
  };

  //endpagination and search
  //load table content
  const createtable = useCallback(
    () => {
      var arr: any = [];
      data.distributors.items.map((distributor: any) => {
        arr = [...arr, distributor];
        const row: Row = {
          tds: [
            {
              valor: distributor.id,
            },
            {
              valor: distributor.name,
            },
            {
              valor: distributor.address,
            },
            {
              valor: distributor.phoneNumber,
            },
            /*{
              valor: distributor.plantPortfolio? (
                distributor.plantPortfolio.name
              ) : "",
            },*/
            {
              valor: distributor.parentDistributor? (
                distributor.parentDistributor.name
              ) : "",
            },
            {
              valor: distributor.salesmanDirector? (
              `${distributor.salesmanDirector.firstName} ${distributor.salesmanDirector.lastName}`
              ) : "",
            },
          ],
        };
        return rows.push(row);
      });
      const a = {
        headers: [
          "ID", 
          "NOMBRE", 
          "DIRECCION", 
          "TELEFONO", 
          // "PORTAFOLIO",
          "DISTRIBUIDOR PADRE",
          "VENDEDOR DIRECTOR",
        ],
        rows: rows,
      };
  
      setArray(a);
      setLoad(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const renderdetails = (distributor: Row) => {
    props.history.push(`/distributors/detail/${distributor.tds[0].valor}`);
  };

  //main Useeffect that retreives data from backend, and handles errors, if it success fet ching data it fills pagination, and table components
  useEffect(() => {
    if (!loading) {
      if (data) {
        setShowLoader(false);
        fillPagination();
        createtable();
      }
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesión ha caducado");
          } else {
            return errores.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [createtable, data, error, errores, fillPagination, loading]);
  //useEffect that executes the lazyquery on component mount
  useEffect(() => {
    exec({
      variables: { filter: { pagination: { currentPage: 1, limit: 10 } } },
    });
  }, [exec]);

  

 
  return (
    <Consumer>
      {(context) => (
        <div className="Distributors">
          {mainPage === true ? (
            context.setLogged(false)
          ) : showLoader ? (
            <div className="load">
              <div className="dot"></div>
              <div className="outline">
                <span></span>
              </div>
            </div>
          ) : load === false ? (
            //If data is already loaded
            <div className="table-display">
              <div className="title-span">
                <h3>Distribuidores Registrados</h3>
              </div>
              <div className="btnspan">
                <InputGroup>
                  <FormControl
                    placeholder="Buscar..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="search-bar"
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      type="primary"
                      elementAttr={{ type: "button" }}
                      size="default"
                      clickEvent={() => {
                        props.history.push("/registerdistributor");
                      }}
                    >
                      {"Crear Distribuidor"}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              <div className="center-table">
                {loading ? (
                  <div className="spinnerSpan">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Table
                    headers={array.headers}
                    rows={array.rows}
                    type="distributor"
                    onrowclick={renderdetails}
                  />
                )}
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                      <Pagination.First onClick={() => handleClick(1)} />
                      <Pagination.Prev
                        key={active - 1}
                        onClick={() => handleClick(active - 1)}
                      />
                    </>
                  ) : null}
                  {paginationItems}
                  {active < totalPages ? (
                    <>
                      <Pagination.Next
                        key={active + 1}
                        onClick={() => handleClick(active + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handleClick(totalPages)}
                      />
                    </>
                  ) : null}
                </Pagination>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </Consumer>
  );
};

export default Distributors;
