/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton } from 'react-bootstrap';
import { Link, Redirect, useParams } from "react-router-dom";
import Select from "react-select";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editPortfolio } from "../../../Util/Queries/Mutations";
import {
  getPortfolioById as query,

  getPortfolios, getUsers
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import Table, { Row } from "../../Atoms/Table/Table";

export interface PortfolioDetailValidations {
  name: TFieldValidation;
  salesman: TFieldValidation;
}
export interface PortfolioDetailForm {
  name: string;
  salesman: string;
}

const PortfolioDetail: React.FC<any> = (props) => {
  //hooks
  const [success, setSuccess] = useState<boolean>(false);
  const [edit, setedit] = useState<boolean>(false);
  const [plantsArray, setPlantsArray] = useState<any>([]);
  const [distributorsArray, setDistributorsArray] = useState<any>([]);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [usersOptions, setUsersOptions] = useState<any>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [editportfolio, { loading, data, error }] = useMutation(editPortfolio, {
    update: (cache, { data: { editPortfolio } }) => {
      const { portfolios }: any = cache.readQuery({ query: getPortfolios });
      const newPortfolios = [...portfolios, editPortfolio];
      cache.writeQuery({
        query: getPortfolios,
        data: { portfolios: newPortfolios },
      });
    },
  });
  const listUsers = useQuery(getUsers,{fetchPolicy:"network-only"});
  const [salesmanSelected, setSalesmanSelected] = useState({
    label: "Selecciona un nuevo vendedor",
    value: "",
  });
  const [load, setLoad] = useState<boolean>(false);
  //const
  const alert = useAlert();
  const { id } = useParams();
  const plantsTableRows: any = [];
  const distributorTableRows: any = [];
  //Apollohooks
  const getPortfolio = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy:"network-only"
  });
  //formvalues

  const defaultFormValues: PortfolioDetailForm = {
    name: "",
    salesman: "",
  };

  const formValidation: PortfolioDetailValidations = {
    name: "not-empty",
    salesman: "not-empty",
  };

  useEffect(() => {
    if (!listUsers.loading) {
      if (listUsers.data) {
        let arr: any = [];
        listUsers.data.users.items.map((usuario: any) => {
          if (usuario.role === "SALESMAN") {
            return arr = [
              ...arr,
              {
                value: usuario.id,
                label: usuario.firstName + " " + usuario.lastName,
              },
            ];
          }
          return null
        });
        setUsersOptions(arr);
      }
    }
  }, [listUsers.loading]);

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getPortfolio.loading) {
      if (getPortfolio.data) {
        createTables();
        forceUpdateValue({
          name: getPortfolio.data.portfolio.name,
          salesman: getPortfolio.data.portfolio.salesman
            ? getPortfolio.data.portfolio.salesman.firstName +
              " " +
              getPortfolio.data.portfolio.salesman.lastName
            : "",
        });
      }
      if (getPortfolio.error) {
        getPortfolio.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getPortfolio.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<PortfolioDetailForm, PortfolioDetailValidations>(
    defaultFormValues,
    formValidation
  );



  const submitForm = async () => {
    let Portfoliodata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    if(values.name===""){
      emptyField=true
    }
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true)
      try {
        if(salesmanSelected.value===""){
          Portfoliodata = {
          name: values.name
        };
      }
        else{
          Portfoliodata = {
            name: values.name,
            salesmanID:salesmanSelected.value
          };
        }
        await editportfolio({ variables: {id:id?+id:0, data: Portfoliodata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false)
      }
    }
  };
  //fill plants what belong to this portfolio
  const createTables = () => {
    var plantArr: any = [];
    getPortfolio.data.portfolio.plants.map((plant: any) => {
      plantArr = [...plantArr, plant];
      const row: Row = {
        tds: [
          {
            valor: plant.id,
          },
          {
            valor: plant.client.name,
          },
          {
            valor: plant.name,
          },
          {
            valor: plant.contactNumber,
          },
        ],
      };
      return plantsTableRows.push(row);
    });
    const plants = {
      headers: ["ID", "EMPRESA", "PLANTA", "TELEFONO"],
      rows: plantsTableRows,
    };

    var distributorArr: any = [];
    getPortfolio.data.portfolio.distributors.map((distributor: any) => {
      distributorArr = [...distributorArr, distributor];
      const row: Row = {
        tds: [
          {
            valor: distributor.id,
          },
          {
            valor: distributor.name,
          },
          {
            valor: distributor.address,
          },
          {
            valor: distributor.phoneNumber,
          },
        ],
      };
      return distributorTableRows.push(row);
    });
    const distributors = {
      headers: ["ID", "NOMBRE", "DIRECCIÓN", "TELEFONO"],
      rows: distributorTableRows,
    };
    setLoad(true);
    setPlantsArray(plants);
    setDistributorsArray(distributors);
  };
  const handleSelectChange = (option: any) => {
    setSalesmanSelected(option);
  };
  //Handle table row click
  const handlePlantRowClick = (plant: Row) => {
    props.history.push(`/plants/detail/${plant.tds[0].valor}`);
  };
  const handleDistributorRowClick = (distributor: Row) => {
    props.history.push(`/distributors/detail/${distributor.tds[0].valor}`);
  };
  //UseEffect to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false)
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
         alert.success(
          "Se ha modificado el portafolio con exito",
          {
            onClose: () => {
              setSuccess(true);
            },
          }
        );
      }
    }
  }, [loading]);

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : success ? (
          <Redirect to="/portfolios" />
        ) : !getPortfolio.loading ? (
          getPortfolio.data ? (
            load ? (
              <div className="content-container">
                <div className="mainContainer">
                  <div className="buttonspan">
                    <label></label>
                    {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                    <div className="buttonCustom">
                      {edit ? (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            clickEvent={submitForm}
                            size="default"
                          >
                            {"Guardar"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button" }}
                            clickEvent={() => {
                              setedit(true);
                            }}
                            size="default"
                          >
                            {"Editar"}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="detailcontainer">
                    <h2>
                      {edit ? "Editar Portafolio" : "Detalles de Portafolio"}
                    </h2>
                    <div className="input-container">
                      <FormField
                        type="text"
                        label="Nombre"
                        disabled={!edit}
                        required={{
                          required: true,
                          requiredMsg: "Por favor ingresa un nombre.",
                        }}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="name"
                        value={values.name}
                      />

                      {edit ? (
                        listUsers.data ? (
                          <>
                            <h6>Selecciona un nuevo vendedor</h6>
                            <div className="spacer">
                              <Select
                                value={salesmanSelected}
                                options={usersOptions}
                                onChange={handleSelectChange}
                              />
                            </div>
                          </>
                        ) : null
                      ) : (
                        <>
                          {getPortfolio.data.portfolio.salesman ? (
                            <Link
                              className="form-link"
                              to={
                                "/users/detail/" +
                                getPortfolio.data.portfolio.salesman.id
                              }
                              style={{textDecoration:"none"}}
                            >
                              <FormField
                                type="text"
                                label="Vendedor"
                                name="Salesman"
                                disabled={!edit}
                                value={values.salesman}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Link>
                          ) : (
                            <FormField
                              type="text"
                              label="Vendedor"
                              name="Salesman"
                              value={values.salesman}
                              disabled={!edit}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                            />
                          )}
                          <h6>Plantas del portfolio</h6>
                          <Table
                            headers={plantsArray.headers}
                            rows={plantsArray.rows}
                            type="portfolios"
                            onrowclick={handlePlantRowClick}
                          />
                          <h6>Distribuidores del portfolio</h6>
                          <Table
                            headers={distributorsArray.headers}
                            rows={distributorsArray.rows}
                            type="portfolios"
                            onrowclick={handleDistributorRowClick}
                          />
                        </>
                      )}
                    </div>
                    <div className="buttonspan"></div>
                  </div>
                </div>
              </div>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default PortfolioDetail;
