import React from 'react';
import './Footer.scss';

const Footer:React.FC<{}>=(props)=> {
    return (

        //Here is intended to contain Garlock contact information to display here
        <footer className="footer">
        </footer>
    );
}

export default Footer;