import React, { useState } from "react";
import { useAlert } from "react-alert";
import Select from "react-select";
import Button from "../Atoms/Button";
import RegisterUser from './Register/RegisterUser';
import "./styles.scss";
const Roleselector: React.FC<{}> = (props: any) => {
  const [option, setOption] = useState({ value: "", label: 'Selecciona un rol' });
  const [redirect, setRedirect] = useState<boolean>(false);
  const erroralert = useAlert();
  const options = [
    { value: "ADMIN", label: "Administrador" },
    { value: "USER_CLIENT", label: "Cliente" },
    { value: "SALESMAN", label: "Vendedor" },
    { value: "DSO", label: "DSO" },
    { value: "AGENT", label: "Agente" }
  ];

  const handleChange = (opcionSeleccionada: any) => {
    setOption(opcionSeleccionada);
  };

  const submitForm = () => {
    if (option.value === "") {
      erroralert.show("Por favor seleccione un rol para el usuario")
    }
    else {
      setRedirect(true);
    }
  };

  return redirect === true ? (
    <RegisterUser type="write" role={option} />
  ) : (
      <div className="selectorform">
        <h3 style={{ paddingBottom: "1rem" }}>Selecciona un rol para el usuario</h3>
        <Select value={option} options={options} onChange={handleChange} />
        <div className="btnalign">
          <Button type="primary" clickEvent={submitForm} size="default">
            {"Continuar"}
          </Button>
        </div>
      </div>
    );
};

export default Roleselector;
