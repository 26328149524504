import React, { useState } from 'react';


export type TDefaultFormValidations = 'not-empty' | 'email' | 'number';

export type TFieldValidation = (() => boolean) | TDefaultFormValidations;

export interface IErrorField {
    [key: string]: boolean
};

/**
 * Returns true if the validation is correct, meaning that there's not error, so it returns false when
 * there's an error
 * @param type 
 * @param value 
 */
const useForm = <T extends any, S extends any>(initialState: T, validations: S) => {
    const [values, setValues] = useState<T>(initialState);
    const [errors, setErrors] = useState<IErrorField>({});

    const defaultValidationsTypes = (type: TDefaultFormValidations | string, value: string): boolean => {
        let errorFound: boolean = false;
        const val = value.length > 0 ? value.trim() : value;
        if (type === 'not-empty') {
            errorFound = !(val.length > 0);
        } else if (type === 'email') {
            errorFound = !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value));
        } else if (type === 'number') {
            errorFound = value.length > 0 ? isNaN(+value) : true;
        }
        return !errorFound;
    }


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [event.target.name]:event.target.value
        })
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.name in validations) {
            if (typeof validations[event.target.name] === 'string') {
                setErrors({
                    ...errors,
                    [event.target.name]: !defaultValidationsTypes(validations[event.target.name], event.target.value)
                });
            } else if (typeof validations[event.target.name] === 'function') {
                setErrors({
                    ...errors,
                    [event.target.name]: !validations[event.target.name]()
                });
            }
        }
    }

    const handleSubmit = () => {
    let tmpErr:IErrorField = {};
        for(const [key, val] of Object.entries(validations)){
            if (typeof val === 'string') {
                tmpErr = {...tmpErr, [key]: !defaultValidationsTypes(val, values[key])};
            } else if (typeof val === 'function') {
                tmpErr = {...tmpErr, [key]: !values[key]()};
            }
        }
        setErrors(tmpErr);
        return tmpErr;
    };


    const forceUpdateValue = (a:T) => {
        setValues({
            ...values,
            ...a
        });
}


    return {
        handleChange,
        handleBlur,
        values,
        validations,
        errors,
        handleSubmit,
        forceUpdateValue
    }
};

export default useForm;