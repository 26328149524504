/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormControl, InputGroup, Pagination, Spinner } from "react-bootstrap";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getProductStyles } from "../../Util/Queries/Queries";
import Button from "../Atoms/Button";
import Table, { Row } from "../Atoms/Table/Table";
import "./styles.scss";

const ProductStyles: React.FC<{ history: History }> = (props) => {
  const [load, setLoad] = useState<boolean>(true);
  const [array, setArray] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [active, setActive] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  //Getquery hook
  const [exec, { loading, error, data }] = useLazyQuery(getProductStyles, {
    fetchPolicy: "network-only",
  });
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);
  const [paginationItems, setpaginationItems] = useState<any>([]);
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const rows: any = [];

  //main Useeffect that retreives data from backend, and handles errors, if it success fet ching data it fills pagination, and table components
  useEffect(() => {
    if (!loading) {
      if (data) {
        setShowLoader(false);
        fillPagination();
        createtable();
      }
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          } else {
            return errores.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);
  //useEffect that executes the lazyquery on component mount
  useEffect(() => {
    exec({
      variables: { filter: { pagination: { currentPage: 1, limit: 10 } } },
    });
  }, []);

  //pagination and search functions

  const handleClick = (page: any) => {
    setActive(page);
    exec({
      variables: { filter: { pagination: { currentPage: page, limit: 10 } } },
    });
  };

  //funciton that fills pagination component

  const fillPagination = () => {
    let items: any = [];
    setTotalPages(data.productStyles.pagination.totalPages);
    for (let number = active - 4; number < active; number++) {
      if (number >= 1) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    for (let number = active; number <= active + 4; number++) {
      if (number <= data.productStyles.pagination.totalPages) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    setpaginationItems(items);
    setPaginationLoad(true);
  };

  const performSearch = (e: any) => {
    exec({
      variables: {
        filter: {
          search: {
            value: e,
            fields: ["name", "description"],
          },
          pagination: {
            currentPage: 1,
            limit: 10,
          },
        },
      },
    });
  };
  const debounce = (func: any, wait: number, e: any) => {
    let timeout: any;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(e);
    }, wait);
  };
  const handleSearchChange = (e: any) => {
    debounce(performSearch, 400, e.target.value);
  };

  //end pagination and search
  //load table content
  const createtable = () => {
    var arr: any = [];
    var temp: any = [];
    data.productStyles.items.map((productStyle: any) => {
      arr = [...arr, productStyle];
      productStyle.products.map((producto: any) => {
        return temp.push(producto.code);
      });
      const row: Row = {
        tds: [
          {
            valor: productStyle.id,
          },
          {
            valor: productStyle.name,
          },
          {
            valor: productStyle.productLine.name,
          },
          {
            valor: productStyle.description,
          },
        ],
      };
      return rows.push(row);
    });
    const a = {
      headers: ["ID", "NOMBRE", "LINEA DE PRODUCTO", "DESCRIPCION"],
      rows: rows,
    };
    setArray(a);
    setLoad(false);
  };
  const renderdetails = (productStyle: Row) => {
    props.history.push(`/productstyles/detail/${productStyle.tds[0].valor}`);
  };

  return (
    <Consumer>
      {(context) => (
        <div className="ProductStyles">
          {mainPage === true ? (
            context.setLogged(false)
          ) : showLoader ? (
            <div className="load">
              <div className="dot"></div>
              <div className="outstyle">
                <span></span>
              </div>
            </div>
          ) : load === false ? (
            //If data is already loaded(
            <div className="table-display">
              <div className="title-span">
                <h3>Estilos de Producto Registrados</h3>
              </div>
              <div className="btnspan">
                <InputGroup>
                  <FormControl
                    placeholder="Buscar..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="search-bar"
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      type="primary"
                      elementAttr={{ type: "button" }}
                      size="default"
                      clickEvent={() => {
                        props.history.push("/registerproductstyle");
                      }}
                    >
                      {"Crear Estilo"}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              <div className="center-table">
                {loading ? (
                  <div className="spinnerSpan">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Table
                    headers={array.headers}
                    rows={array.rows}
                    type="productstyle"
                    onrowclick={renderdetails}
                  />
                )}
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                      <Pagination.First onClick={() => handleClick(1)} />
                      <Pagination.Prev
                        key={active - 1}
                        onClick={() => handleClick(active - 1)}
                      />
                    </>
                  ) : null}
                  {paginationItems}
                  {active < totalPages ? (
                    <>
                      <Pagination.Next
                        key={active + 1}
                        onClick={() => handleClick(active + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handleClick(totalPages)}
                      />
                    </>
                  ) : null}
                </Pagination>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </Consumer>
  );
};

export default ProductStyles;
