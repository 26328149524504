/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { getInstalledProductById as query, getResponsibleById, getUserById } from "../../../Util/Queries/Queries";
import FormField from "../../Atoms/FormField";

export interface InstalledProductDetailValidations {
  code: TFieldValidation;
  internalCode: TFieldValidation;
  client: TFieldValidation;
  plant: TFieldValidation;
  installedAt: TFieldValidation;
  location: TFieldValidation;
  application: TFieldValidation;
  trackingDate: TFieldValidation;
  responsible: TFieldValidation;
  status: TFieldValidation;
  user: TFieldValidation;
}
export interface InstalledProductDetailForm {
  code: string;
  internalCode: string;
  client: string;
  plant: string;
  installedAt: string;
  location: string;
  application: string;
  trackingDate: string;
  responsible: string;
  status: string;
  user: string;
}

const InstalledProductDetail: React.FC<any> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [logOut, setLogOut] = useState<boolean>(false);
  //const
  const alert = useAlert();
  const { id } = useParams();
  //Apollohooks
  const getInstalledProduct = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy:"network-only"
  });
  const [getUser,getUserStatus] = useLazyQuery(getUserById, {
    fetchPolicy:"network-only"
  });
  const [getResponsible,getResponsibleStatus] = useLazyQuery(getResponsibleById, {
    fetchPolicy:"network-only"
  });
  //formvalues

  const defaultFormValues: InstalledProductDetailForm = {
    code: "",
    internalCode: "",
    user: "",
    client: "",
    plant: "",
    installedAt: "",
    location: "",
    application: "",
    trackingDate: "",
    responsible: "",
    status: "",
  };

  const formValidation: InstalledProductDetailValidations = {
    code: "not-empty",
    internalCode: "not-empty",
    client: "not-empty",
    plant: "not-empty",
    installedAt: "not-empty",
    location: "not-empty",
    application: "not-empty",
    trackingDate: "not-empty",
    responsible: "not-empty",
    status: "not-empty",
    user: "not-empty",
  };

  

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getInstalledProduct.loading) {
      if (getInstalledProduct.data) {
        if (getInstalledProduct.data.registeredProduct.comments) {
          setDescription(getInstalledProduct.data.registeredProduct.comments);
        }
        if(getInstalledProduct.data.registeredProduct.user){
          getUser({variables:{id:+getInstalledProduct.data.registeredProduct.user.id}})
        }
        else if(getInstalledProduct.data.registeredProduct.responsible){
          getResponsible({variables:{id:+getInstalledProduct.data.registeredProduct.responsible.id}})
        }else{
          forceUpdateValue({
            code: getInstalledProduct.data.registeredProduct.product
              ? getInstalledProduct.data.registeredProduct.product.code
              : "",
            internalCode: getInstalledProduct.data.registeredProduct.internalCode
              ? getInstalledProduct.data.registeredProduct.internalCode
              : "",
            client: getInstalledProduct.data.registeredProduct.plant.client.name,
            plant: getInstalledProduct.data.registeredProduct.plant.name,
            user: "",
            installedAt: getInstalledProduct.data.registeredProduct.installedAt?getInstalledProduct.data.registeredProduct.installedAt:"",
            location: getInstalledProduct.data.registeredProduct.location,
            application:
              getInstalledProduct.data.registeredProduct.application.name,
            trackingDate: getInstalledProduct.data.registeredProduct.trackingDate?getInstalledProduct.data.registeredProduct.trackingDate:"",
            responsible: "",
            status: getInstalledProduct.data.registeredProduct.status,
          });
        }
      }
      if (getInstalledProduct.error) {
        getInstalledProduct.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getInstalledProduct.loading]);

  useEffect(() => {
    if(!getUserStatus.loading){
      if(getUserStatus.data){
        forceUpdateValue({
          code: getInstalledProduct.data.registeredProduct.product
            ? getInstalledProduct.data.registeredProduct.product.code
            : "",
          internalCode: getInstalledProduct.data.registeredProduct.internalCode
            ? getInstalledProduct.data.registeredProduct.internalCode
            : "",
          client: getInstalledProduct.data.registeredProduct.plant.client.name,
          plant: getInstalledProduct.data.registeredProduct.plant.name,
          user: getUserStatus.data.user.firstName+" "+getUserStatus.data.user.lastName,
          installedAt: getInstalledProduct.data.registeredProduct.installedAt?getInstalledProduct.data.registeredProduct.installedAt:"",
          location: getInstalledProduct.data.registeredProduct.location,
          application:
            getInstalledProduct.data.registeredProduct.application.name,
          trackingDate: getInstalledProduct.data.registeredProduct.trackingDate?getInstalledProduct.data.registeredProduct.trackingDate:"",
          responsible: "",
          status: getInstalledProduct.data.registeredProduct.status,
        });
      }
    }
  }, [getUserStatus.loading])
  useEffect(() => {
    if(!getResponsibleStatus.loading){
      if(getResponsibleStatus.data){
        forceUpdateValue({
          code: getInstalledProduct.data.registeredProduct.product
            ? getInstalledProduct.data.registeredProduct.product.code
            : "",
          internalCode: getInstalledProduct.data.registeredProduct.internalCode
            ? getInstalledProduct.data.registeredProduct.internalCode
            : "",
          client: getInstalledProduct.data.registeredProduct.plant.client.name,
          plant: getInstalledProduct.data.registeredProduct.plant.name,
          user: "",
          installedAt: getInstalledProduct.data.registeredProduct.installedAt?getInstalledProduct.data.registeredProduct.installedAt:"",
          location: getInstalledProduct.data.registeredProduct.location,
          application:
            getInstalledProduct.data.registeredProduct.application.name,
          trackingDate: getInstalledProduct.data.registeredProduct.trackingDate?getInstalledProduct.data.registeredProduct.trackingDate:"",
          responsible: getResponsibleStatus.data.responsible.firstName+" "+getResponsibleStatus.data.responsible.lastName,
          status: getInstalledProduct.data.registeredProduct.status,
        });
      }
    }
  }, [getResponsibleStatus.loading])
  const {
    handleChange,
    values,
    handleBlur,
    forceUpdateValue,
  } = useForm<InstalledProductDetailForm, InstalledProductDetailValidations>(
    defaultFormValues,
    formValidation
  );

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getInstalledProduct.loading ? (
          getInstalledProduct.data ? (
            <Form>
              <div className="content-container">
                <div className="mainContainer">
                  <div className="buttonspan">
                    <label></label>
                    {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                    <div className="buttonCustom"></div>
                  </div>
                  <div className="detailcontainer">
                    <h2>
                      {edit
                        ? "Editar Estilo de Producto"
                        : "Detalles de Producto Registrado"}
                    </h2>
                    <div className="input-container">
                      {getInstalledProduct.data.registeredProduct.product ? (
                        <Link
                          className="form-link"
                          to={
                            "/products/detail/" +
                            getInstalledProduct.data.registeredProduct.product
                              .id
                          }
                          style={{textDecoration:"none"}}
                        >
                          <FormField
                            type="text"
                            label="Código de producto"
                            disabled={!edit}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                            }}
                            name="code"
                            value={values.code}
                          />
                        </Link>
                      ) : null}
                      <FormField
                        type="text"
                        label="Código Interno"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="internalCode"
                        value={values.internalCode}
                      />
                      <Link
                        className="form-link"
                        to={
                          "/clients/detail/" +
                          getInstalledProduct.data.registeredProduct.plant
                            .client.id
                        }
                        style={{textDecoration:"none"}}
                      >
                        <FormField
                          type="text"
                          label="Empresa"
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="client"
                          value={values.client}
                        />
                      </Link>
                      <Link
                        className="form-link"
                        to={
                          "/plants/detail/" +
                          getInstalledProduct.data.registeredProduct.plant.id
                        }
                        style={{textDecoration:"none"}}
                      >
                        <FormField
                          type="text"
                          label="Planta"
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="plant"
                          value={values.plant}
                        />
                      </Link>
                      {
                        getInstalledProduct.data.registeredProduct.user?
                      <Link
                        className="form-link"
                        to={
                          values.user !== ""
                            ? "/users/detail/" +
                              getInstalledProduct.data.registeredProduct.user.id
                            : "/installedproducts/detail/" + id
                        }
                        style={{textDecoration:"none"}}
                      >
                        <FormField
                          type="text"
                          label="Empleado de la planta"
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="user"
                          value={values.user}
                        />
                      </Link>
                      :
                      <Link
                        className="form-link"
                        to={
                          values.responsible !== ""
                            ? "/responsibles/detail/" +
                              getInstalledProduct.data.registeredProduct.responsible.id
                            : "/installedproducts/detail/" + id
                        }
                        style={{textDecoration:"none"}}
                      >
                      <FormField
                        type="text"
                        label="Responsable"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="responsible"
                        value={values.responsible}
                      />
                      </Link>
                      }
                      <FormField
                        type="text"
                        label="Fecha de Instalación"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="installedAt"
                        value={values.installedAt}
                      />
                      <FormField
                        type="text"
                        label="Ubicación"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="location"
                        value={values.location}
                      />
                      <FormField
                        type="text"
                        label="Aplicación"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="application"
                        value={values.application}
                      />
                      <FormField
                        type="text"
                        label="Fecha de seguimiento"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="trackingDate"
                        value={values.trackingDate}
                      />
                      
                      <FormField
                        type="text"
                        label="Estado"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="status"
                        value={values.status}
                      />
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="form-label">
                          Comentarios
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={description}
                          onChange={handleTextAreaChange}
                          disabled={!edit}
                        />
                      </Form.Group>
                    </div>
                    <div className="buttonspan"></div>
                  </div>
                </div>
              </div>
            </Form>
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default InstalledProductDetail;
