/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { CreateUser as mutation } from "../../../Util/Queries/Mutations";
import {
  getClients,
  getDistributors,
  getPortfolios,
  getUsers,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import "../styles.scss";

export interface IRegisterFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface Registerprops {
  role?: any;
  type: "read" | "write";
  edit?: boolean;
}

export interface IRegisterFormValidation {
  firstName: TFieldValidation;
  lastName: TFieldValidation;
  email: TFieldValidation;
  password: TFieldValidation;
}

const RegisterUser: React.FC<Registerprops> = (props) => {
  const alert = useAlert();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false);
  const [createUser, { data, loading, error }] = useMutation<any>(mutation, {
    update: (cache, { data: { createUser } }) => {
      const { users }: any = cache.readQuery({ query: getUsers });
      const newUsers = [...users, createUser];
      cache.writeQuery({
        query: getUsers,
        data: { users: newUsers },
      });
    },
  });
  const listDistributors = useQuery(getDistributors, {
    fetchPolicy: "network-only",
  });
  const listPortfolios = useQuery(getPortfolios, {
    fetchPolicy: "network-only",
  });
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [company, setCompany] = useState([]);
  const [plants, setPlants] = useState([]);
  const [distributorSelected, setDistributorSelected] = useState({
    value: "",
    label: "Selecciona un distribuidor...",
  });
  const [distributors, setDistributors] = useState([]);
  const [optionPortfolios, setOptionPortfolios] = useState([]);
  const [option, setOption] = useState({
    value: "default",
    label: "Selecciona tu empresa",
  });
  const [portfolioSelected, setPortfolioSelected] = useState({
    value: "default",
    label: "Selecciona un portafolio",
  });
  const listClients = useQuery(getClients, { fetchPolicy: "network-only" });
  const [optionPlant, setOptionPlant] = useState({
    value: "default",
    label: "Selecciona una planta",
  });
  const [mainpage, setmainpage] = useState<boolean>(false);
  const [plant, showPlant] = useState<boolean>(false);
  const defaultFormValues: IRegisterFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const formValidation: IRegisterFormValidation = {
    firstName: "not-empty",
    lastName: "not-empty",
    email: "email",
    password: "not-empty",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    IRegisterFormValues,
    IRegisterFormValidation
  >(defaultFormValues, formValidation);

  //useeffect to handle success/error on register data
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 461) {
            return alert.error("El email ya se encuentra en uso");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha creado el usuario con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  }, [data, error, loading]);

  //Fetch registered distributors to fill select component in case user is salesman type user
  useEffect(() => {
    if (!listDistributors.loading) {
      if (listDistributors.data) {
        let arr: any = [];
        listDistributors.data.distributors.items.map((empresa: any) => {
          return (arr = [...arr, { value: empresa.id, label: empresa.name }]);
        });
        setDistributors(arr);
      }
      if (listDistributors.error) {
        listDistributors.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [
    listDistributors.data,
    listDistributors.error,
    listDistributors.loading,
  ]);

  //UseEffect that fills clientSelect Component, only works when registered user is a Client type user
  useEffect(() => {
    if (!listClients.loading) {
      if (listClients.data) {
        let arr: any = [];
        listClients.data.clients.items.map((empresa: any) => {
          return (arr = [...arr, { value: empresa.id, label: empresa.name }]);
        });
        setCompany(arr);
      }
      if (listClients.error) {
        listClients.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [listClients.data, listClients.error, listClients.loading]);

  //Fetch portfolios to fill portfoliosSelect component in case user registered is a salesman type user
  useEffect(() => {
    if (!listPortfolios.loading) {
      if (listPortfolios.data) {
        let arr: any = [];
        listPortfolios.data.portfolios.items.map((portfolio: any) => {
          return (arr = [
            ...arr,
            { value: portfolio.id, label: portfolio.name },
          ]);
        });
        setOptionPortfolios(arr);
      }
      if (listPortfolios.error) {
        listPortfolios.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [
    listPortfolios.data,
    listPortfolios.error,
    listPortfolios.loading,
  ]);

  //useeffect that changes value on plant when a client is selected, so it matches client registered plants, only in case registered user is a client type user
  useEffect(() => {
    setOptionPlant({ label: "Selecciona una planta", value: "default" });
    if (option.value !== "default") {
      showPlant(true);
    }
    if (listClients.data) {
      var arr: any = [];
      listClients.data.clients.items.map((empresa: any) => {
        if (empresa.id === option.value) {
          return empresa.plants.map((planta: any) => {
            if (planta.status === 'ACTIVE') {

              return (arr = [...arr, { label: planta.name, value: planta.id }]);
            }
            else {
              return null
            }
          });
        } else {
          return null;
        }
      });
      setPlants(arr);
    }
  }, [listClients.data, option]);

  // change select values on state on select's value change
  const handleDistributorChange = (opcion: any) => {
    setDistributorSelected(opcion);
  };

  const handleSelectChange = (opcion: any) => {
    setOption(opcion);
  };
  const handlePlantChange = (seleccion: any) => {
    setOptionPlant(seleccion);
  };

  const handlePortfolioChange = (option: any) => {
    setPortfolioSelected(option);
  };

  //submit register data to backend
  const submitForm = () => {
    let userdata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectClient: boolean = false;
    let errorSelectSalesman: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields before focus

    //this validation's are executed depending on user type, so whenuser doesn't have certain fields this validation prevents to show a false error on empty values
    if (props.role.value !== "ADMIN") {
      if (props.role.value !== "AGENT") {
        if (props.role.value !== "DSO") {
          values.password = "defaultValue";
        }
      }
    }

    if (props.role.value === "USER_CLIENT") {
      if (option.value === "default" || optionPlant.value === "default") {
        errorSelectClient = true;
      }
    }

    if (props.role.value === "SALESMAN") {
      if (distributorSelected.value === "") {
        errorSelectSalesman = true;
      }
    }

    Object.values(errors).map((item: any) => {
      if (item === true) {
        return (errorsFound = true);
      }
      return null;
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return (emptyField = true);
      }
      return null;
    });
    //if an error is found it shows an alert notifying the user
    if (errorsFound || emptyField || errorSelectClient || errorSelectSalesman) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
      if (errorSelectClient) {
        alert.error("Por favor indique la empresa y la planta del cliente");
      }
      if (errorSelectSalesman) {
        alert.error("Por favor indique el distribuidor al que pertenece");
      }
    } else {
      setDisabledButton(true);
      try {
        //depending on role is the values sent to backend
        props.role.value === "ADMIN"
          ? (userdata = {
            email: values.email,
            role: props.role.value,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
          })
          : props.role.value === "USER_CLIENT"
            ? (userdata = {
              email: values.email,
              role: props.role.value,
              firstName: values.firstName,
              lastName: values.lastName,
              plantId: +optionPlant.value,
            })
            : props.role.value === "DSO"
              ? (userdata = {
                email: values.email,
                role: props.role.value,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
              })
              : props.role.value === "AGENT"
              ? (userdata = {
                email: values.email,
                role: props.role.value,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
              })
              : (userdata = {
                email: values.email,
                role: props.role.value,
                firstName: values.firstName,
                lastName: values.lastName,
                distributor_id: +distributorSelected.value,
                plantPortfolioId: portfolioSelected
                  ? +portfolioSelected.value
                  : "",
              });
        createUser({ variables: { input: userdata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(true);
      }
    }
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container" style={{ overflowY: "auto" }}>
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>
                  Registro de{" "}
                  {props.role.value === "ADMIN"
                    ? "Administrador"
                    : props.role.value === "SALESMAN"
                      ? "Vendedor"
                      : props.role.value === "USER_CLIENT"
                        ? "Cliente"
                        : props.role.value === "AGENT" ?
                          "Agente de Chat" :
                          "DSO"}
                </h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa tu nombre.",
                      }}
                      name="firstName"
                      value={values.firstName}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.firstName}
                    />
                    <FormField
                      type="text"
                      label="Apellido"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa tu apellido.",
                      }}
                      name="lastName"
                      value={values.lastName}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.lastName}
                    />

                    <FormField
                      type="text"
                      label="Correo"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un correo válido.",
                      }}
                      name="email"
                      value={values.email}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.email}
                    />
                    {props.role.value === "ADMIN" ||
                      props.role.value === "DSO" ||
                      props.role.value === "AGENT" ? (
                        <>
                          <FormField
                            type="password"
                            label="Contraseña"
                            name="password"
                            show={() => {
                              setShowPassword(!showPassword);
                            }}
                            value={values.password}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                              type: showPassword === false ? "password" : "",
                            }}
                          />
                        </>
                      ) : null}
                    {props.role.value === "USER_CLIENT" ? (
                      <>
                        <div className="spacer">
                          <h5>Empresa *</h5>
                          <Select
                            value={option}
                            options={company}
                            onChange={handleSelectChange}
                          />
                        </div>
                        {plant === true ? (
                          <div className="spacer">
                            <h5>Planta *</h5>
                            <Select
                              value={optionPlant}
                              options={plants}
                              onChange={handlePlantChange}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {props.role.value === "SALESMAN" ? (
                      <>
                        <div className="spacer">
                          <h5>Distribuidor</h5>
                          <Select
                            value={distributorSelected}
                            options={distributors}
                            onChange={handleDistributorChange}
                          />
                        </div>
                        <div className="spacer">
                          <h5>Asignar un portafolio</h5>
                          <Select
                            value={portfolioSelected}
                            options={optionPortfolios}
                            onChange={handlePortfolioChange}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                          <div
                            className="spinner-border spinner-border-sm text-primary"
                            role="status"
                          ></div>
                        )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
              <Redirect to="/users" />
            )
      }
    </Consumer>
  );
};

export default RegisterUser;
