import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { createDistributor as mutation } from "../../../Util/Queries/Mutations";
import { getDistributors, getPortfolios, getUsers } from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import Select from "react-select";
import "../styles.scss";

export interface DistributorFormValues {
  name: string;
  address: string;
  phoneNumber: string;
}

export interface DistributorFormValidation {
  name: TFieldValidation;
  address: TFieldValidation;
  phoneNumber: TFieldValidation;
}

const RegisterDistributor: React.FC<{}> = (props) => {
  const alert = useAlert();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [pressedButton, setpressedButton] = useState<boolean>(false);
  const [registerDistributor, { data, loading, error }] = useMutation<any>(
    mutation,
    {
      update: (cache, { data: { createDistributor } }) => {
        const { distributors }: any = cache.readQuery({
          query: getDistributors,
        });
        const newDistributors = [...distributors, createDistributor];
        cache.writeQuery({
          query: getDistributors,
          data: { distributors: newDistributors },
        });
      },
    }
  );
  const [mainpage, setmainpage] = useState<boolean>(false);
  const [portfolioOptions, setPortfolioOptions] = useState<any>([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>({
    value: null,
    label: "Selecciona un portafolio",
  });
  const listPortfolios = useQuery(getPortfolios, {fetchPolicy: "network-only"});

  const [distributorOptions, setDistributorOptions] = useState<any>([]);
  const [selectedDistributor, setSelectedDistributor] = useState<any>({
    value: null,
    label: "Selecciona un distribuior",
  });
  const listDistributors = useQuery(getDistributors, {fetchPolicy: "network-only"});

  const [salesmanDirectorOptions, setSalesmanDirectorOptions] = useState<any>([]);
  const [selectedSalesmanDirector, setSelectedSalesmanDirector] = useState<any>({
    value: null,
    label: "Selecciona un vendedor director",
  });
  const listSalesmanDirector = useQuery(getUsers, {
    variables:{
      "filter": {
        "filter":{
          "role":"SALESMAN",
          "status":"ACTIVE"
        }
      }
    },
    fetchPolicy: "network-only"
  });

  const defaultFormValues: DistributorFormValues = {
    name: "",
    address: "",
    phoneNumber: "",
  };

  const formValidation: DistributorFormValidation = {
    name: "not-empty",
    address: "not-empty",
    phoneNumber: "number",
  };

  const { handleChange, values, handleBlur, errors } = useForm<
    DistributorFormValues,
    DistributorFormValidation
  >(defaultFormValues, formValidation);

  //UseEffect to handle success/error on register mutation
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false);
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        alert.success("Se ha registrado el distribuidor con exito", {
          onClose: () => {
            setmainpage(true);
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data, error, loading]);


  //Fetch portfolios to assign to this new Distributor
  useEffect(() => {
    if(!listPortfolios.loading){
      if(listPortfolios.data){
        // fill the portfolio options array
        let arr: any = [{
          value: null,
          label: 'Ninguno',
        }];
        listPortfolios.data.portfolios.items.map((portfolio:any) => {
          return arr = [
            ...arr,
            {
              value: portfolio.id,
              label: portfolio.name,
            }
          ]
        });
        setPortfolioOptions(arr);
      }
    }
  }, [listPortfolios.data, listPortfolios.loading]);

  //Fetch distributors to assign to this new Distributor
  useEffect(() => {
    if(!listDistributors.loading){
      if(listDistributors.data){
        // fill the distributor options array
        let arr: any = [{
          value: null,
          label: 'Ninguno',
        }];
        listDistributors.data.distributors.items.map((distributor:any) => {
          return arr = [
            ...arr,
            {
              value: distributor.id,
              label: distributor.name,
            }
          ]
        });
        setDistributorOptions(arr);
      }
    }
  }, [listDistributors.data, listDistributors.loading]);

  useEffect(() => {
    if(!listSalesmanDirector.loading){
      if(listSalesmanDirector.data){
        // fill the distributor options array
        let arr: any = [{
          value: null,
          label: 'Ninguno',
        }];
        listSalesmanDirector.data.users.items.map((salesman:any) => {
          return arr = [
            ...arr,
            {
              value: salesman.id,
              label: `${salesman.firstName} ${salesman.lastName}`,
            }
          ]
        });
        setSalesmanDirectorOptions(arr);
      }
    }
  }, [listSalesmanDirector.data, listSalesmanDirector.loading]);

  const handlePortfolioChange = (option: any) => {
    setSelectedPortfolio(option);
  };

  const handleDistributorChange = (option: any) => {
    setSelectedDistributor(option);
  };

  const handleSalesmanDirectorChange = (option: any) => {
    setSelectedSalesmanDirector(option);
  };

  //submit register data to backend
  const submitForm = () => {
    let Distributordata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    setpressedButton(true)
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true);
      try {
        Distributordata = {
          name: values.name,
          address: values.address,
          phoneNumber: values.phoneNumber,
          plantPortfolioId: selectedPortfolio.value,
          parentDistributorId: selectedDistributor.value,
          salesmanDirectorId: selectedSalesmanDirector.value,
        };
        registerDistributor({ variables: { data: Distributordata } });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false);
      }
    }
  };
  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : mainpage === false ? (
          <div className="content-container">
            <div className="mainContainer" style={{ marginTop: "2rem" }}>
              <div className="register-container">
                <h2>Registro de Distribuidor</h2>
                <Form>
                  <div className="input-container">
                    <FormField
                      type="text"
                      label="Nombre"
                      required={{
                        required: true,
                        requiredMsg:
                          "Por favor ingresa el nombre del distribuidor.",
                      }}
                      name="name"
                      value={values.name}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.name}
                    />
                    <FormField
                      type="text"
                      label="Direccion"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa una direccion.",
                      }}
                      name="address"
                      value={values.address}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.address}
                    />

                    <FormField
                      type="text"
                      label="Numero de Contacto"
                      required={{
                        required: true,
                        requiredMsg: "Por favor ingresa un telefono válido.",
                      }}
                      name="phoneNumber"
                      value={values.phoneNumber}
                      inputElAttributes={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      error={pressedButton && errors.phoneNumber}
                    />

                    {/*<div className="spacer">
                      <h5>Asignar distribuidor a un portafolio</h5>
                      
                      {!listPortfolios.loading ? (
                        <Select
                        options={portfolioOptions}
                        value={selectedPortfolio}
                        onChange={handlePortfolioChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>*/}

                    <div className="spacer">
                      <h5>Asignar distribuidor padre</h5>
                      
                      {!listDistributors.loading ? (
                      <Select
                        styles={{
                          menu: provided => ({...provided, zIndex: 9999}),
                          menuPortal: provided => ({...provided, zIndex: 9999})
                        }}
                        menuPortalTarget={document.body}
                        menuPlacement="top"
                        options={distributorOptions}
                        value={selectedDistributor}
                        onChange={handleDistributorChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>

                    <div className="spacer">
                      <h5>Asignar vendedor director</h5>
                      
                      {!listSalesmanDirector.loading ? (
                      <Select
                        styles={{
                          menu: provided => ({...provided, zIndex: 9999}),
                          menuPortal: provided => ({...provided, zIndex: 9999})
                        }}
                        menuPortalTarget={document.body}
                        menuPlacement="top"
                        options={salesmanDirectorOptions}
                        value={selectedSalesmanDirector}
                        onChange={handleSalesmanDirectorChange}
                      />
                      ) : (
                        <div className="spinnerSpan">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>

                  </div>
                  <div className="button-span">
                    <Button
                      type="primary"
                      elementAttr={{ type: "button", disabled: disabledButton }}
                      clickEvent={submitForm}
                      size="default"
                    >
                      {!loading ? (
                        "Registrar"
                      ) : (
                        <div
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/Distributors" />
        )
      }
    </Consumer>
  );
};

export default RegisterDistributor;
