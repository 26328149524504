/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import {
  editProductLine,
  editResourcePosition
} from "../../../Util/Queries/Mutations";
import {
  getProductLineById as query,
  getProductLines
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import Table, { Row } from "../../Atoms/Table/Table";

export interface ProductLineDetailValidations {
  name: TFieldValidation;
}
export interface ProductLineDetailForm {
  name: string;
  image: string;
}

const ProductLineDetail: React.FC<any> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [array, setArray] = useState<any>([]);
  const [resources, setResources] = useState<any>([]);
  const [description, setDescription] = useState<string>();
  const [checked, setChecked] = useState<any>([]);
  const [reorder, setReorder] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [all, setAll] = useState<boolean>(false);
  const [deleteSuccess, setDelete] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const specs=[
    { label: "Fluído", value: "needs_fluid" },
    { label: "pH", value: "needs_pH" },
    { label: "Temperatura", value: "needs_temperature" },
    { label: "Presión", value: "needs_pressure" },
    { label: "RPM", value: "needs_RPM" },
    { label: "Diámetro de Eje/Brida/Tubería", value: "needs_axisDiameter" },
    { label: "Norma Dimensional", value: "needs_Norm" },
    { label: "Tipo de Válvula", value: "needs_valveType" },
    { label: "Marca Bomba", value: "needs_bombBrand" },
    { label: "Presentación Textil", value: "needs_textileType" },
  ];
  //const
  const alert = useAlert();
  const rows: any = [];
  const resourceRows: any = [];
  const { id } = useParams();
  //Apollohooks
  const getProductLine = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [performReorder, reorderStatus] = useMutation(editResourcePosition);
  const [performEdit, { loading, data, error }] = useMutation(editProductLine);
  //formvalues

  const defaultFormValues: ProductLineDetailForm = {
    name: "",
    image: "",
  };

  const formValidation: ProductLineDetailValidations = {
    name: "not-empty",
  };

  var list: any = [];

  const updateCache = (productLine: any) => {
    const data = productLine.readQuery({
      query: getProductLines,
    });
    const newData = {
      productLines: data.productLines.filter((t: any) => t.id !== id),
    };
    productLine.writeQuery({
      query: getProductLines,
      data: newData,
    });
  };

  useEffect(() => {
    if (all === true) {
      let arr: any = [];
      specs.map((spec: any) => {
        return arr = [...arr, spec.value];
      });
      setChecked(arr);
    } else {
      setChecked([]);
    }
  }, [all]);

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getProductLine.loading) {
      if (getProductLine.data) {
        createtable();
        let array: any = [];
        setDescription(getProductLine.data.productLine.description);
        Object.keys(getProductLine.data.productLine).map(
          (key: any, index: any) => {
            if (getProductLine.data.productLine[key] === true) {
              return array = [...array, key];
            }
            else{
              return null
            }
          }
        );
        setChecked(array);
        fillResourceTable();
        forceUpdateValue({
          name: getProductLine.data.productLine.name,
          image: "",
        });
      }
      if (getProductLine.error) {
        getProductLine.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode===401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getProductLine.loading]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<ProductLineDetailForm, ProductLineDetailValidations>(
    defaultFormValues,
    formValidation
  );

  //Onsubmit function execute
  const submitForm = () => {
    let ProductLinedata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    let errorSelectStyle: boolean = false;
    //validations provided by Formfield component, also validates empty fields before focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    if (description === "" || values.name === "") {
      emptyField = true;
    }
    if (errorsFound || emptyField || errorSelectStyle) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true)
      try {
        ProductLinedata = {
          name: values.name,
          description: description,
          status: "ACTIVE",
        };
        checked.map((spec: any) => {
          return ProductLinedata[spec] = true;
        });
        performEdit({
          variables: {
            id: id ? +id : 0,
            data: ProductLinedata,
            image: image ? image : "",
          },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false)
      }
    }
  };

  //Fill productStyles data that belong to this Line
  const createtable = () => {
    var arr: any = [];
    getProductLine.data.productLine.productStyles.map((style: any) => {
      arr = [...arr, style];
      const row: Row = {
        tds: [
          {
            valor: style.id,
          },
          {
            valor: style.name,
          },
          {
            valor: style.description,
          },
        ],
      };
      return rows.push(row);
    });
    const a = {
      headers: ["ID", "NOMBRE", "DESCRIPCION"],
      rows: rows,
    };
    setLoad(true);
    setArray(a);
  };

  //Fill resources of this line
  const fillResourceTable = () => {
    var arr: any = [];
    getProductLine.data.productLine.resources.map((resource: any) => {
      arr = [...arr, resource];
      const row: Row = {
        tds: [
          {
            valor: resource.id,
          },
          {
            valor: resource.name,
          },
          {
            valor:
              resource.type === "PDF_QUOTE_TEMPLATE"
                ? "Plantilla de Cotización"
                : resource.type === "PDF"
                ? "Ficha Técnica"
                : "Video",
          },
          {
            valor:resource.is_public?"✔":"✘"
          },
          {
            valor: resource.path,
          },
        ],
      };
      return resourceRows.push(row);
    });
    const a = {
      headers: ["ID", "NOMBRE", "TIPO","PUBLICO" ,"ENLACE"],
      rows: resourceRows,
    };
    setLoad(true);
    setResources(a);
  };

  //Handle table row click
  const handleRowClick = (style: Row) => {
    props.history.push(`/productstyles/detail/${style.tds[0].valor}`);
  };

  //UseEffects to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (error) {
        setDisabledButton(false)
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else if (error.message.code === 505) {
            return alert.error(
              "No se puede eliminar la línea ya que tiene relacion con otros elementos, elimine estos elementos primero"
            );
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
      if (data) {
        let msg = "";
        if (deleteSuccess) {
          msg = "Se ha eliminado la Línea de producto...";
        } else {
          msg = "Se han guardado los cambios";
        }
        alert.success(msg, {
          onClose: () => {
            props.history.push(`/productlines`);
          },
        });
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!reorderStatus.loading) {
      if (reorderStatus.data) {
        fillResourceTable();
      }
    }
  }, [reorderStatus.loading]);

  const handleTextAreaChange = (event: any) => {
    setDescription(event.target.value);
  };

  const reorderItems = (temp: any) => {
    list = temp;
  };

  const reorderResources = () => {
    if (reorder) {
      let arr: any = [];
      list.map((item: any, index: number) => {
        return arr = [...arr, [+item.id, index + 1]];
      });
      performReorder({ variables: { positions: { positions: arr } } });
      getProductLine.refetch();
    }
    setReorder(!reorder);
  };
  //Execute delete mutation function
  const performDelete = () => {
    setDisabledButton(true)
    let dataModified: any = {
      status: "INACTIVE",
    };
    performEdit({
      variables: {
        id: id ? +id : 0,
        data: dataModified,
        image: image ? image : "",
      },
      update: updateCache,
    });
    setModal(false);
    setDelete(true);
  };

  //Show resource detail on row click
  const handleResourceClick = (resource: any) => {
    props.history.push(
      `/productlines/detail/${id}/resources/detail/${resource.tds[0].valor}`
    );
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : getProductLine.networkStatus!==1 ? (
          getProductLine.data ? (
            load ? (
              <Form>
                <div className="content-container">
                  <ConfirmationModal
                    show={modal}
                    onCancel={() => setModal(false)}
                    onHide={() => setModal(false)}
                    clickEvent={performDelete}
                    message={
                      <div>
                        Está seguro que desea eliminar esta línea de producto?
                        <br /> Esta acción no se puede revertir...
                      </div>
                    }
                    action="Eliminar Línea de Producto"
                    type="delete"
                  />
                  <div className="mainContainer">
                    <div className="buttonspan">
                      <label></label>
                      {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                      <div className="buttonCustom">
                        <Button
                          type="danger"
                          elementAttr={{ type: "button",disabled:disabledButton }}
                          clickEvent={() => {
                            setModal(true);
                          }}
                          size="default"
                        >
                          {"Eliminar"}
                        </Button>
                        {edit ? (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{ type: "button",disabled:disabledButton }}
                              clickEvent={submitForm}
                              size="default"
                            >
                              {"Guardar"}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              elementAttr={{ type: "button" }}
                              clickEvent={() => {
                                setedit(true);
                              }}
                              size="default"
                            >
                              {"Editar"}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="detailcontainer">
                      <h2>
                        {edit
                          ? "Editar Línea de Producto"
                          : "Detalles de Línea de Producto"}
                      </h2>
                      <div className="input-container">
                        <FormField
                          type="text"
                          label="Nombre"
                          required={{
                            required: true,
                            requiredMsg: "Por favor ingresa un nombre.",
                          }}
                          disabled={!edit}
                          inputElAttributes={{
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          name="name"
                          value={values.name}
                        />
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label className="form-label">
                            Descripción
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={description}
                            onChange={handleTextAreaChange}
                            disabled={!edit}
                          />
                        </Form.Group>
                        {!edit ? (
                          load ? (
                            <>
                              <FormField
                                type="image"
                                label="Imagen"
                                name="image"
                                value="Imagen"
                                link={getProductLine.data.productLine.image}
                              />
                              <h6>Estilos de Producto</h6>
                              <Table
                                headers={array.headers}
                                rows={array.rows}
                                type="productstyle"
                                onrowclick={handleRowClick}
                              />
                              <div className="resources">
                                <h6>Recursos</h6>
                                <Button
                                  type="primary"
                                  elementAttr={{
                                    type: "button",
                                    style: {
                                      position: "absolute",
                                      marginRight: "10rem",
                                    },
                                  }}
                                  size="small"
                                  clickEvent={reorderResources}
                                >
                                  {!reorder ? "Cambiar Orden" : "Terminar"}
                                </Button>

                                <Button
                                  type="primary"
                                  elementAttr={{ type: "button" }}
                                  size="small"
                                  clickEvent={() => {
                                    props.history.push(
                                      `/productlines/detail/${id}/addresource`
                                    );
                                  }}
                                >
                                  {"Agregar Recurso"}
                                </Button>
                              </div>
                              {getProductLine.networkStatus===4?
                              <div className="spinnerSpan"><Spinner animation="border" variant="primary" /></div>
                              :
                              <Table
                                headers={resources.headers}
                                reorder={reorder}
                                rows={resources.rows}
                                onReorderEnd={reorderItems}
                                type="resources"
                                onrowclick={handleResourceClick}
                              />
}
                            </>
                          ) : null
                        ) : (
                          <>
                            <FormField
                              type="file"
                              label="Selecciona una imagen"
                              required={{
                                required: false,
                                requiredMsg:
                                  "Por favor selecciona una imagen válida.",
                              }}
                              name="image"
                              value={values.image}
                              inputElAttributes={{
                                onChange: (ev) => {
                                  if (ev.target.files)
                                    setImage(ev.target.files[0]);
                                  handleChange(ev);
                                },
                                onBlur: handleBlur,
                                accept: "image/png,jpg",
                              }}
                            />
                          </>
                        )}
                        <Form.Group controlId="formCheckbox">
                          <Form.Label>Especificaciones</Form.Label>
                          {edit ? (
                            <Button
                              type="primary"
                              elementAttr={{ type: "button" }}
                              size="tiny"
                              clickEvent={() => {
                                setAll(!all);
                              }}
                            >
                              Seleccionar todo
                            </Button>
                          ) : null}
                          {specs.map((spec: any) => {
                            return (
                              <Form.Check
                                name={spec.value}
                                disabled={!edit}
                                type="checkbox"
                                label={spec.label}
                                checked={
                                  checked.filter(
                                    (check: any) => check === spec.value
                                  ).length > 0
                                }
                                style={{ marginLeft: "1rem" }}
                                onChange={(e: any) => {
                                  let isIn = false;
                                  if (e.target.checked) {
                                    checked.map((check: any) => {
                                      if (check === e.target.name) {
                                        return isIn = true;
                                      }
                                      return null
                                    });
                                    if (!isIn) {
                                      setChecked([...checked, e.target.name]);
                                    }
                                  } else {
                                    checked.map((check: any) => {
                                      if (check === e.target.name) {
                                        return isIn = true;
                                      }
                                      return null
                                    });
                                    if (isIn) {
                                      setChecked(
                                        checked.filter(
                                          (item: any) => item !== e.target.name
                                        )
                                      );
                                    }
                                  }
                                }}
                              />
                            );
                          })}
                        </Form.Group>
                      </div>
                      <div className="buttonspan"></div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default ProductLineDetail;
