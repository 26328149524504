/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button as Bbutton } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Consumer } from "../../../Util/Context/Context";
import useForm, { TFieldValidation } from "../../../Util/Hooks/useForm";
import { editDistributor as mutation } from "../../../Util/Queries/Mutations";
import {
  getDistributorById as query,
  getDistributors,
  getPortfolios,
  getUsers,
} from "../../../Util/Queries/Queries";
import Button from "../../Atoms/Button";
import FormField from "../../Atoms/FormField";
import ConfirmationModal from "../../Atoms/Modal/ConfirmationModal";
import Table, { Row } from "../../Atoms/Table/Table";
import Select from "react-select";

export interface DistributorDetailValidations {
  name: TFieldValidation;
  address: TFieldValidation;
  phoneNumber: TFieldValidation;
  status: TFieldValidation;
}
export interface DistributorDetailForm {
  name: string;
  address: string;
  phoneNumber: string;
  status: string;
}

const DistributorDetail: React.FC<{ history: History }> = (props) => {
  //hooks
  const [edit, setedit] = useState<boolean>(false);
  const [usersArray, setUsersArray] = useState<any>([]);
  const [childDistributorsArray, setChildDistributorsArray] = useState<any>([]);
  const [logOut, setLogOut] = useState<boolean>(false);
  const [deleteSuccess, setDelete] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false);

  const [editDistributor, { data, loading, error }] = useMutation<any>(
    mutation
  );
  const [portfolioOptions, setPortfolioOptions] = useState<any>([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>({
    value: null,
    label: "Ninguno",
  });
  const listPortfolios = useQuery(getPortfolios, {fetchPolicy: "network-only"});

  const [distributorOptions, setDistributorOptions] = useState<any>([]);
  const [selectedParentDistributor, setSelectedParentDistributor] = useState<any>({
    value: null,
    label: 'Ninguno',
  });
  const listDistributors = useQuery(getDistributors, {fetchPolicy: "network-only"});

  const [salesmanDirectorOptions, setSalesmanDirectorOptions] = useState<any>([]);
  const [selectedSalesmanDirector, setSelectedSalesmanDirector] = useState<any>({
    value: null,
    label: 'Ninguno',
  });
  const listSalesmanDirectors = useQuery(getUsers, {
    variables: {filter: {
      filter: {
        role: "SALESMAN",
        status: "ACTIVE"
      }
    }},
    fetchPolicy: "network-only"
  });

  //const
  const alert = useAlert();
  const { id } = useParams();
  //Apollohooks
  const getDistributor = useQuery(query, {
    variables: { id: id ? +id : 0 },
    fetchPolicy:"network-only"
  });
  //formvalues

  const defaultFormValues: DistributorDetailForm = {
    name: "",
    address: "",
    phoneNumber: "",
    status: "",
  };

  const formValidation: DistributorDetailValidations = {
    name: "not-empty",
    address: "not-empty",
    phoneNumber: "number",
    status: "not-empty",
  };

  const updateCache = (distributor: any) => {
    const data = distributor.readQuery({
      query: getDistributors,
    });
    const newData = {
      distributors: data.distributors.filter((t: any) => t.id !== id),
    };
    distributor.writeQuery({
      query: getDistributors,
      data: newData,
    });
  };

  //Main useEffect fetching data to fill form
  useEffect(() => {
    if (!getDistributor.loading) {
      if (getDistributor.data) {
        createTables();
        forceUpdateValue({
          name: getDistributor.data.distributor.name,
          address: getDistributor.data.distributor.address,
          phoneNumber: getDistributor.data.distributor.phoneNumber,
          status:
            getDistributor.data.distributor.status === "ACTIVE"
              ? "Activo"
              : "Inactivo",
        });
        if(getDistributor.data.distributor.plantPortfolio){
          setSelectedPortfolio({
            value: getDistributor.data.distributor.plantPortfolio.id,
            label: getDistributor.data.distributor.plantPortfolio.name,
          })
        }
        if(getDistributor.data.distributor.parentDistributor){
          setSelectedParentDistributor({
            value: getDistributor.data.distributor.parentDistributor.id,
            label: getDistributor.data.distributor.parentDistributor.name,
          })
        }
        if(getDistributor.data.distributor.salesmanDirector){
          setSelectedSalesmanDirector({
            value: getDistributor.data.distributor.salesmanDirector.id,
            label: `${getDistributor.data.distributor.salesmanDirector.firstName} ${getDistributor.data.distributor.salesmanDirector.lastName}`,
          })
        }
      }
      if (getDistributor.error) {
        getDistributor.error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [getDistributor.loading]);

  //Fill user's info
  const createTables = () => {
    var users: any = [];
    const userRows : any = [];
    getDistributor.data.distributor.users.map((user: any) => {
      users = [...users, user];
      const row: Row = {
        tds: [
          {
            valor: user.id,
          },
          {
            valor: user.firstName + " " + user.lastName,
          },
          {
            valor: user.email,
          },
          {
            valor: user.phoneNumber,
          },
        ],
      };
      return userRows.push(row);
    });
    const usersTable = {
      headers: ["ID", "NOMBRE", "EMAIL", "TELEFONO"],
      rows: userRows,
    };

    var distributors: any = [];
    const distributorRows : any = [];
    getDistributor.data.distributor.childDistributors.map((distributor: any) => {
      distributors = [...distributors, distributor];
      const row: Row = {
        tds: [
          {
            valor: distributor.id,
          },
          {
            valor: distributor.name,
          },
          {
            valor: distributor.address,
          },
          {
            valor: distributor.phoneNumber,
          },
        ],
      };
      return distributorRows.push(row);
    });
    const distributorsTable = {
      headers: ["ID", "NOMBRE", "DIRECCIÓN", "TELEFONO"],
      rows: distributorRows,
    };

    setLoad(true);
    setChildDistributorsArray(distributorsTable);
    setUsersArray(usersTable);
  };

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    forceUpdateValue,
  } = useForm<DistributorDetailForm, DistributorDetailValidations>(
    defaultFormValues,
    formValidation
  );

  //UseEffects to handle success/error on mutation event
  useEffect(() => {
    if (!loading) {
      if (data) {
        let msg = "";
        if (deleteSuccess) {
          msg = "Se ha eliminado el distribuidor con exito...";
        } else {
          msg = "Se han guardado los cambios";
        }
        alert.success(msg, {
          onClose: () => {
            props.history.push(`/distributors`);
          },
        });
      }
      if (error) {
        setDisabledButton(false)
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setLogOut(true);
            return alert.error("Su sesion ha caducado");
          } 
          else if (error.message.code === 505) {
            return alert.error("No se puede eliminar el distribuidor ya que tiene relación con otros elementos, elimine estos elementos primero");
          }
          else {
            return alert.error("Ha ocurrido un error");
          }
        });
      }
    }
  }, [loading]);

  //Fetch portfolios to assign to this Distributor
  useEffect(() => {
    if(!listPortfolios.loading){
      if(listPortfolios.data){
        // fill the portfolio options array
        let arr: any = [{
          value: null,
          label: 'Ninguno',
        }];
        listPortfolios.data.portfolios.items.map((portfolio:any) => {
          return arr = [
            ...arr,
            {
              value: portfolio.id,
              label: portfolio.name,
            }
          ]
        });
        setPortfolioOptions(arr);
      }
    }
  }, [listPortfolios.data, listPortfolios.loading]);
  
  //Fetch distributors to assign to this Distributor
  useEffect(() => {
    if(!listDistributors.loading){
      if(listDistributors.data){
        // fill the portfolio options array
        let arr: any = [{
          value: null,
          label: 'Ninguno',
        }];
        listDistributors.data.distributors.items.map((distributor:any) => {
          return arr = [
            ...arr,
            {
              value: distributor.id,
              label: distributor.name,
            }
          ]
        });
        setDistributorOptions(arr);
      }
    }
  }, [listDistributors.data, listDistributors.loading]);

  //Fetch salesman directors to assign to this Distributor
  useEffect(() => {
    if(!listSalesmanDirectors.loading){
      if(listSalesmanDirectors.data){
        // fill the portfolio options array
        let arr: any = [{
          value: null,
          label: 'Ninguno',
        }];
        listSalesmanDirectors.data.users.items.map((salesman:any) => {
          return arr = [
            ...arr,
            {
              value: salesman.id,
              label: `${salesman.firstName} ${salesman.lastName}`,
            }
          ]
        });
        setSalesmanDirectorOptions(arr);
      }
    }
  }, [listSalesmanDirectors.data, listSalesmanDirectors.loading]);

  const handlePortfolioChange = (option: any) => {
    setSelectedPortfolio(option);
  };

  const handleDistributorChange = (option: any) => {
    setSelectedParentDistributor(option);
  };

  const handleSalesmanDirectorChange = (option: any) => {
    setSelectedSalesmanDirector(option);
  };

  const saveChanges = () => {
    let Distributordata;
    let errorsFound: boolean = false;
    let emptyField: boolean = false;
    //validations provided by Formfield component, also validates empty fields after focus
    Object.values(errors).map((item: any) => {
      if (item === true) {
        return errorsFound = true;
      }
      return null
    });
    Object.values(values).map((item: any) => {
      if (item === "") {
        return emptyField = true;
      }
      return null
    });
    if (errorsFound || emptyField) {
      if (errorsFound) {
        alert.error("Por favor llena todos los campos en el formato indicado");
      }
      if (emptyField) {
        alert.error("No puede dejar campos vacios");
      }
    } else {
      setDisabledButton(true)
      try {
        Distributordata = {
          name: values.name,
          address: values.address,
          phoneNumber: values.phoneNumber,
          plantPortfolioId: selectedPortfolio.value,
          parentDistributorId: selectedParentDistributor.value,
          salesmanDirectorId: selectedSalesmanDirector.value,
        };
        editDistributor({
          variables: { id: id ? +id : 0, data: Distributordata },
        });
      } catch (error) {
        alert.error("Ha ocurrido un error");
        setDisabledButton(false)
      }
    }
  };
  const performDelete = () => {
    let dataModified = { status: "INACTIVE" };
    setDisabledButton(true)
    editDistributor({
      variables: {
        id: id ? +id : 0,
        data: dataModified,
      },
      update: updateCache,
    });
    setDelete(true);
    setModal(false);
  };

  const handleUserRowClick = (user: Row) => {
    props.history.push(`/users/detail/${user.tds[0].valor}`);
  };

  const handleChildDistributorRowClick = (distributor: Row) => {
    props.history.push(`/distributors/detail/${distributor.tds[0].valor}`);
  };

  return (
    <Consumer>
      {(context) =>
        logOut === true ? (
          context.setLogged(false)
        ) : !getDistributor.loading ? (
          getDistributor.data ? (
            load ? (
              <div className="content-container">
                <ConfirmationModal
                  show={modal}
                  onHide={() => setModal(false)}
                  onCancel={() => setModal(false)}
                  clickEvent={performDelete}
                  message={
                    <div>
                      Está seguro que desea eliminar este distribuidor?
                      <br /> Esta acción no se puede revertir...
                    </div>
                  }
                  action="Eliminar Distribuidor"
                  type="delete"
                />
                <div className="mainContainer">
                  <div className="buttonspan">
                    <label></label>
                    {
                        edit?
                        <Bbutton
                        size="sm"
                        variant="light"
                        className="backButton"
                        onClick={()=>{setedit(false)}}
                      >
                        <div className="backIcon"></div>
                      </Bbutton>
                      :null
                      }
                    <div className="buttonCustom">
                          <Button
                            type="danger"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            clickEvent={() => {
                              setModal(true);
                            }}
                            size="default"
                          >
                            {"Eliminar"}
                          </Button>
                      {edit ? (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button",disabled:disabledButton }}
                            size="default"
                            clickEvent={saveChanges}
                          >
                            {"Guardar"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="primary"
                            elementAttr={{ type: "button" }}
                            clickEvent={() => {
                              setedit(true);
                            }}
                            size="default"
                          >
                            {"Editar"}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="detailcontainer">
                    <h2>
                      {edit
                        ? "Editar Distribuidor"
                        : "Detalles del Distribuidor"}
                    </h2>
                    <div className="input-container">
                      <FormField
                        type="text"
                        label="Nombre"
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        name="name"
                        value={values.name}
                      />
                      <FormField
                        type="text"
                        label="Direccion"
                        name="address"
                        value={values.address}
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                      <FormField
                        type="text"
                        label="Telefono"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        disabled={!edit}
                        inputElAttributes={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                      />
                      {!edit ? (
                        <>
                          <FormField
                            type="text"
                            label="Estatus"
                            name="status"
                            //@ts-ignore
                            value={values.status}
                            disabled={!edit}
                            inputElAttributes={{
                              onChange: handleChange,
                              onBlur: handleBlur,
                              disabled: edit,
                            }}
                          />
                          {/*{getDistributor.data.distributor.plantPortfolio ? (
                            <Link
                              className="form-link"
                              to={
                                "/portfolios/detail/" +
                                getDistributor.data.distributor.plantPortfolio.id
                              }
                              style={{textDecoration:"none"}}
                            >
                              <FormField
                                type="text"
                                label="Portafolio"
                                name="plantPortfolio"
                                disabled={!edit}
                                value={selectedPortfolio.label}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Link>
                          ) : (
                            <FormField
                              type="text"
                              label="Portafolio"
                              name="plantPortfolio"
                              disabled={!edit}
                              value={selectedPortfolio.label}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                            />
                          )}*/}
                          {getDistributor.data.distributor.parentDistributor ? (
                            <Link
                              className="form-link"
                              to={
                                "/distributors/detail/" +
                                getDistributor.data.distributor.parentDistributor.id
                              }
                              style={{textDecoration:"none"}}
                            >
                              <FormField
                                type="text"
                                label="Distribuidor Padre"
                                name="parentDistributor"
                                disabled={!edit}
                                value={selectedParentDistributor.label}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Link>
                          ) : (
                            <FormField
                              type="text"
                              label="Distribuidor Padre"
                              name="parentDistributor"
                              disabled={!edit}
                              value={selectedParentDistributor.label}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                            />
                          )}
                          {getDistributor.data.distributor.salesmanDirector ? (
                            <Link
                              className="form-link"
                              to={
                                "/users/detail/" +
                                getDistributor.data.distributor.salesmanDirector.id
                              }
                              style={{textDecoration:"none"}}
                            >
                              <FormField
                                type="text"
                                label="Vendedor director"
                                name="salesmanDirector"
                                disabled={!edit}
                                value={selectedSalesmanDirector.label}
                                inputElAttributes={{
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Link>
                          ) : (
                            <FormField
                              type="text"
                              label="Vendedor director"
                              name="salesmanDirector"
                              disabled={!edit}
                              value={selectedSalesmanDirector.label}
                              inputElAttributes={{
                                onChange: handleChange,
                                onBlur: handleBlur,
                              }}
                            />
                          )}
                          <h6>Usuarios del distribuidor</h6>
                          <Table
                            headers={usersArray.headers}
                            rows={usersArray.rows}
                            type="users"
                            onrowclick={handleUserRowClick}
                          />

                          <h6>Distribuidores hijo</h6>
                          <Table
                            headers={childDistributorsArray.headers}
                            rows={childDistributorsArray.rows}
                            type="users"
                            onrowclick={handleChildDistributorRowClick}
                          />
                        </>
                      ) : (
                        <>
                          {/*<h6>Selecciona un nuevo portafolio</h6>
                          <div className="spacer">
                            <Select
                              value={selectedPortfolio}
                              options={portfolioOptions}
                              onChange={handlePortfolioChange}
                            />
                          </div>
                          <br/>*/}
                          <h6>Selecciona un distribuidor padre</h6>
                          <div className="spacer">
                            <Select
                              styles={{
                                menu: provided => ({...provided, zIndex: 9999}),
                                menuPortal: provided => ({...provided, zIndex: 9999})
                              }}
                              menuPortalTarget={document.body}
                              menuPlacement="top"
                              value={selectedParentDistributor}
                              options={distributorOptions}
                              onChange={handleDistributorChange}
                            />
                          </div>
                          <br/>
                          <h6>Selecciona un vendedor director</h6>
                          <div className="spacer">
                            <Select
                              styles={{
                                menu: provided => ({...provided, zIndex: 9999}),
                                menuPortal: provided => ({...provided, zIndex: 9999})
                              }}
                              menuPortalTarget={document.body}
                              menuPlacement="top"
                              value={selectedSalesmanDirector}
                              options={salesmanDirectorOptions}
                              onChange={handleSalesmanDirectorChange}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="buttonspan"></div>
                  </div>
                </div>
              </div>
            ) : null
          ) : null
        ) : (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        )
      }
    </Consumer>
  );
};

export default DistributorDetail;
