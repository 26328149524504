import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./ConfirmationModal.scss";
const ConfirmationModal: React.FC<any> = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.action}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        {
          //A ternary just to remark difference between a modal to delete elements and a close Request Modal
        props.type === "delete" ? (
          <>
            <Button variant="primary" onClick={props.onCancel}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={()=>{props.clickEvent();props.onCancel()}}>
              Eliminar
            </Button>
          </>
        ) : (
          <Button onClick={()=>{props.clickEvent();props.onCancel()}}>Confirmar</Button>
        )
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
