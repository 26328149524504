import gql from "graphql-tag";

export const getUserToken = gql`
  mutation inicio($data: SignInInput!) {
    signIn(data: $data) {
      token
      refreshToken
    }
  }
`;

export const createClient = gql`
  mutation createClient($data: CreateClientInput!) {
    createClient(data: $data) {
      id
      name
      address
      contactNumber
      status
    }
  }
`;

export const createPlant = gql`
  mutation createplant($data: CreatePlantInput!) {
    createPlant(data: $data) {
      id
      name
      address
      contactNumber
      status
      plantPortfolio {
        id
      }
    }
  }
`;

export const createIndustry = gql`
  mutation createIndustry(
    $data: CreateIndustryInput!,
    $image: Upload!
  ) {
    createIndustry(data: $data, image: $image){
      id
      name
    }
  }
`;

export const createCase = gql`
  mutation createSuccessCase(
    $data: CreateCaseInput!
  ){
    createSuccessCase(
      data: $data
    ){
      id
      name
    }
  }
`;

export const editIndustry = gql`
    mutation editIndustry($id: Float!, $data: IndustryInput!, $image: Upload!) {
      editIndustry(where: { id: $id }, data: $data, image: $image) {
        id
        name
    }
}
`;

export const editSuccessCase = gql`
  mutation editSuccessCase($id: Float!, $data: EditSuccessCaseInput!, $image: Upload! ) {
    editSuccessCase(where: { id: $id }, data: $data, image: $image) {
      id
      name
    }
  }
`;


export const CreateUser = gql`
  mutation create($input: CreateUserInput!) {
    createUser(data: $input) {
      id
      firstName
      lastName
      email
      role
      phoneNumber
    }
  }
`;

export const EditUserLogged = gql`
  mutation editusr($data: EditUserInput!) {
    editUser(data: $data) {
      id
      phoneNumber
      firstName
      lastName
      email
    }
  }
`;

export const EditUser = gql`
  mutation editusr($id: Float!, $data: EditUserInput!) {
    editUser(where: { id: $id }, data: $data) {
      id
      phoneNumber
      firstName
      lastName
      email
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: Float!) {
    editUser(where: { id: $id }, data: { status: INACTIVE }) {
      id
    }
  }
`;

export const editPlant = gql`
  mutation($id: Float!, $data: PlantInput!) {
    updatePlant(data: $data, where: { id: $id }) {
      id
      name
      address
      contactNumber
      status
      plantPortfolio {
        id
      }
    }
  }
`;

export const createPortfolio = gql`
  mutation cretaePortfolio($data: CreatePortfolioInput!) {
    createPortfolio(data: $data) {
      id
      name
      plants {
        id
        name
      }
      salesman {
        id
        firstName
        lastName
      }
    }
  }
`;

export const editPortfolio = gql`
  mutation edit($id: Float!, $data: PortfolioInput!) {
    updatePortfolio(where: { id: $id }, data: $data) {
      id
      name
      salesman {
        id
        firstName
        lastName
      }
    }
  }
`;

export const createProductLine = gql`
  mutation createProductLine(
    $data: CreateLineInput!
    $image: Upload!
    $quoteTemplate: Upload!
  ) {
    createProductLine(
      data: $data
      image: $image
      quoteTemplate: $quoteTemplate
    ) {
      id
      name
    }
  }
`;

export const editClient = gql`
  mutation editClient($data: ClientInput!, $id: Float!) {
    updateClient(data: $data, where: { id: $id }) {
      id
      name
      address
      contactNumber
      status
    }
  }
`;

export const editProductLine = gql`
  mutation editProductLine(
    $id: Float!
    $data: EditProductLineInput!
    $image: Upload!
  ) {
    editProductLine(where: { id: $id }, data: $data, image: $image) {
      id
      name
      description
      status
    }
  }
`;

export const createProductStyle = gql`
  mutation createProductStyle($data: CreateStyleInput!) {
    createProductStyle(data: $data) {
      id
      name
      description
      productLine {
        id
        name
        description
      }
    }
  }
`;

export const editProductStyle = gql`
  mutation editProductStyle($id: Float!, $data: ProductStyleInput!) {
    editProductStyle(where: { id: $id }, data: $data) {
      id
      name
      description
      status
    }
  }
`;

export const editProduct = gql`
  mutation editProduct($id: Float!, $data: ProductInput!) {
    editProduct(where: { id: $id }, data: $data) {
      id
      description
      code
      status
    }
  }
`;

export const createProduct = gql`
  mutation createProduct($data: CreateProductInput!) {
    createProduct(data: $data) {
      id
      description
      code
      image
      createdAt
      status
      productStyle {
        id
        name
      }
    }
  }
`;

export const createApplication = gql`
  mutation createApplication($data: ApplicationInput!) {
    createApplication(data: $data) {
      id
      name
      description
      createdAt
      createdBy {
        id
      }
    }
  }
`;

export const editApplication = gql`
  mutation editApplication($id: Float!, $data: ApplicationInput!) {
    editApplication(where: { id: $id }, data: $data) {
      id
      name
      description
      status
    }
  }
`;

export const editDistributor = gql`
  mutation($data: EditDistributorInput!, $id: Float!) {
    editDistributor(data: $data, where: { id: $id }) {
      id
      name
      address
      phoneNumber
      status
      plantPortfolio{
        id
        name
      }
    }
  }
`;

export const createDistributor = gql`
  mutation($data: CreateDistributorInput!) {
    createDistributor(data: $data) {
      id
      name
      address
      phoneNumber
      plantPortfolio{
        id,
        name
      }
    }
  }
`;

export const closeTrainingRequest = gql`
  mutation editTrainingRequest($data: EditTrainingRequestInput!, $id: Float!) {
    editTrainingRequest(where: { id: $id }, data: $data) {
      id
      status
      type
    }
  }
`;

export const createResource = gql`
  mutation($data: CreateResourceInput!, $image: Upload!, $pdf: Upload!, $video: Upload!) {
    createResource(data: $data, pdf: $pdf, image: $image, video: $video) {
      id
      name
      description
      path
      type
      productLine {
        id
        name
      }
      successCase {
        id
        name
      }
    }
  }
`;

export const editResource = gql`
  mutation editResource(
    $data: EditResourceInput!
    $pdf: Upload
    $image: Upload
    $id: Float!
  ) {
    editResource(pdf: $pdf, image: $image, where: { id: $id }, data: $data) {
      id
      name
      description
      type
    }
  }
`;

export const passwordRecovery = gql`
  mutation recoveryPassword($data: PasswordRecoveryInput!) {
    passwordRecovery(data: $data) {
      message
      code
    }
  }
`;

export const passwordChange = gql`
  mutation passwordChange($data: PasswordChangeInput!) {
    passwordChange(data: $data) {
      message
      code
    }
  }
`;

export const editResponsible = gql`
  mutation($id: Float!, $data: EditResponsibleInput!) {
    updateResponsible(where: { id: $id }, data: $data) {
      id
      firstName
      lastName
      email
      phoneNumber
      status
    }
  }
`;

export const editTrainingRequestReason = gql`
  mutation($id: Float!, $data: EditTrainingRequestReasonInput!) {
    editTrainingRequestReason(where: { id: $id }, data: $data) {
      id
      reason
      createdAt
      createdBy {
        id
      }
      status
    }
  }
`;

export const createTrainingRequestReason = gql`
  mutation($data: CreateTrainingRequestReasonInput!) {
    createTrainingRequestReason(data: $data) {
      id
      reason
      createdAt
      createdBy {
        id
      }
      status
    }
  }
`;

export const deleteUserDSO = gql`
  mutation($id: Float!, $status: Status!) {
    deleteUser(where: { id: $id, status: $status }) {
      id
      role
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;

export const resendCode = gql`
  mutation resendCode($id: Float!) {
    resendRegisterCode(where: { id: $id }) {
      message
      code
    }
  }
`;

export const editResourcePosition=gql`
mutation($positions:EditResourcePositionInput!){
  editResourcePositions(positions:$positions)
}
`;