/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/react-hooks";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Pagination, Spinner } from "react-bootstrap";
import "../../assets/scss/index.scss";
import { Consumer } from "../../Util/Context/Context";
import { getDeletedUsers } from "../../Util/Queries/Queries";
import Table, { Row } from "../Atoms/Table/Table";
import "./loader.scss";
import "./styles.scss";

const Users: React.FC<{ history: History }> = (props) => {
  const [load, setload] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [array, setarray] = useState<any>([]);
  const [exec, { loading, error, data }] = useLazyQuery(getDeletedUsers, {
    fetchPolicy: "network-only",
  });
  const [mainPage, setMainPage] = useState<boolean>(false);
  const errores = useAlert();
  const [paginationLoad, setPaginationLoad] = useState<boolean>(false);

  const [active, setActive] = useState<number>(1);
  const [paginationItems, setpaginationItems] = useState<any>([]);

  const handleClick = (page: any) => {
    setActive(page);
    exec({
      variables: { filter: { 
        pagination: { currentPage: page, limit: 10 },
        filter: {status: ["INACTIVE","DELETED"]}
      } },
    });
  };
  const rows: any = [];

  //funciton that fills pagination component

  const fillPagination = () => {
    let items: any = [];
    setTotalPages(data.users.pagination.totalPages);
    for (let number = active - 4; number < active; number++) {
      if (number >= 1) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    for (let number = active; number <= active + 4; number++) {
      if (number <= data.users.pagination.totalPages) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handleClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    setpaginationItems(items);
    setPaginationLoad(true);
  };
  //main Useeffect that retreives data from backend, and handles errors, if it success fet ching data it fills pagination, and table components

  useEffect(() => {
    if (!loading) {
      if (error) {
        error.graphQLErrors.map((error: any) => {
          if (error.message.statusCode === 401) {
            setMainPage(true);
            return errores.error("Su sesion ha caducado");
          } else {
            return errores.error("Ha ocurrido un error");
          }
        });
      } else {
        if (data) {
          setShowLoader(false);
          fillPagination();
          createtable();
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    exec({
      variables: { filter: { 
        pagination: { currentPage: 1, limit: 10 },
        filter: {status: ["INACTIVE","DELETED"]}
      } },
    });
  }, []);

  //load table content
  const createtable = () => {
    var arr: any = [];
    data.users.items.map((user: any) => {
      arr = [...arr, user];
      const row: Row = {
        tds: [
          {
            valor: user.id,
          },
          {
            valor: user.email,
          },
        ],
      };
      return rows.push(row);
    });
    const a = {
      headers: ["ID", "EMAIL"],
      rows: rows,
    };
    setarray(a);
    setload(false);
  };

  return (
    <Consumer>
      {(context) =>
        mainPage === true ? (
          context.setLogged(false)
        ) : showLoader ? (
          <div className="load">
            <div className="dot"></div>
            <div className="outline">
              <span></span>
            </div>
          </div>
        ) : load === false ? (
          //If data is already loaded
          <div className="Users">
            <div className="table-display">
              <div className="title-span">
                <h3>Usuarios Eliminados</h3>
              </div>
              <div className="center-table">
                {loading ? (
                  <div className="spinnerSpan">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Table
                    headers={array.headers}
                    rows={array.rows}
                    type={null}
                    onrowclick={() => null}
                  />
                )}
              </div>
              {paginationLoad ? (
                <Pagination>
                  {active > 1 ? (
                    <>
                      <Pagination.First onClick={() => handleClick(1)} />
                      <Pagination.Prev
                        key={active - 1}
                        onClick={() => handleClick(active - 1)}
                      />
                    </>
                  ) : null}
                  {paginationItems}
                  {active < totalPages ? (
                    <>
                      <Pagination.Next
                        key={active + 1}
                        onClick={() => handleClick(active + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handleClick(totalPages)}
                      />
                    </>
                  ) : null}
                </Pagination>
              ) : null}
            </div>
          </div>
        ) : null
      }
    </Consumer>
  );
};

export default Users;
